import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword, requestPasswordAdmin } from '../redux/AuthCRUD'

const initialValues = {
  email: '',
}

export function ForgotPassword(props: { type: any, translateObj: any }) {
  let { type, translateObj } = props;

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(translateObj("VALID.EMAIL"))
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
  })
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        if (!type) {
          requestPassword(values.email)
            .then(({ data }) => {
              setHasErrors(false)
              setLoading(false)
            })
            .catch((err) => {
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setStatus(err.response.data.message ? err.response.data.message : translateObj("VALID.INCORRET"))
            })
        } else if (type === 1) {
          requestPasswordAdmin(values.email)
            .then(({ data }) => {
              setHasErrors(false)
              setLoading(false)
            })
            .catch((err) => {
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setStatus(err.response.data.message ? err.response.data.message : translateObj("VALID.INCORRET"))
            })
        }
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{translateObj("MESSAGE.FORGOTPASSWORD")}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>{translateObj("MESSAGE.FORGOTPASSWORD.INSERT")}</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {formik.status}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{translateObj("MESSAGE.FORGOTPASSWORD.REDEFINATION")}</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{translateObj("MESSAGE.EMAIL")}</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {loading ? (
          <span className='text-center indicator-progress' style={{ display: 'block' }}>
            {translateObj("MESSAGE.LOADING") + ' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        ) : (
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-dark text-white fw-bolder me-4'
            >
              <span className='indicator-label'>{translateObj("BTN.SEND")}</span>
            </button>
            <Link to={!type ? '/auth/login' : '/auth/admin'}>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {translateObj("BTN.CANCEL")}
              </button>
            </Link>
          </div>
        )}
      </form>
    </>
  )
}
