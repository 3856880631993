import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction } from 'react';

export function ModalImage(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, descriptionDecoration: any }) {
  const { translateObj, show, setShow, descriptionDecoration } = props;

  const handleClose = () => {
    setShow(!show);
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <button type='button' className='btn p-0 m-0 position-absolute' style={{ right: '5%' }} onClick={() => setShow(false)}>
          <i className='fa fa-times'></i>
        </button>
        <Modal.Title className='text-primary'>
          {translateObj("MESSAGE.PREVIEWDECORATION")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{descriptionDecoration.description}</p>
        <img src={descriptionDecoration.image} style={{ maxWidth: '100%' }} alt={translateObj("MESSAGE.EXAMPLEDECORATION")} />
      </Modal.Body>
    </Modal>
  )
}