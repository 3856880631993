import React, {useEffect, useState} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {ScrollTop} from './components/ScrollTop'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import { Toaster } from 'react-hot-toast';
import { getNotificationsCount, getPaymentCredit } from '../../app/services/Api'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../setup'


const MasterLayout: React.FC = ({children}) => {
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const [totalBalance, setTotalBalance] = useState<any>({})
  const [totalNotifications, setTotalNotifications] = useState<any>()

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)

    user && (!user?.type ? (
      getPaymentCredit().then(data => {
        const express = data?.celer_express ? data.celer_express : 0;
        const image = data?.image ? data.image : 0;
        
        setTotalBalance({ express: express, image: image })
      })
    ) : (
      getNotificationsCount().then(data => {
        const total = data?.total
        setTotalNotifications(total)
      })
    ))
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper totalBalance={totalBalance} totalNotification={totalNotifications} />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>{children}</Content>
              <Toaster />
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
