import { KTSVG } from '../../../../_metronic/helpers'
import { useEffect, useState } from 'react';
import { deleteOrderId, getOrderId } from '../../../services/Api';
import { ModalSendImages } from '../components/ModalSendImages';
import { infoTitle } from '../DetailsProject';
import { Images } from '../components/Images';
import { formatReal, maskCep, searchCategory, searchDecoration, searchProduct, searchStatus, searchType, typeProductName } from '../../../utils';
import moment from 'moment';
import UseCredit from '../../create/components/useCredit';

function NewImages(props: {translateObj: any}) {
  let {translateObj} = props

  return (
    <>
      <hr />
      <div className='alert alert-warning'>
        <h5 className='mb-5'>
          <i className='fa fa-info-circle mx-2 text-primary'></i>
          {translateObj('MESSAGE.NEEDNEWPHOTOS')}
        </h5>
        <p>{translateObj('MESSAGE.PHOTOSNOTWORKING')}</p>
        <button type='button' className='btn btn-info w-100'>
          <i className='fa fa-plus'></i>
          <span className='mx-2'>{translateObj('MESSAGE.SENDNEWPHOTOS')}</span>
        </button>
      </div>
    </>
  )
}

export default function DetailCompany(props: {translateObj: any; id: any}) {
  const {translateObj, id} = props
  const [modified, setModified] = useState(1)
  const [order, setOrder] = useState<any>()
  const [modalShow, setModalShow] = useState(false)
  const [ambience, setAmbience] = useState(null)
  const [movimentItem, setMovimentItem] = useState()
  const [finishedPayment, setFinishedPayment] = useState(false)

  const finishedPaymentScreen = () => {
    setFinishedPayment(true)
    return false
  }

  const sendNewImage = (data: any) => {
    setAmbience(data)
    setModalShow(true)
  }

  const removeOrder = async (id: any) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(translateObj('MESSAGE.CANCELORDER'))) {
      await deleteOrderId(id).then(() => {
        window.location.href = '../report'
      })
    }
  }

  useEffect(() => {
    getOrderId(id).then((data) => {
      setOrder(data)
    })
  }, [id, modified])

  return (
    <>
      <ModalSendImages
        translateObj={translateObj}
        show={modalShow}
        setShow={setModalShow}
        ambience={ambience}
        isAdmin={false}
        setModified={setModified}
      />
      <div className='mb-15'>
        <a href='/report'>
          <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-4 me-1' />
          {translateObj('BTN.BACK')}
        </a>
      </div>
      {order?.property && (
        <>
          <h1>
            {translateObj('MESSAGE.DETAILSPROJECT')}
            <span className='text-primary mx-3'>#{order.number}</span>
          </h1>
          <h5 className='mb-5'>
            <b>{translateObj('MESSAGE.CREATED_AT')}: </b>
            <span className='fw-normal'>
              {moment(order?.created_at).format('DD/MM/YYYY HH:mm')}
            </span>
          </h5>

          <div className='card'>
            <div className='card-body'>
              <h3>
                <b>{translateObj("MESSAGE.PRODUCT")}: </b>
                <span className='fw-normal'>{typeProductName(order)}</span>
              </h3>

              <h3>
                <b>{translateObj('MESSAGE.STATUS')}: </b>
                <span className='fw-normal'>{searchStatus(order.status)}</span>
              </h3>

              <div className='mt-10'>
                <div className='accordion-item'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button fs-4 fw-bold'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#kt_accordion_1'
                      aria-expanded='true'
                      aria-controls='kt_accordion_1'
                    >
                      {translateObj('MESSAGE.IMMOBILE.TITLE')}
                    </button>
                  </h2>
                  <div id='kt_accordion_1' className='accordion-collapse collapse show'>
                    <div className='accordion-body pt-0 mt-0'>
                      <div className='row'>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj('MESSAGE.IDENTIFICATION')}</div>
                          {order.property.name}
                        </div>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj('MESSAGE.CATEGORY')}</div>
                          {searchCategory(order.property.category)}
                        </div>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj('MESSAGE.TYPE')}</div>
                          {searchType(order.property.type)}
                        </div>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj('MESSAGE.PROPERTYSTANDARD')}</div>
                          {searchProduct(order.standard)}
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj('MESSAGE.ZIPCODE')}</div>
                          {maskCep(order.property.zip_code)}
                        </div>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj('MESSAGE.VALUE')}</div>
                          {formatReal(order.property.price)}
                        </div>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj('MESSAGE.DECORATIONSTYLE')}</div>
                          {searchDecoration(order.decoration)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button fs-4 fw-bold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#kt_accordion_2'
                      aria-expanded='false'
                      aria-controls='kt_accordion_2'
                    >
                      {translateObj('MESSAGE.PHOTOSIMMOBILE')}
                    </button>
                  </h2>
                  <div id='kt_accordion_2' className='accordion-collapse collapse'>
                    <div className='accordion-body pt-0 mt-0'>
                      {order.enviroments.map((item: any, key: any) => {
                        let keyItem = key + 1

                        return (
                          <div key={keyItem} className={`mt-5`}>
                            <h5 className='m-0 p-0 border-bottom pb-3 mb-3'>
                              {keyItem}° {translateObj('MESSAGE.AMBIENCE')}
                            </h5>

                            <div>
                              <b>{translateObj('MESSAGE.AMBIENCE')}:</b>
                              <span> {item.name}</span>
                            </div>
                            {item.album_link && (
                              <div>
                                <b>{translateObj('MESSAGE.LINKSITE')}:</b>
                                <span> {item.album_link}</span>
                              </div>
                            )}
                            {item.description && (
                              <div>
                                <b>{translateObj('MESSAGE.DESCRIPTION')}:</b>
                                <span> {item.description}</span>
                              </div>
                            )}
                            {item.measurements && (
                              <div>
                                <b>{translateObj('MESSAGE.DESCRIPTIONDETAILS')}:</b>
                                <span> {item.measurements}</span>
                              </div>
                            )}
                            <Images
                              translateObj={translateObj}
                              photos={item.photos}
                              status={order.status}
                              setMovimentItem={setMovimentItem}
                            />
                            {(order.status === 'Draft' ||
                              order.status === 'Waiting_Payment' ||
                              order.status === 'Waiting_Service') &&
                              item.photos.length < 3 && (
                                <div onClick={() => sendNewImage(item)}>
                                  <button
                                    type='button'
                                    className='btn btn-dark text-white w-100 mt-10'
                                  >
                                    <i className='fa fa-plus'></i>
                                    <span className='mx-2'>
                                      {translateObj('MESSAGE.SENDNEWPHOTO')}
                                    </span>
                                  </button>
                                </div>
                              )}
                            {item.review && (
                              <div onClick={() => sendNewImage(item)}>
                                <NewImages translateObj={translateObj} />
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                {order.status !== 'Waiting_Payment' && (
                  <div className='accordion-item'>
                    <h2 className='accordion-header'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_3'
                        aria-expanded='false'
                        aria-controls='kt_accordion_3'
                      >
                        ({order.orders_moviments.length}) {translateObj('MESSAGE.MOVEMENTS')}
                      </button>
                    </h2>
                    <div id='kt_accordion_3' className='accordion-collapse collapse'>
                      <div className='accordion-body'>
                        <ul>
                          {order.orders_moviments.map((item: any) => {
                            let created_at = moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')

                            return (
                              <li key={item.id} className='p-3'>
                                {created_at} - {item.desciption}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {order.status === 'Finished' &&
                (order.comparative_document_url || order.comparative_video_url) && (
                  <div className='mt-8'>
                    <h5>{translateObj('MESSAGE.TITLE.COMPARATIVE')}</h5>
                    <div className='d-flex gap-5'>
                      {order.comparative_document_url && (
                        <a
                          href={order.comparative_document_url}
                          target='_blank'
                          className='btn btn-primary btn-sm'
                          rel='noreferrer'
                        >
                          <i className='fa fa-download me-3'></i>
                          {translateObj('MESSAGE.COMPARATIVEDOCUMENT')}
                        </a>
                      )}

                      {order.comparative_video_url && (
                        <a
                          href={order.comparative_video_url}
                          target='_blank'
                          className='btn btn-primary btn-sm'
                          rel='noreferrer'
                        >
                          <i className='fa fa-download me-3'></i>
                          {translateObj('MESSAGE.COMPARATIVEVIDEO')}
                        </a>
                      )}
                    </div>
                  </div>
                )}

              {(order.status === 'Waiting_Payment' || order.status === 'Draft') && (
                <div className='pt-5'>
                  {!finishedPayment ? (
                    <>
                      <UseCredit translateObj={translateObj} order={order} typeSelectOrder={order} functionPayment={finishedPaymentScreen} />
                      <div>
                        <h4>{translateObj('MESSAGE.CANCELORDER')}</h4>
                        <p className='fs-5'>{translateObj('MESSAGE.CANCELORDER.DESCRIPTION')}</p>

                        <button
                          type='button'
                          className='btn btn-danger cursor-pointer w-100'
                          onClick={() => removeOrder(order?.id)}
                        >
                          <i className='fa fa-times me-4' />
                          {translateObj('BTN.CANCEL')}
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className='card'>
                      <div className='card-body'>
                        <h1 className='card-title text-success d-flex align-items-center'>
                          <i className='fa fa-check-circle text-success fa-2x'></i>
                          <span className='mx-3'>{translateObj('MESSAGE.NOWTEAM.TITLE')}</span>
                        </h1>
                        <div className='card-text my-5 fs-5'>
                          <div>{translateObj('MESSAGE.NOWTEAM.DESCRIPTION')}</div>
                          <div className='alert alert-warning mt-5'>
                            <b>{translateObj('MESSAGE.ATENTION')}:</b>
                            <br />
                            {translateObj('MESSAGE.ATENTION.DESCRIPTION')}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}
