import clsx from 'clsx'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {HeaderUserMenu} from '../../../partials'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {setLanguage} from '../../../i18n/Metronici18n'
import {languages} from '../../../../app/utils'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

export function Topbar(props: {totalBalance: any; totalNotification: any}) {
  const intl = useIntl()
  const translateObj = (obj: any) => {
    return intl.formatMessage({id: obj})
  }
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any
  let {totalBalance, totalNotification} = props

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='position-absolute top-0 start-0 ms-3 mt-2 me-5 text-center'>
        <b className='me-3'>{translateObj('TRANSLATOR.SELECT')}</b>
        <br />
        <select
          className='mt-1'
          onChange={(e) => e.target.value !== '' && setLanguage(e.target.value)}
        >
          <option value=''>{translateObj('MESSAGE.SELECT')}</option>
          {languages.map((item: any, key: number) => {
            return (
              <option key={key} value={item.id} selected={item.id === intl.locale}>
                {item.name}
              </option>
            )
          })}
        </select>
      </div>
      {user &&
        (!user?.type ? (
          <>
            <div
              className={clsx(
                'd-flex align-items-center label-topbar-info',
                toolbarButtonMarginClass
              )}
              id='kt_header_user_credit'
            >
              <Link to='/credit/report' className='text-dark'>
                Premium:{' '}
                <b>{totalBalance?.image && totalBalance?.image >= 0 ? totalBalance?.image : 0}</b>
                {' | '}
                Celer:{' '}
                <b>
                  {totalBalance?.express && totalBalance?.express >= 0 ? totalBalance?.express : 0}
                </b>
              </Link>
            </div>
          </>
        ) : (
          <div
            className={clsx(
              'd-flex align-items-center label-topbar-info position-relative',
              toolbarButtonMarginClass
            )}
            id='kt_header_user_notifications'
          >
            <Link to='/notifications' className='text-dark'>
              <i className='fa fa-bell text-dark'></i>
              {totalNotification > 0 && (
                <span className='badge badge-dark m-0 p-1 ms-1'>{totalNotification}</span>
              )}
            </Link>
          </div>
        ))}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id={`kt_header_user_menu_toggle${Math.random()}`}
      >
        <div
          className={clsx('cursor-pointer symbol ms-3', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {translateObj('MESSAGE.USERPROFILE')}
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}
