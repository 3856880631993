/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { AsideMenuItem } from './AsideMenuItem'
import MenuCompany from './permission/Company'
import MenuTeam from './permission/Team'

export function AsideMenuMain() {
  const intl = useIntl();
  const translateObj = (obj: any) => {
    return intl.formatMessage({ id: obj })
  }
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={translateObj("MESSAGE.INDEX")}
        fontIcon='bi-app-indicator'
      />
      {user && (user?.type ? <MenuTeam translateObj={translateObj} /> : <MenuCompany translateObj={translateObj} />)}
    </>
  )
}
