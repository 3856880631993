import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useState } from 'react';
import { putAdminOperator } from '../../../services/Api';

export function ModalChangeUser(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, ambienceUserSelected: any, setModified: Dispatch<SetStateAction<number>> }) {
  const { translateObj, show, setShow, ambienceUserSelected, setModified } = props;
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setShow(false);
  }

  const changeConfirm = () => {
    setLoading(true)

    putAdminOperator({ enviroment_id: ambienceUserSelected.idAmbience, operator_id: ambienceUserSelected.idUser }).then(() => {
      setModified(Math.random())
      handleClose()
      setLoading(false)
    })
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className='text-primary'>
          {translateObj("MESSAGE.UPDATEDRESPONSIBLE")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='fs-6'>
          {translateObj("MESSAGE.UPDATEDRESPONSIBLE.DESCRIPTION")}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <>
            <span className='indicator-progress' style={{ display: 'block' }}>
              {translateObj("MESSAGE.LOADING") + ' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </>
        ) : (
          <>
            <button type='button' className='btn btn-default' onClick={handleClose}>
              {translateObj("MESSAGE.NO")}
            </button>
            <button type='button' className='btn btn-dark text-white' onClick={changeConfirm}>
              <i className='fa fa-check'></i>
              <span className='mx-1'>{translateObj("MESSAGE.YES")}</span>
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}