import { Field, ErrorMessage } from 'formik'
import { useState } from 'react';
import { maskCep, maskCpOrCnpj, validCep, validCPFandCNPJ } from '../../../../utils';

export function Company(props: { translateObj: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void; }) {
  let { translateObj, setFieldValue } = props
  const [loadingZipCode, setLoadingZipCode] = useState(false)

  return (
    <>
      <div className='w-100'>
        <h2 className='fw-bolder text-dark'>
          <i className='fa fa-building fa-1x me-3 text-primary'></i>
          {translateObj('MESSAGE.COMPANY.DATA')}
        </h2>

        <div className='text-dark fw-bold fs-6 mb-15'>
          {translateObj('MESSAGE.COMPANY.NEWACCOUNT')}
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.NAME')}</label>

          <Field name='name' className='form-control form-control-lg form-control-solid' />
          <div className='text-danger mt-2'>
            <ErrorMessage name='name' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.DOCUMENT')}</label>

          <Field name='document' className='form-control form-control-lg form-control-solid' onKeyUp={(e: any) => setFieldValue('document', maskCpOrCnpj(e.target.value))}
            onBlur={(e: any) => {
              var length = e?.target?.value.length;

              if ((length === 14 || length === 18) && !validCPFandCNPJ(e?.target?.value)) {
                setFieldValue('document', '')
              }
            }} />
          <div className='text-danger mt-2'>
            <ErrorMessage name='document' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.ZIPCODE')}</label>

          <Field name='zip_code' className='form-control form-control-lg form-control-solid' maxLength={9} onKeyUp={(e: any) => setFieldValue('zip_code', maskCep(e.target.value))} onBlur={(e: any) => e.target.value.length > 8 && validCep(e.target.value, setFieldValue, setLoadingZipCode)} />
          <div className='text-danger mt-2'>
            <ErrorMessage name='zip_code' />
          </div>
          {loadingZipCode && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Buscando informações...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </div>

        <div className="row">
          <div className='col-8 fv-row mb-7'>
            <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.CITY')}</label>

            <Field name='city' className='form-control form-control-lg form-control-solid' />
            <div className='text-danger mt-2'>
              <ErrorMessage name='city' />
            </div>
          </div>

          <div className='col-4 fv-row mb-7'>
            <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.STATE')}</label>

            <Field name='state' className='form-control form-control-lg form-control-solid' />
            <div className='text-danger mt-2'>
              <ErrorMessage name='state' />
            </div>
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.ADDRESS')}</label>

          <Field name='address' className='form-control form-control-lg form-control-solid' />
          <div className='text-danger mt-2'>
            <ErrorMessage name='address' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.NUMBER')}</label>

          <Field name='address_number' className='form-control form-control-lg form-control-solid' />
          <div className='text-danger mt-2'>
            <ErrorMessage name='address_number' />
          </div>
        </div>
      </div>
    </>
  )
}