import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Dispatch, SetStateAction, useState } from "react";
import { maskCep, maskCpOrCnpj, validCep, validCPFandCNPJ } from '../../../utils';
import { patchCompany } from '../../../services/Api';

export const FormNfe = (props: { translateObj: any, company: any, setModified: Dispatch<SetStateAction<number>> }) => {
  const { translateObj, company, setModified } = props;
  const [loading, setLoading] = useState(false)
  const [loadingZipCode, setLoadingZipCode] = useState(false)

  const initialValues = {
    id: company?.id,
    is_same_tax: !company?.is_same_tax,
    state: company?.tax_state ? company.tax_state : '',
    city: company?.tax_city ? company.tax_city : '',
    address: company?.tax_address ? company.tax_address : '',
    tax_name: company?.tax_name ? company.tax_name : '',
    tax_document: company?.tax_document ? company.tax_document : '',
    tax_number: company?.tax_number ? company.tax_number : '',
    tax_zip_code: company?.tax_zip_code ? maskCep(company.tax_zip_code) : '',
    tax_email: company?.tax_email ? company.tax_email : '',
    tax_state_reg: company?.tax_state_reg ? company.tax_state_reg : '',
  }

  const currentSchema = Yup.object().shape({
    "tax_email": Yup.string()
      .email(translateObj("VALID.EMAIL"))
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(100, translateObj("VALID.MAX.CARACTER", { "length": 100 }))
      .required(translateObj("VALID.REQUIRED")),
    "tax_document": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "tax_name": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(100, translateObj("VALID.MAX.CARACTER", { "length": 100 }))
      .required(translateObj("VALID.REQUIRED")),
    "tax_zip_code": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(9, translateObj("VALID.MAX.CARACTER", { "length": 9 }))
      .required(translateObj("VALID.REQUIRED")),
    "state": Yup.string()
      .min(2, translateObj("VALID.MIN.CARACTER", { "length": 2 }))
      .max(2, translateObj("VALID.MAX.CARACTER", { "length": 2 }))
      .required(translateObj("VALID.REQUIRED")),
    "address": Yup.string()
      .required(translateObj("VALID.REQUIRED")),
    "city": Yup.string()
      .required(translateObj("VALID.REQUIRED")),
    "tax_number": Yup.string()
      .min(1, translateObj("VALID.MIN.CARACTER", { "length": 1 }))
      .max(4, translateObj("VALID.MAX.CARACTER", { "length": 4 }))
      .required(translateObj("VALID.REQUIRED")),
  })

  const submitForm = (values: any) => {
    setLoading(true);

    let data = values;
    data.tax_city = values.city;
    data.tax_state = values.state;
    data.tax_address = values.address;
    data.address_number = Number(values.tax_number);
    data.tax_zip_code = isNaN(values.tax_zip_code) ? Number(values.tax_zip_code.replace("-", "")) : values.tax_zip_code;
    data.tax_number = Number(values.tax_number);

    patchCompany(data).then(() => {
      // setModified(Math.random());
      setLoading(false);
    });
  }

  return (
    <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitForm}>
      {({ setFieldValue }) => (
        <Form id='kt_form_nfe'>
          <div className='fv-row mt-7 mb-7'>
            <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.DOCUMENT')}</label>

            <Field name='tax_document' className='form-control form-control-lg form-control-solid' onKeyUp={(e: any) => setFieldValue('tax_document', maskCpOrCnpj(e.target.value))}
              onBlur={(e: any) => {
                var length = e?.target?.value.length;

                if ((length === 14 || length === 18) && !validCPFandCNPJ(e?.target?.value)) {
                  setFieldValue('tax_document', '')
                }
              }} />
            <div className='text-danger mt-2'>
              <ErrorMessage name='document' />
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.NAME')}</label>
            <Field name='tax_name' className='form-control form-control-lg form-control-solid' />
            <div className='text-danger mt-2'>
              <ErrorMessage name='tax_name' />
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.EMAILNFE')}</label>
            <Field type="email" name='tax_email' className='form-control form-control-lg form-control-solid' />
            <div className='text-danger mt-2'>
              <ErrorMessage name='tax_email' />
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='fs-6 fw-bold form-label'>{translateObj('MESSAGE.STATEREGISTRATION')}</label>
            <Field name='tax_state_reg' className='form-control form-control-lg form-control-solid' />
            <div className='text-danger mt-2'>
              <ErrorMessage name='tax_state_reg' />
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.ZIPCODE')}</label>
            <Field name='tax_zip_code' className='form-control form-control-lg form-control-solid' maxLength={9} onKeyUp={(e: any) => setFieldValue('tax_zip_code', maskCep(e.target.value))} onBlur={(e: any) => e.target.value.length > 8 && validCep(e.target.value, setFieldValue, setLoadingZipCode)} />
            <div className='text-danger mt-2'>
              <ErrorMessage name='tax_zip_code' />
            </div>
            {loadingZipCode && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Buscando informações...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </div>

          <div className="row">
            <div className='col-8 fv-row mb-7'>
              <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.CITY')}</label>

              <Field name='city' className='form-control form-control-lg form-control-solid' />
              <div className='text-danger mt-2'>
                <ErrorMessage name='city' />
              </div>
            </div>

            <div className='col-4 fv-row mb-7'>
              <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.STATE')}</label>

              <Field name='state' className='form-control form-control-lg form-control-solid' />
              <div className='text-danger mt-2'>
                <ErrorMessage name='state' />
              </div>
            </div>
          </div>

          <div className='fv-row mb-7'>
            <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.ADDRESS')}</label>

            <Field name='address' className='form-control form-control-lg form-control-solid' />
            <div className='text-danger mt-2'>
              <ErrorMessage name='address' />
            </div>
          </div>
          
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.NUMBER')}</label>

            <Field name='tax_number' className='form-control form-control-lg form-control-solid' />
            <div className='text-danger mt-2'>
              <ErrorMessage name='tax_number' />
            </div>
          </div>
          {loading ? (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {translateObj("MESSAGE.LOADING") + ''}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <button type='submit' className='btn btn-dark text-white'>
              <i className='fa fa-check'></i>
              <span className='mx-1'>{translateObj("BTN.SAVE")}</span>
            </button>
          )}
        </Form>
      )}
    </Formik>
  )
}