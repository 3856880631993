/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import * as auth from '../redux/AuthRedux'
import { KTSVG } from '../../../../_metronic/helpers'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { IRegister, register } from '../redux/AuthCRUD'
import { Link, useParams } from 'react-router-dom'
import { Company } from './steps/Step1'
import { User } from './steps/Step2'

interface IValues {
  executive: string;
  name: string;
  document: string;
  zip_code: number | string;
  city: string;
  state: string;
  address: string;
  address_number: number | string;
  consumer_name: string;
  email: string;
  phone: string;
  password: string;
  password_change: string;
  accept_terms: string;
}

export function Registration(props: { translateObj: any }) {
  let { translateObj } = props;
  const { id } = useParams<any>();

  const initialValues: IValues = {
    executive: id ? id : '',
    name: '',
    document: '',
    zip_code: '',
    city: '',
    state: '',
    address: '',
    address_number: '',
    consumer_name: '',
    email: '',
    phone: '',
    password: '',
    password_change: '',
    accept_terms: '',
  }

  const registrationSchema = [Yup.object().shape({
    "name": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "document": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "zip_code": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(9, translateObj("VALID.MAX.CARACTER", { "length": 9 }))
      .required(translateObj("VALID.REQUIRED")),
    "state": Yup.string()
      .min(2, translateObj("VALID.MIN.CARACTER", { "length": 2 }))
      .max(2, translateObj("VALID.MAX.CARACTER", { "length": 2 }))
      .required(translateObj("VALID.REQUIRED")),
    "address_number": Yup.string()
      .min(1, translateObj("VALID.MIN.CARACTER", { "length": 1 }))
      .max(7, translateObj("VALID.MAX.CARACTER", { "length": 7 }))
      .required(translateObj("VALID.REQUIRED")),
  }), Yup.object().shape({
    "consumer_name": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "email": Yup.string()
      .email(translateObj("VALID.EMAIL"))
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(100, translateObj("VALID.MAX.CARACTER", { "length": 100 }))
      .required(translateObj("VALID.REQUIRED")),
    "phone": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(15, translateObj("VALID.MAX.CARACTER", { "length": 15 }))
      .required(translateObj("VALID.REQUIRED")),
    "password": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "password_change": Yup.string()
      .required(translateObj("VALID.REQUIRED"))
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], translateObj("VALID.CONFPASSWORD")),
      }),
    "accept_terms": Yup.mixed().required(translateObj("VALID.TERMS")),
  })]
  const dispatch = useDispatch()
  const [status, setStatus] = useState<any>();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(registrationSchema[0])
  const [isSubmitButton, setSubmitButton] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)

    stepper.current.goPrev()

    setCurrentSchema(registrationSchema[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: IRegister) => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)

    setCurrentSchema(registrationSchema[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {

      setStatus('');
      setLoading(true);

      values.zip_code = Number(values.zip_code.toString().replace("-", ""))
      values.address_number = Number(values.address_number)

      register(values)
        .then(({ data }) => {
          dispatch(auth.actions.login(data.access_token))
        })
        .catch((err) => {
          setLoading(false)
          setStatus(err.response.data.message ? err.response.data.message : 'Verifique seus dados, alguns campos estão inválidos.')
        })
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])
  /* End Wizard */

  const [loading, setLoading] = useState(false)

  return (
    <div>
      <div className='mb-10 text-center'>
        <h1 className='text-dark mb-3'>{translateObj('MESSAGE.NEWACCOUNT')}</h1>

        <div className='text-gray-400 fw-bold fs-4'>
          {translateObj('MESSAGE.HAVEACCOUNT')}
          <Link to='/auth/login' className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
            {translateObj('MESSAGE.ENTERHERE')}
          </Link>
        </div>
      </div>

      <hr />

      <div className='card'>
        <div className='card-body'>
          <div
            ref={stepperRef}
            className='stepper stepper-links d-flex flex-column'
            id='kt_create_account_stepper'
          >
            <div className='stepper-nav mb-5'>
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                <h3 className='stepper-title'>{translateObj('MESSAGE.COMPANY')}</h3>
              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>
                <h3 className='stepper-title'>{translateObj('MESSAGE.USER')}</h3>
              </div>
            </div>

            <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitStep}>
              {({ setFieldValue }) => (
                <Form className='mx-auto mw-600px w-100 pt-5 pb-5' id='kt_create_account_form'>
                  <div className='current' data-kt-stepper-element='content'>
                    <Company translateObj={translateObj} setFieldValue={setFieldValue} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <User translateObj={translateObj} setFieldValue={setFieldValue} />
                  </div>

                  {status && (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{status}</div>
                    </div>
                  )}

                  {loading ? (
                    <span className='text-center indicator-progress' style={{ display: 'block' }}>
                      {translateObj('MESSAGE.LOADING') + ' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <div className='d-flex flex-stack pt-15'>
                      <div className='mr-2'>
                        <button
                          onClick={prevStep}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-4 me-1'
                          />
                          {translateObj('BTN.PREVIOUS')}
                        </button>
                      </div>

                      <div>
                        <button type='submit' className='btn btn-lg btn-dark text-white me-3'>
                          <span className='indicator-label'>
                            {!isSubmitButton && translateObj('BTN.NEXT')}
                            {isSubmitButton && translateObj('BTN.SAVE')}
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
