import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {getCompanyUsers} from '../../../services/Api'
import {ModalUser} from '../components/ModalUser'

export const ReportUsers = () => {
  const intl = useIntl()
  const translateObj = (obj: any) => {
    return intl.formatMessage({id: obj})
  }
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any
  const [modified, setModified] = useState(1)
  const [modalShow, setModalShow] = useState(false)
  const [users, setUsers] = useState<any[]>([])

  const limit = 10
  const [totalPages, setTotalPages] = useState<any[]>([])
  const [listUsers, setListUsers] = useState<any[]>([])

  const verifyTotalPages = (array: []) => {
    const total = []
    const length = array.length / limit

    for (let i = 0; i < length; i++) {
      total.push(i + 1)
    }

    setTotalPages(total)
  }

  const verifyListUsers = (array: []) => {
    setUsers(array)
    setListUsers(array.slice(0, 10))

    verifyTotalPages(array)
  }

  useEffect(() => {
    getCompanyUsers().then((data: any) => {
      verifyListUsers(data)
    })
  }, [modified])

  return (
    <>
      {user?.is_admin ? (
        <>
          <ModalUser translateObj={translateObj} show={modalShow} setShow={setModalShow} setModified={setModified} companyId={user.company_id} dataUser={{}} />
          <h1 className='mb-10'>{translateObj("MESSAGE.CONSULTUSERS")}</h1>
          <div className='card'>
            <div className='card-body'>
              {users.length < 3 && (
                <div className='text-end'>
                  <button className='btn btn-dark text-white' onClick={() => setModalShow(true)}>
                    {translateObj('MESSAGE.ADDUSER')}
                  </button>
                </div>
              )}

              <h3 className='mb-5'>
                {listUsers?.length} {translateObj('MESSAGE.RESULTS')}
              </h3>
              <div className='table-responsive'>
                <table className='table table-hover'>
                  <thead className='table-light border-bottom text-uppercase fw-bolder bg-secondary text-center'>
                    <tr>
                      <td>{translateObj('MESSAGE.NAME')}</td>
                      <td>{translateObj('MESSAGE.EMAIL')}</td>
                      {/* <td>Status</td> */}
                      {/* <td className='text-center'>#</td> */}
                    </tr>
                  </thead>
                  <tbody className='text-center'>
                    {listUsers.length ? (
                      listUsers.map((item) => {
                        return (
                          <tr key={item.id} className='cursor-pointer border-bottom'>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            {/* <td>{item.status ? 'Ativo' : 'Inativo'}</td> */}
                            {/* <td className='text-center'>
                            <span className='p-3'>
                              <i className='fa fa-search'></i>
                            </span>
                          </td> */}
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={3}>{translateObj('MESSAGE.NOTRESULT')}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>{translateObj('VALID.PERMISSION')}</>
      )}
    </>
  )
}
