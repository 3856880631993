import React from 'react'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'

type Props = {
  anchor: string
  urls: any
  title: string
  icon?: string
  activeMultiMenu: boolean
}

const AsideMenuItemToggle: React.FC<Props> = ({
  children,
  anchor,
  urls,
  title,
  activeMultiMenu
}) => {
  const { pathname, hash } = useLocation()
  const isActive = urls.filter((item: any) => item.to === pathname).length || anchor === hash || activeMultiMenu
  
  return (
    <div className={`menu-item ${isActive && 'bg-light border-top border-bottom py-3'}`}>
      <Link className={clsx('menu-link without-sub', { active: isActive })} to={anchor}>
        <span className='menu-title'>{title}</span>
        <i className={clsx('bi fs-3', isActive ? `fa fa-angle-up` : `fa fa-angle-down`)}></i>
      </Link>
      {isActive && children}
    </div>
  )
}

export { AsideMenuItemToggle }
