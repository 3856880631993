import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {Modal} from 'react-bootstrap-v5'
import {Dispatch, SetStateAction} from 'react'
import {putImagesCompanyId} from '../../../services/Api'

export function ModalBalanceEdit(props: {
  translateObj: any
  show: any
  setShow: Dispatch<SetStateAction<boolean>>
  setModified: Dispatch<SetStateAction<number>>
  companyId: any
  screen: any
  valueEdit: any
}) {
  const {translateObj, show, setShow, setModified, companyId, screen, valueEdit} = props

  const handleClose = () => {
    setShow(false)
  }

  const initialValues = {
    amount: valueEdit ? valueEdit : 0,
    is_paid: true,
    is_celer_express: screen === 'express',
  }

  const currentSchema = Yup.object().shape({
    amount: Yup.number()
      .max(999, translateObj('VALID.MAX.CARACTER', {length: 999}))
      .required(translateObj('VALID.REQUIRED')),
  })

  const submit = (values: any) => {
    console.log(values)
    let data = values

    putImagesCompanyId(companyId, data).then(() => {
      setModified(Math.random())
      handleClose()
    })
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submit}>
          {({setFieldValue}) => (
            <Form id='kt_order'>
              <div>
                <h2 className='fw-bolder text-dark mb-10'>
                  Editar{' '}
                  {screen === 'express'
                    ? translateObj('MESSAGE.TOTALIMAGESEXPRESS')
                    : translateObj('MESSAGE.TOTALIMAGES')}
                </h2>

                <div className='fv-row mb-5 mt-5'>
                  <label className='fs-6 fw-bold form-label required'>
                    {translateObj('MESSAGE.QUANTITY')}
                  </label>

                  <Field
                    type='number'
                    name='amount'
                    className='form-control form-control-lg form-control-solid'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='amount' />
                  </div>
                </div>

                <div className='mt-10'>
                  <button type='submit' className='btn btn-dark w-100'>
                    {translateObj('BTN.SAVE')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-default w-100'
                    onClick={() => handleClose()}
                  >
                    {translateObj('BTN.CLOSE')}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
