import { useState } from 'react'
import { postPaymentCreditPayPdv } from '../../../../services/Api'

export default function ContentLocal(props: { translateObj: any, data: any }) {
  let { translateObj, data } = props
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState(false)
  const [payment, setPayment] = useState(false)

  const validToken = () => {
    setLoading(true)
    postPaymentCreditPayPdv({ credit_id: data.id, conecta_password: token }).then(data => {
      setLoading(false)
      data?.id && setPayment(true)
    })
  }

  return (
    <>
      {data?.pdv ? (
        <div className='w-100'>
          {!payment ? (
            <div className='alert alert-warning mt-5' style={{ maxWidth: '440px' }}>
              {translateObj("MESSAGE.TERMINALPAYMENT")}

              <h3 className='mt-5'>{translateObj("MESSAGE.VALIDTOKEN")}</h3>
              <div className="input-group mb-3">
                <input type="text" maxLength={6} className="form-control" placeholder="a12s45" onKeyUp={(e: any) => setToken(e.target.value)} />
                {loading ? (
                  <button type="button" className="btn btn-primary">
                    <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                    {translateObj("MESSAGE.VALIDATING")}
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary" onClick={validToken}>{translateObj("MESSAGE.VALIDATE")}</button>
                )}
              </div>
            </div>
          ) : (
            <h3 className="card-title text-success d-flex align-items-center">
              <i className='fa fa-check-circle text-success fa-2x'></i>
              <span className='mx-3'>{translateObj("MESSAGE.BALANCE.RELEASED")}</span>
            </h3>
          )}
        </div>
      ) : (
        <>
          {translateObj("MESSAGE.ERROR.PAYMENT")}
        </>
      )}
    </>
  )
}