import moment from "moment"
import { useEffect, useState } from "react"
import { getAdminGeneratePdfPaymentId, getAdminPayments, getAdminPaymentsUsage } from "../../../../services/Api"
import { convertDate, formatReal } from "../../../../utils"
import { ModalToken } from "../../components/ModalToken"

export function TableUse(props: { translateObj: any, showResult: boolean }) {
  const { translateObj, showResult } = props

  const [params, setParams] = useState<any>({});
  const [filter, setFilter] = useState<any>({})
  const [coupons, setCoupons] = useState<any[]>([])
  const [show, setShow] = useState(false);
  const [token, setToken] = useState<any>('');

  const limit = 10
  const [pagination, setPagination] = useState(1)
  const [totalPages, setTotalPages] = useState<any[]>([])
  const [listCoupons, setListCoupons] = useState<any[]>([])

  const changeParam = (element: any) => {
    const value = element.target.value
    const oldParams = params
    oldParams[`${element.target.name}`] = value
    setParams({ ...oldParams })
    verifyEnter(element)
  }

  const verifyEnter = (e: any) => {
    if (e.keyCode === 13) sendFilter()
  }

  const sendFilter = () => {
    setFilter({ ...params })
  }

  const verifyPagination = (page: any) => {
    let resultPage = (page - 1) * limit
    let newOffSet = { from: resultPage, to: resultPage + limit }

    coupons && setListCoupons(coupons.slice(newOffSet.from, newOffSet.to))
    setPagination(page)
  }

  const verifyTotalPages = (array: []) => {
    const total = []
    const length = array?.length / limit

    for (let i = 0; i < length; i++) {
      total.push(i + 1)
    }

    setTotalPages(total)
  }

  const verifyListCoupons = (array: []) => {
    setCoupons(array)
    array && setListCoupons(array.slice(0, 10))

    verifyTotalPages(array)
  }

  const validItem = (obj: any, search = 0) => {
    setToken('')

    if (obj?.order_id && search) {
      window.location.href = `../detailsProject/${obj.order_id}`;
    } else if (obj?.conecta_password && obj?.status === "Pending") {
      setShow(true)
      setToken(obj?.conecta_password)
    }
  }

  useEffect(() => {
    getAdminPaymentsUsage(filter).then((data) => {
      verifyListCoupons(data)
    })
  }, [filter])

  return (
    <>
      <ModalToken translateObj={translateObj} show={show} setShow={setShow} token={token} />
      <div>
        <h3 className='mb-5'>{translateObj("MESSAGE.FILTER")}:</h3>
        <div className='mb-10'>
          <div className='row align-items-end'>
            <div className='col-6'>
              <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.COMPANY")}</label>
              <input type='text' name='company' className='form-control form-control-sm form-control-solid' onKeyUp={(e: any) => changeParam(e)} />
            </div>
            <div className='col-3'>
              <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.FILTER.IN")}</label>
              <input type='date' name='date_in' maxLength={10} className='form-control form-control-sm form-control-solid' onKeyUp={(e: any) => changeParam(e)} />
            </div>
            <div className='col-3'>
              <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.FILTER.UNTIL")}</label>
              <input type='date' name='date_at' maxLength={10} className='form-control form-control-sm form-control-solid' onKeyUp={(e: any) => changeParam(e)} />
            </div>
          </div>
          <div className="mt-5">
            <button type="submit" className='w-100 btn btn-dark text-white' onClick={sendFilter}>
              <i className='fa fa-search'></i>
              <span>{translateObj("BTN.SEARCH")}</span>
            </button>
          </div>
        </div>

        {showResult && (<h3 className='mb-5'>{coupons?.length + ' ' + translateObj("MESSAGE.RESULTS")}:</h3>)}
        <div className="table-responsive">
          <table className="table table-hover">
            <thead className='table-light border-bottom text-uppercase fw-bolder bg-secondary text-center'>
              <tr>
                <td>{translateObj("MESSAGE.REALSTATE")}</td>
                <td>{translateObj("MESSAGE.STATUS")}</td>
                <td>{translateObj("MESSAGE.TYPE")}</td>
                <td>{translateObj("MESSAGE.DATE")}</td>
                <td>{translateObj("MESSAGE.ACTIONS")}</td>
              </tr>
            </thead>
            <tbody className='text-center'>
              {listCoupons?.length ? (listCoupons?.map(item => {

                return (
                  <tr key={item.id} className='cursor-pointer border-bottom'>
                    <td>{item?.company?.name}</td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <div>
                          <i className='fa fa-check text-success' />
                        </div>
                        <div className="ms-3" style={{ minWidth: '70px' }}>
                          {item.status === "Paid" ? (
                            <span className="badge badge-default text-success">{translateObj("MESSAGE.USED")}</span>
                          ) : (
                            <span className="badge badge-warning">{translateObj("MESSAGE.PENDING")}</span>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>{item.is_credit ? "Express" : "Premium"}</td>
                    <td>{moment(item?.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td className="text-center">
                      {item?.order_id ? (
                        <button className="btn btn-primary btn-sm px-3" onClick={() => validItem(item, 1)}>
                          <i className="fa fa-search" />
                        </button>
                      ) : '--'}
                    </td>
                  </tr>
                )
              })) : (
                <tr>
                  <td colSpan={5}>{translateObj("MESSAGE.NOTRESULT")}</td>
                </tr>
              )}
            </tbody>
          </table>

          {totalPages.length > 0 && (
            <div className="float-end my-5 text-center">
              {translateObj("MESSAGE.PAGINATION")}
              <div className="d-flex pt-3">
                {totalPages.map(item => {
                  return (
                    <button key={item} type="button" className={`btn btn-sm text-primary p-0 m-0 px-1 border mx-1 ${pagination === item && 'border-primary border-2'}`} onClick={() => verifyPagination(item)}>{item}</button>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export function TablePayment(props: { translateObj: any, showResult: boolean }) {
  const { translateObj, showResult } = props

  const [params, setParams] = useState<any>({});
  const [filter, setFilter] = useState<any>({})
  const [coupons, setCoupons] = useState<any[]>([])
  const [show, setShow] = useState(false);
  const [token, setToken] = useState<any>('');

  const limit = 10
  const [pagination, setPagination] = useState(1)
  const [totalPages, setTotalPages] = useState<any[]>([])
  const [listCoupons, setListCoupons] = useState<any[]>([])

  const changeParam = (element: any) => {
    const value = element.target.value
    const oldParams = params
    oldParams[`${element.target.name}`] = value
    setParams({ ...oldParams })
    verifyEnter(element)
  }

  const verifyEnter = (e: any) => {
    if (e.keyCode === 13) sendFilter()
  }

  const sendFilter = () => {
    setFilter({ ...params })
  }

  const verifyPagination = (page: any) => {
    let resultPage = (page - 1) * limit
    let newOffSet = { from: resultPage, to: resultPage + limit }

    coupons && setListCoupons(coupons.slice(newOffSet.from, newOffSet.to))
    setPagination(page)
  }

  const verifyTotalPages = (array: []) => {
    const total = []
    const length = array?.length / limit

    for (let i = 0; i < length; i++) {
      total.push(i + 1)
    }

    setTotalPages(total)
  }

  const verifyListCoupons = (array: []) => {
    setCoupons(array)
    array && setListCoupons(array.slice(0, 10))

    verifyTotalPages(array)
  }

  const validStatus = (status: any) => {
    if (status === "Paid") {
      return <span className="badge bg-success">{translateObj("MESSAGE.PAID")}</span>
    } else if (status === "Pending") {
      return <span className="badge bg-warning">{translateObj("MESSAGE.PENDING")}</span>
    } else {
      return <span className="badge bg-danger">{translateObj("MESSAGE.CANCELED")}</span>
    }
  }

  const validItem = (obj: any, search = 0) => {
    setToken('')

    if (obj?.order_id && search) {
      window.location.href = `../detailsProject/${obj.order_id}`;
    } else if (obj?.conecta_password && obj?.status === "Pending") {
      setShow(true)
      setToken(obj?.conecta_password)
    }
  }

  const downloadTermPayment = async (idPayment: any) => {
    await getAdminGeneratePdfPaymentId(idPayment).then(term => {
      const windowPrint = window.open('CELER');
      windowPrint?.document.write(term);
      windowPrint?.window.print();
      windowPrint?.window.close();
    });
  }

  useEffect(() => {
    getAdminPayments(filter).then((data) => {
      verifyListCoupons(data)
    })
  }, [filter])

  return (
    <>
      <ModalToken translateObj={translateObj} show={show} setShow={setShow} token={token} />
      <div>
        <h3 className='mb-5'>{translateObj("MESSAGE.FILTER")}:</h3>
        <div className='mb-10'>
          <div className='row align-items-end'>
            <div className='col-8'>
              <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.COMPANY")}</label>
              <input type='text' name='company' className='form-control form-control-sm form-control-solid' onKeyUp={(e: any) => changeParam(e)} />
            </div>
            <div className="col-4">
              <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.STATUS")}</label>
              <select name='status' className='form-select form-select-sm form-select-solid' onChange={(e: any) => changeParam(e)}>
                <option value=''>{translateObj("MESSAGE.ALLSTATUS")}</option>
                <option value="Pending">{translateObj("MESSAGE.PENDING")}</option>
                <option value="Paid">{translateObj("MESSAGE.PAID")}</option>
                <option value="Canceled">{translateObj("MESSAGE.CANCELED")}</option>
              </select>
            </div>
          </div>
          <div className="mt-5">
            <button type="submit" className='w-100 btn btn-dark text-white' onClick={sendFilter}>
              <i className='fa fa-search'></i>
              <span>{translateObj("BTN.SEARCH")}</span>
            </button>
          </div>
        </div>

        {showResult && (<h3 className='mb-5'>{coupons?.length + ' ' + translateObj("MESSAGE.RESULTS")}:</h3>)}
        <div className="table-responsive">
          <table className="table table-hover">
            <thead className='table-light border-bottom text-uppercase fw-bolder bg-secondary text-center'>
              <tr>
                <td>{translateObj("MESSAGE.COMPANY")}</td>
                <td>{translateObj("MESSAGE.STATUS")}</td>
                <td>{translateObj("MESSAGE.TYPE")}</td>
                <td>{translateObj("MESSAGE.VALUE")}</td>
                <td>{translateObj("MESSAGE.QUANTITY")}</td>
                <td>{translateObj("MESSAGE.COUPON")}</td>
                <td>{translateObj("MESSAGE.DATE.ORDER")}</td>
                <td>{translateObj("MESSAGE.DATE.PAYMENT")}</td>
                <td>{translateObj("MESSAGE.TOKENPAYMENT")}</td>
                <td>Termo</td>
                <td>{translateObj("MESSAGE.ACTIONS")}</td>
              </tr>
            </thead>
            <tbody className='text-center'>
              {listCoupons?.length ? (listCoupons?.map(item => {

                return (
                  <tr key={item.id} className='cursor-pointer border-bottom'>
                    <td>{item?.company?.name}</td>
                    <td>{validStatus(item?.status)}</td>
                    <td>{item.is_credit ? "Express" : "Premium"}</td>
                    <td>{formatReal(item?.price)}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.cupons?.name ? item?.cupons?.name : '---'}</td>
                    <td>{moment(item?.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{item?.payments[0]?.paid_at ? convertDate(item?.payments[0]?.paid_at + "T") : '--'}</td>
                    <td>
                      {(item?.conecta_password && item?.status === "Pending") ? (
                        <button className="btn btn-primary btn-sm px-3" onClick={() => validItem(item, 0)}>
                          <i className="fa fa-eye" />
                        </button>
                      ) : '--'}
                    </td>
                    <td>
                      <button className='btn btn-dark btn-sm px-3' title="Termo" onClick={() => downloadTermPayment(item?.id)}>
                        <i className="fa fa-file" />
                      </button>
                    </td>
                    <td>
                      {item?.order_id ? (
                        <button className="btn btn-primary btn-sm px-3" onClick={() => validItem(item, 1)}>
                          <i className="fa fa-search" />
                        </button>
                      ) : '--'}
                    </td>
                  </tr>
                )
              })) : (
                <tr>
                  <td colSpan={11}>{translateObj("MESSAGE.NOTRESULT")}</td>
                </tr>
              )}
            </tbody>
          </table>

          {totalPages.length > 0 && (
            <div className="float-end my-5 text-center">
              {translateObj("MESSAGE.PAGINATION")}
              <div className="d-flex pt-3">
                {totalPages.map(item => {
                  return (
                    <button key={item} type="button" className={`btn btn-sm text-primary p-0 m-0 px-1 border mx-1 ${pagination === item && 'border-primary border-2'}`} onClick={() => verifyPagination(item)}>{item}</button>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default function ReportTeam(props: { translateObj: any, screen: string }) {
  const { translateObj, screen } = props

  return (
    <>
      {screen === 'payment' ? <TablePayment translateObj={translateObj} showResult={true} /> : <TableUse translateObj={translateObj} showResult={true} />}
    </>
  )
}