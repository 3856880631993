import { useIntl } from 'react-intl';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { useEffect, useState } from 'react';
import { getNotifications, patchNotificationsIdRead } from '../../services/Api';
import { typeNotification } from '../../utils';

const Notifications = () => {
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const intl = useIntl();
  const translateObj = (obj: any, varInfo?: any) => {
    if (varInfo) {
      return intl.formatMessage({ id: obj }, varInfo)
    }

    return intl.formatMessage({ id: obj })
  }

  const [modified, setModified] = useState(1)
  const [notifications, setNotifications] = useState<any[]>([]);

  const limit = 10
  const [pagination, setPagination] = useState(1)
  const [totalPages, setTotalPages] = useState<any[]>([])
  const [listNotifications, setListNotifications] = useState<any[]>([]);

  const verifyPagination = (page: any) => {
    let resultPage = (page - 1) * limit
    let newOffSet = { from: resultPage, to: resultPage + limit }

    setListNotifications(notifications.slice(newOffSet.from, newOffSet.to))
    setPagination(page)
  }

  const verifyTotalPages = (array: []) => {
    const total = []
    const length = array.length / limit

    for (let i = 0; i < length; i++) {
      total.push(i + 1)
    }

    setTotalPages(total)
  }

  const verifyListNotifications = (array: []) => {
    setNotifications(array)
    setListNotifications(array.slice(0, 10))

    verifyTotalPages(array)
  }

  const confirmReading = (id: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(translateObj("MESSAGE.WANTTOCONFIRM"))) {
      patchNotificationsIdRead(id).then(() => {
        setModified(Math.random());
      })
    }
  }

  useEffect(() => {
    getNotifications().then(data => {
      data && verifyListNotifications(data);
    });
  }, [modified])

  return (
    <>
      {user && (user?.type ? (
        <>
          <h1 className='mb-10'>{translateObj("MESSAGE.NOTIFICATIONS")}</h1>
          <div className='card'>
            <div className='card-body'>
              <div className='alert alert-warning mb-10'>
                {translateObj("MESSAGE.ALERTNOTIFICATIONS")}
              </div>

              <div className="d-flex justify-content-between align-items-center mb-5">
                <h3 className='mb-5'>{notifications?.length + ' ' + translateObj("MESSAGE.RESULTS")}:</h3>
              </div>

              <div className="table-responsive">
                <table className="table table-hover">
                  <thead className='table-light border-bottom text-uppercase fw-bolder bg-secondary text-center'>
                    <tr>
                      <td>{translateObj("MESSAGE.TYPE")}</td>
                      <td align='left'>{translateObj("MESSAGE.MESSAGE")}</td>
                      <td>{translateObj("MESSAGE.READIN")}</td>
                    </tr>
                  </thead>
                  <tbody className='text-center'>
                    {listNotifications?.length ? (listNotifications.map(item => {
                      return (
                        <tr key={item.id} className='cursor-pointer border-bottom' onClick={() => {
                          if (item?.notification_type_id === 1) {
                            item.company_id && (window.location.href = './company/' + item.company_id);  
                          } else {
                            item.order_id && (window.location.href = './detailsProject/' + item.order_id);
                          }
                        }}>
                          {console.log(item)}
                          <td width={178}>{typeNotification(item.notification_type_id)}</td>
                          <td align='left'>{item.message}</td>
                          <td width={178}>{item.is_read ? moment(item.read_at).format('DD/MM/YYYY HH:mm') : (
                            <button type='button' className='btn btn-success btn-sm' onClick={() => confirmReading(item.id)}>
                              <i className='fa fa-check me-2'></i>
                              {translateObj("MESSAGE.CONFIRMREADING")}
                            </button>
                          )}</td>
                        </tr>
                      )
                    })) : (
                      <tr>
                        <td colSpan={3}>{translateObj("MESSAGE.NOTRESULT")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {totalPages?.length > 0 && (
                  <div className="float-end my-5 text-center">
                    {translateObj("MESSAGE.PAGINATION")}
                    <div className="d-flex pt-3">
                      {totalPages.map(item => {
                        return (
                          <button key={item} type="button" className={`btn btn-sm text-primary p-0 m-0 px-1 border mx-1 ${pagination === item && 'border-primary border-2'}`} onClick={() => verifyPagination(item)}>{item}</button>
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <h5>{translateObj("VALID.PERMISSION")}</h5>
      ))}
    </>
  )
}

export { Notifications }