import { KTSVG } from '../../../../../_metronic/helpers'
import { Field, ErrorMessage, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import { maskCep, maskCpOrCnpj, validCPFandCNPJ } from '../../../../utils'
import { postPaymentCreditPay } from '../../../../services/Api'

export default function ContentCard(props: { translateObj: any, credit: any }) {
  let { translateObj, credit } = props
  const [paid, setPaid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const initialValues = {
    cpfCnpj: '',
    zip_code: '',
    holder_name: '',
    number: '',
    expiry_month: '',
    expiry_year: '',
    ccv: '',
  }

  const currentSchema = Yup.object().shape({
    "cpfCnpj": Yup.string()
      .min(14, translateObj("VALID.MIN.CARACTER", { "length": 14 }))
      .max(18, translateObj("VALID.MAX.CARACTER", { "length": 18 }))
      .required(translateObj("VALID.REQUIRED")),
    "zip_code": Yup.string()
      .min(8, translateObj("VALID.MIN.CARACTER", { "length": 8 }))
      .max(9, translateObj("VALID.MAX.CARACTER", { "length": 9 }))
      .required(translateObj("VALID.REQUIRED")),
    "holder_name": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(80, translateObj("VALID.MAX.CARACTER", { "length": 80 }))
      .required(translateObj("VALID.REQUIRED")),
    "number": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(19, translateObj("VALID.MAX.CARACTER", { "length": 19 }))
      .required(translateObj("VALID.REQUIRED")),
    "expiry_month": Yup.string().required(translateObj("VALID.REQUIRED")),
    "expiry_year": Yup.string().required(translateObj("VALID.REQUIRED")),
    "ccv": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(4, translateObj("VALID.MAX.CARACTER", { "length": 4 }))
      .required(translateObj("VALID.REQUIRED")),
  })

  const submitStep = (values: any) => {
    setLoading(true)

    let param = values

    param.credit_id = credit?.creditId;
    param.method = 'CREDIT_CARD';
    param.cpfCnpj = param.cpfCnpj.trim().replaceAll(/\./g, '').replaceAll('-', '').replaceAll('/', '').replaceAll(' ', '');
    param.zip_code = param.zip_code.trim().replaceAll('-', '');
    param.number = param.number.trim().replaceAll(/\./g, '').replaceAll('-', '').replaceAll('/', '').replaceAll(' ', '');

    postPaymentCreditPay(param).then((data) => {
      setErrors([])
      setLoading(false)

      if (data?.status && data?.status === "CONFIRMED") {
        setPaid(true)
      } else if (data?.errors) {
        setErrors(data?.errors)
      }
    })
  }

  return (
    <>
      {paid ? (
        <div className='card'>
          <div className="card-body">
            <h1 className="card-title text-success d-flex align-items-center">
              <i className='fa fa-check-circle text-success fa-2x'></i>
              <span className='mx-3'>{translateObj("MESSAGE.PAYMENTMADE")}!</span>
            </h1>
          </div>
        </div>
      ) : (
        <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitStep}>
          {({ values, setFieldValue }) => (
            <Form className='mx-auto =w-100 pt-5 pb-5' id='kt_card_form'>
              {errors.length > 0 && (
                <div className='my-5 mb-15'>
                  <b>{translateObj("MESSAGE.ERROSCARD")}:</b>

                  <ul className='mt-3'>
                    {errors.map((msg: any) => {
                      return (
                        <li className='text-danger'>{msg?.description}</li>
                      )
                    })}
                  </ul>
                </div>
              )}
              <div className='w-100'>
                <div className='d-flex flex-column mb-7 fv-row'>
                  <label className='required fs-6 fw-bold form-label mb-2'>{translateObj("MESSAGE.DOCUMENT")}</label>

                  <div className='position-relative'>
                    <Field
                      type='text'
                      className='form-control form-control-solid'
                      name='cpfCnpj'
                      value={values.cpfCnpj ? maskCpOrCnpj(values.cpfCnpj) : ''}
                      onKeyUp={(e: any) => setFieldValue('cpfCnpj', maskCpOrCnpj(e.target.value))}
                      onBlur={(e: any) => {
                        var length = e?.target?.value.length;

                        if ((length === 14 || length === 18) && !validCPFandCNPJ(e?.target?.value)) {
                          setFieldValue('cpfCnpj', '')
                        }
                      }}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='cpfCnpj' />
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-column mb-7 fv-row'>
                  <label className='required fs-6 fw-bold form-label mb-2'>{translateObj("MESSAGE.ZIPCODE")}</label>

                  <div className='position-relative'>
                    <Field
                      type='text'
                      className='form-control form-control-solid'
                      name='zip_code'
                      maxLength={9}
                      value={values.zip_code ? maskCep(values.zip_code) : ''}
                      onKeyUp={(e: any) => setFieldValue('zip_code', maskCep(e.target.value))}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='zip_code' />
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-column mb-7 fv-row'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                    <span className='required'>{translateObj("MESSAGE.NAMECARD")}</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title="Nome que está especificado no cartão."
                    ></i>
                  </label>

                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    name='holder_name'
                    maxLength={80}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='holder_name' />
                  </div>
                </div>

                <div className='d-flex flex-column mb-7 fv-row'>
                  <label className='required fs-6 fw-bold form-label mb-2'>{translateObj("MESSAGE.NUMBERCARD")}</label>

                  <div className='position-relative'>
                    <Field
                      type='text'
                      className='form-control form-control-solid'
                      name='number'
                      maxLength={19}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='number' />
                    </div>
                  </div>
                </div>

                <div className='row mb-10'>
                  <div className='col-md-8 fv-row'>
                    <div className='row fv-row'>
                      <div className='col-6'>
                        <label className='required fs-6 fw-bold form-label mb-2'>{translateObj("MESSAGE.EXPIRY.MONTH")}</label>

                        <Field as='select' name='expiry_month' className='form-select form-select-solid'>
                          <option value=''>{translateObj("MESSAGE.SELECT")}</option>
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='5'>5</option>
                          <option value='6'>6</option>
                          <option value='7'>7</option>
                          <option value='8'>8</option>
                          <option value='9'>9</option>
                          <option value='10'>10</option>
                          <option value='11'>11</option>
                          <option value='12'>12</option>
                        </Field>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='expiry_month' />
                        </div>
                      </div>

                      <div className='col-6'>
                        <label className='required fs-6 fw-bold form-label mb-2'>{translateObj("MESSAGE.EXPIRY.YEAR")}</label>

                        <Field type='text' minLength={4} maxLength={4} name='expiry_year' className='form-control form-control-solid' />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='expiry_year' />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 fv-row'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>CVV</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Código de segurança do cartão - CVV'
                      ></i>
                    </label>

                    <div className='position-relative'>
                      <Field
                        type='text'
                        className='form-control form-control-solid'
                        minLength={3}
                        maxLength={4}
                        placeholder='***'
                        name='ccv'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='ccv' />
                      </div>

                      <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                        <KTSVG path='/media/icons/duotune/finance/fin002.svg' className='svg-icon-2hx' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <span className='text-center indicator-progress' style={{ display: 'block' }}>
                  {translateObj("MESSAGE.LOADING") + ' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <button type='submit' className='btn btn-lg btn-dark text-white w-100'>
                  <i className='fa fa-check me-3'></i>
                  <span className='indicator-label'>
                    {translateObj("BTN.PAY")}
                  </span>
                </button>
              )}
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}