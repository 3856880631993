import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useState } from 'react';
import { putAdminAiPhotoChooseId, putAdminEnviromentApproveId, putAdminOrderPhotoRemove } from '../../../services/Api';

export function ModalConfirm(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, item: any, type: any, setModified: Dispatch<SetStateAction<number>> }) {
  const { translateObj, show, setShow, item, type, setModified } = props;
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setShow(!show);
  }

  const initialValues = {
    type: type,
    description: '',
  }

  const currentSchema = type === 'approve' ? Yup.object().shape({}) : Yup.object().shape({
    "description": Yup.string().required(translateObj("VALID.REQUIRED")),
  })

  const submitForm = (values: any) => {
    setLoading(true)

    if (values.type === 'approve') {
      if (item?.is_ai_render) {
        putAdminAiPhotoChooseId(item.id).then(() => {
          setModified(Math.random())
        })
      } else {
        putAdminEnviromentApproveId(item.enviroment_id).then(() => {
          setModified(Math.random())
        })
      }
    } else {
      putAdminOrderPhotoRemove({ photo_id: item.id, description: values.description }).then(() => {
        setModified(Math.random())
      })
    }

    setLoading(false)
    setShow(!show);
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitForm}>
        {({ values, setFieldValue }) => (
          <Form id='kt_reproach_form'>
            <Modal.Header>
              <Modal.Title className={`${type === 'approve' ? 'text-success' : 'text-danger'}`}>
                {type === 'approve' ? translateObj("MESSAGE.APPROVEIMAGETEAM") : translateObj("MESSAGE.REPROVEIMAGETEAM")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                {type === 'approve' ? translateObj("MESSAGE.APPROVEIMAGETEAM.DESCRIPTION") : (
                  <div>
                    <div className='fs-6'>{translateObj("MESSAGE.REPROVEIMAGETEAM.DESCRIPTION")}</div>
                    <div className='fv-row mt-7'>
                      <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.REASONREJECTION")}</label>

                      <Field as='textarea' name='description' className='form-control form-control-lg form-control-solid' />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='description' />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <>
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </>
              ) : (
                <>
                  <button type='button' className='btn btn-default' onClick={handleClose}>
                    {translateObj("BTN.CANCEL")}
                  </button>
                  {type === 'approve' ? (
                    <button type='submit' className='btn btn-success'>
                      <i className='fa fa-check'></i>
                      <span className='mx-2'>{translateObj("BTN.CONFIRM")}</span>
                    </button>
                  ) : (
                    <button type='submit' className='btn btn-danger'>
                      <i className='fa fa-times'></i>
                      <span className='mx-2'>{translateObj("BTN.CONFIRM")}</span>
                    </button>
                  )}
                </>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}