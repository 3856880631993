import { useState } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { RootState } from "../../../../../setup"
import { AsideMenuItem } from "../AsideMenuItem"
import { AsideMenuItemToggle } from "../AsideMenuItemToggle"

export default function MenuTeam(props: { translateObj: any }) {
  let { translateObj } = props;

  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const notPermission = !['Executive', 'Influencer'].includes(user?.type)
  const menu = [
    {
      name: translateObj("MESSAGE.PROJECTS"), permission: notPermission, active: false, urls: [
        { title: translateObj("MESSAGE.KANBAN"), to: '/kanban', permission: true, icon: '/media/icons/duotune/general/gen009.svg' },
        { title: translateObj("MESSAGE.CONSULTGENERAL"), permission: true, to: '/report', icon: '/media/icons/duotune/general/gen005.svg' },
        { title: translateObj("MESSAGE.NEWPROJECT"), permission: true, to: '/create', icon: '/media/icons/duotune/general/gen041.svg' }
      ]
    },
    {
      name: translateObj("MESSAGE.CLIENTS"), permission: notPermission, active: false, urls: [
        { title: translateObj("MESSAGE.CONSULTCLIENTS"), to: '/company/report', permission: true, icon: '/media/icons/duotune/general/gen005.svg' }
      ]
    },
    {
      name: translateObj("MESSAGE.USERS"), permission: notPermission, active: false, urls: [
        { title: translateObj("MESSAGE.CONSULTTEAMS"), to: '/teams/report', permission: (user?.type === 'Squad' || user?.type === 'Squadinator'), icon: '/media/icons/duotune/general/gen005.svg' },
        { title: translateObj("MESSAGE.CONSULTUSERS"), to: '/users/report', permission: (user?.type === 'Squad' || user?.type === 'Squadinator'), icon: '/media/icons/duotune/general/gen005.svg' },
        { title: translateObj("MESSAGE.CONSULTINFLUENCERS"), to: '/influencers/report', permission: true, icon: '/media/icons/duotune/general/gen005.svg' },
        { title: translateObj("MESSAGE.CONSULTEXECUTIVES"), to: '/executives/report', permission: true, icon: '/media/icons/duotune/general/gen005.svg' },
      ]
    },
    {
      name: translateObj("MESSAGE.FINANCIAL"), permission: (user?.type === 'Squad' || user?.type === 'Squadinator'), active: false, urls: [
        { title: translateObj("MESSAGE.CONSULTCOUPONS"), to: '/coupon/report', permission: true, icon: '/media/icons/duotune/general/gen005.svg' },
        { title: translateObj("MESSAGE.CONSULTPAYMENTS"), to: '/credit/report', permission: true, icon: '/media/icons/duotune/general/gen005.svg' },
        // { title: translateObj("MESSAGE.CONSULTNFE"), to: '/nf/report', permission: true, icon: '/media/icons/duotune/general/gen005.svg' },
      ]
    }
  ]
  const [multiMenu, setMultiMenu] = useState<any>(menu)

  const openMultiMenu = (key: any) => {
    setMultiMenu(menu.map((itemDefault, keyDefault) => {
      return { ...itemDefault, active: keyDefault === key }
    }))
  }

  return (
    <>
      {multiMenu.map((itemMultiMenu: any, keyMultiMenu: number) => {
        if (itemMultiMenu.permission) {
          return (
            <div key={keyMultiMenu} onClick={() => openMultiMenu(keyMultiMenu)}>
              <AsideMenuItemToggle
                anchor={`#${itemMultiMenu.name}`}
                urls={itemMultiMenu.urls}
                title={itemMultiMenu.name}
                activeMultiMenu={itemMultiMenu.active}
              >
                {itemMultiMenu.urls.map((itemMenu: any, keyMenu: number) => {
                  if (itemMenu.permission) {
                    return (
                      <AsideMenuItem
                        key={keyMenu}
                        to={itemMenu.to}
                        icon={itemMenu.icon}
                        title={itemMenu.title}
                        fontIcon='bi-layers'
                      />
                    )
                  }
                })}
              </AsideMenuItemToggle>
            </div>
          )
        }
      })}
    </>
  )

}
