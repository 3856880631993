import {FC} from 'react'
import {useLang} from './Metronici18n'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/br'
import '@formatjs/intl-relativetimeformat/locale-data/en'

import brMessages from './messages/br.json'
import enMessages from './messages/en.json'
import esMessages from './messages/es.json'
import ptMessages from './messages/pt.json'
import cnMessages from './messages/cn.json'

const allMessages = {
  br: brMessages,
  en: enMessages,
  es: esMessages,
  pt: ptMessages,
  cn: cnMessages
}

const I18nProvider: FC = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
