/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable eqeqeq */
import axios from "axios";
import moment from "moment";

export const reloadPageLogout = () => {
  localStorage.clear()
  window.location.reload()
}

function addHours(date: any, hours: any) {
  date.setHours(date.getHours() + hours);

  return date;
}

export const convertDateUs = (date: any) => {
  return `${date.split("/")[2]}-${date.split("/")[1]}-${date.split("/")[0]}`;
}

export const typeProductName = (item: any) => {
  let name = "";
  
  if(item.is_celer_express) {
    name = "Celer"
  } else if (item.is_basic_ai) {
    name = "Celer AI"
  } else {
    name = "Celer Premium"
  }

  return name;
}

export const verifyIconTimer = (status: any) => {
  let iconTimer = `clock`
  const statusValid = ['Waiting_Service', 'In_Progress', 'In_Review', 'In_Review_Squad']

  if (statusValid.indexOf(status) > -1) {
    iconTimer = `hourglass`
  }

  return iconTimer
}

export const verifyStatus = (string: any) => {
  let color = ``
  let hour = Number(string.replaceAll(" ", "").replaceAll("h", "").replaceAll("min", ""))

  if (hour < 0) {
    color = `text-danger fw-bold`
  }

  return color
}

export const diffDays = (dateBD: any, status: any, dateReview: any) => {
  let valid = 0
  let expiredDate = new Date(dateBD);

  // if (status === 'Waiting_Service') {
  //   expiredDate = addHours(expiredDate, 4)
  //   valid = 1
  // } else if (status === 'In_Progress') {
  //   expiredDate = addHours(expiredDate, dateReview ? 24 : 48)
  //   valid = 1
  // } else if (status === 'In_Review') {
  //   expiredDate = addHours(expiredDate, 4)
  //   valid = 1
  // } else if (status === 'In_Review_Squad') {
  //   expiredDate = addHours(expiredDate, 4)
  //   valid = 1
  // }

  var now = moment(new Date());
  var end = moment(expiredDate);

  let duration = null
  if (valid) {
    duration = moment.duration(end.diff(now));
  } else {
    duration = moment.duration(now.diff(end));
  }

  var hours = duration.asHours();
  var minutes = duration.asMinutes();

  let time = null
  if (minutes > 60) {
    time = `${hours.toPrecision(2).split(".")[0]} h`
  } else {
    time = `${minutes.toPrecision(2).split(".")[0]} min`
  }

  return time
}

export const searchTypeUsers = (name: string) => {
  switch (name) {
    case 'Operator':
      return "Operador";
    case 'Squad':
      return "Squader";
    case 'Coordinator':
      return "Coordenador";
  }
}

export const typeNotification = (type: number) => {
  switch (type) {
    case 1:
      return "Novo Cliente";
    case 2:
      return "Pagamentos e Tentativas de Pagamentos";
    case 3:
      return "Projeto Concluídos";
    case 4:
      return "Projeto Finalizado";
    case 5:
      return "Atribuição a Nova Tarefa";
    case 6:
      return "Atribuição a um Time";
    case 7:
      return "Reprovação / Aprovação de Tarefa";
    case 8:
      return "Seleção de Fotografia por Squader";
    case 9:
      return "Pagamento Aprovado";
    case 10:
      return "Imagens Finalizadas / Aguardando Aprovação";
    case 11:
      return "Cliente Subiu Nova Imagem";
  }
}

export const statusOption = [{ value: "Waiting_Payment", label: "Aguardando pagamento" },
{ value: "Waiting_Service", label: "Aguardando atendimento" },
{ value: "In_Progress", label: "Em progresso" },
{ value: "Waiting_Customer", label: "Alteração cliente" },
{ value: "In_Review", label: "Review coordenação" },
{ value: "In_Review_Squad", label: "Review squad" },
{ value: "Finished", label: "Finalizado" },]

export const searchProduct = (name: string) => {
  switch (name) {
    case 'High':
      return "Alto";
    case 'Average':
      return "Médio";
    case 'Economic':
      return "Econômico";
  }
}

export const searchType = (name: string) => {
  switch (name) {
    case 'Residential':
      return "Residencial";
    case 'Commercial':
      return "Comercial";
    case 'Others':
      return "Outros";
  }
}

export const searchCategory = (name: string) => {
  switch (name) {
    case 'Rent':
      return "Aluguel";
    case 'Sell':
      return "Venda";
  }
}

export const searchDecoration = (name: string) => {
  switch (name) {
    case 'Empty':
      return "Minimalista/Clean";
    case 'Classic':
      return "Clássico";
    case 'Sophisticated':
      return "Sofisticado";
    case 'Young':
      return "Jovem/Descolado";
  }
}

export const searchStatusColor = (status: string) => {
  switch (status) {
    case 'Draft':
      return 'bg-dark'
    case 'Waiting_Payment':
      return 'bg-light text-dark';
    case 'Waiting_Service':
      return 'bg-warning text-dark'
    case 'In_Progress':
      return 'bg-primary'
    case 'Waiting_Customer':
      return 'bg-secondary text-dark'
    case 'In_Review':
      return 'bg-info text-white'
    case 'In_Review_Squad':
      return 'bg-info text-white'
    case 'Finished':
      return 'bg-success'
  }
}

export const searchStatus = (status: string) => {
  switch (status) {
    case 'Draft':
      return "Rascunho";
    case 'Waiting_Payment':
      return "Aguardando pagamento";
    case 'Waiting_Service':
      return "Aguardando atendimento"
    case 'In_Progress':
      return "Em progresso"
    case 'Waiting_Customer':
      return "Alteração cliente"
    case 'In_Review':
      return "Review coordenação"
    case 'In_Review_Squad':
      return "Review squad"
    case 'Finished':
      return "Finalizado"
  }
}

export const searchStatusStringToNumber = (status: string) => {
  switch (status) {
    case 'Waiting_Payment':
      return 1;
    case 'Waiting_Service':
      return 2
    case 'In_Progress':
      return 3
    case 'Waiting_Customer':
      return 4
    case 'In_Review':
      return 5
    case 'In_Review_Squad':
      return 6
    case 'Finished':
      return 7
  }
}

export const languages = [
  { id: 'br', name: 'Português (BR)' }, 
  { id: 'pt', name: 'Português (PT)' }, 
  { id: 'en', name: 'Inglês' }, 
  { id: 'es', name: 'Espanhol' },
  { id: 'cn', name: 'Chinês' }
];

export const searchStatusNumberToString = (status: number) => {
  switch (status) {
    case 1:
      return 'Waiting_Payment';
    case 2:
      return 'Waiting_Service'
    case 3:
      return 'In_Progress'
    case 4:
      return 'Waiting_Customer'
    case 5:
      return 'In_Review'
    case 6:
      return 'In_Review_Squad'
    case 7:
      return 'Finished'
  }
}

export const maskCep = (string: any) => {
  if (!string) {
    return '';
  }

  return string.toString().replace(/(\d{5})(\d{3})/g, '$1-$2');
}

export const maskCepPt = (string: any) => {
  if (!string) {
    return '';
  }

  return string.toString().replace(/(\d{4})(\d{3})/g, '$1-$2');
}

export const maskCpOrCnpj = (value: any) => {
  if (!value) return '';

  if (value.length <= 14) {
    let cpf = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
    return cpf
  } else {
    var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    let cnpj = !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
    return cnpj
  }
}

export const formatReal = (value: any) => {
  const valueBr = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);

  return valueBr;
}

export const formatPT = (value: any) => {
  const valueBr = new Intl.NumberFormat('sfb', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  }).format(value);

  return valueBr;
}

export const convertDate = (textDate: string) => {
  let dateSplit = textDate.split("T");
  const date = dateSplit[0];
  const hour = dateSplit[1].replace(":00.000Z", "");
  return new Date(`${date} ${hour}`).toLocaleString("pt-BR").replace(",", "");
}

export const convertDateOnly = (textDate: string) => {
  return textDate.split("T")[0];
}

const isNumber = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const maskMoney = (value: any) => {
  const valueString = value.toString().replaceAll(",", "").replaceAll(".", "");

  if (valueString.length <= 2) {
    return value
  }

  const decimal = valueString.substr(-2, 2);
  const valueInt = valueString.substr(0, valueString.length - 2);
  const valueFull = Number(`${valueInt}.${decimal}`)

  return valueFull.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
}

export const maskMoneyOld = (value: any) => {
  if (!value) return ''

  let newValue = value;

  newValue = newValue + '';
  newValue = parseInt(newValue.replace(/[\D]+/g, ''));
  newValue = newValue + '';
  newValue = newValue.replace(/([0-9]{2})$/g, ".$1");

  if (newValue.length > 6) {
    newValue = newValue.replace(/([0-9]{3}),([0-9]{2}$)/g, "$1.$2");
  }

  return Number(newValue).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
}

export const validCep = (value: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void, setLoadingZipCode: React.Dispatch<React.SetStateAction<boolean>>) => {
  setLoadingZipCode(true)
  axios.get(`https://viacep.com.br/ws/${value.replace("-", "")}/json/`).then(response => {
    const data = response.data
    let localidade = '';
    let uf = '';
    let logradouro = '';

    if (!data.erro) {
      localidade = data?.localidade
      uf = data?.uf
      logradouro = data?.logradouro
    }

    setFieldValue('city', localidade)
    setFieldValue('state', uf)
    setFieldValue('address', logradouro)
    setLoadingZipCode(false)
  }).catch(() => setLoadingZipCode(false))
}

export const maskPhone = (value: string) => {
  if (!value) return ''

  const isCellphone = [10, 11, 15].includes(value.length);

  if (isCellphone) {
    return value.replace(
      /^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/gm,
      (regex, val1, val2, val3) => {
        return `(${val1}) ${val2}-${val3}`;
      }
    );
  }

  return value
}

export const validCPFandCNPJ = (val: any) => {
  if (val.length == 14) {
    var cpf = val.trim();

    cpf = cpf.replace(/\./g, '');
    cpf = cpf.replace('-', '');
    cpf = cpf.split('');

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = ((v1 * 10) % 11);

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = ((v2 * 10) % 11);

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  } else if (val.length == 18) {
    var cnpj = val.trim();

    cnpj = cnpj.replace(/\./g, '');
    cnpj = cnpj.replace('-', '');
    cnpj = cnpj.replace('/', '');
    cnpj = cnpj.split('');

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] != cnpj[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1;
      } else {
        v1 += cnpj[i] * p2;
      }
    }

    v1 = (v1 % 11);

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = (11 - v1);
    }

    if (v1 != cnpj[12]) {
      return false;
    }

    for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1;
      } else {
        v2 += cnpj[i] * p2;
      }
    }

    v2 = (v2 % 11);

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = (11 - v2);
    }

    if (v2 != cnpj[13]) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}