import { useEffect, useState } from 'react'
import { getPaymentCredit, putOrderPayment } from '../../../../services/Api'
import { ModalAlertUseCredit } from './ModalAlertUseCredit'
import { ModalUseCredit } from './ModalUseCredit'

export default function UseCredit(props: { translateObj: any, order: any, typeSelectOrder: any, functionPayment: () => void }) {
  const { translateObj, order, typeSelectOrder, functionPayment } = props
  const [loading, setLoading] = useState(false)
  const [hasCredit, setHasCredit] = useState(false)
  const [show, setShow] = useState(false)
  const [showHasCredit, setShowHasCredit] = useState(false)
  const [typeSelect, setTypeSelect] = useState<any>({ credit: true, image: false })

  const functionAlert = () => {
    if (hasCredit) {
      putOrderPayment({ order_id: order?.id, method: 'Pix', is_celer_express: typeSelect?.credit }).then(data => {
        !data?.error && functionPayment()
      })
    }
  }

  const useCredit = () => {
    setLoading(true)

    if (hasCredit) {
      functionAlert()
    } else {
      setShow(true)
    }

    setTimeout(() => setLoading(false), 3000)
  }

  useEffect(() => {
    getPaymentCredit().then(data => {
      if (typeSelectOrder.is_celer_express || typeSelectOrder.express) {
        data?.celer_express ? setHasCredit(true) : setHasCredit(false)
      } else {
        data?.image ? setHasCredit(true) : setHasCredit(false)
      }
    })
  }, [])

  return (
    <div>
      <div className='col-12 mt-10'>
        <h4 className='mb-3'>{translateObj("MESSAGE.CONFIRMATIONORDER")}</h4>
        {hasCredit ? (
          <div>
            <ModalAlertUseCredit translateObj={translateObj} show={showHasCredit} setShow={setShowHasCredit} functionAlert={functionAlert} typeSelect={typeSelect} setTypeSelect={setTypeSelect} />
            <p className='fs-5'>
              - Cada ambiente equivale 1 saldo;
            </p>
            <h1 className='p-3 bg-secondary text-center' style={{ border: '2px dashed' }}>
              <b className='fw-normal pe-3'>{translateObj("MESSAGE.VALUETOTAL")}:</b>
              {order?.enviroments.length}
            </h1>
          </div>
        ) : (
          <div>
            <ModalUseCredit translateObj={translateObj} show={show} setShow={setShow} data={order} typeSelectOrder={typeSelectOrder} />
            <p className='fs-5'>
              {typeSelectOrder.celerAI ? "- 01 imagem avulsa custa: R$ 49,90" : translateObj("MESSAGE.DESCRIPTION.VALUE.BUYIMAGE")}
            </p>
          </div>
        )}
      </div>

      <div className='mb-10'>
        {loading ? (
          <span className='text-center indicator-progress' style={{ display: 'block' }}>
            {translateObj("MESSAGE.LOADING") + ' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        ) : (
          <button type='submit' className='btn btn-lg btn-success w-100' onClick={useCredit}>
            <i className='fa fa-check'></i>
            <span className='indicator-label mx-2'>
              {translateObj("BTN.CONFIRM")}
            </span>
          </button>
        )}
      </div>
    </div>
  )
}