import { useEffect, useState } from "react"
import { getExecutiveDashboard } from "../../../services/Api"
import moment from 'moment';
import { maskPhone } from "../../../utils";

const Executive = (props: { translateObj: any }) => {
  let { translateObj } = props;
  const [loading, setLoading] = useState(false)
  const [realEstate, setRealEstate] = useState<any>()

  useEffect(() => {
    setLoading(true)
    getExecutiveDashboard().then(data => {
      if (data) {
        setRealEstate(data)
        setLoading(false)
      }
    })
  }, [])

  return (
    <>
      <p>{translateObj("MESSAGE.TRACKCLIENTS")}</p>

      <div className="card p-10 mt-10">
        <h3 className='mb-5'>{realEstate?.length + ' ' + translateObj("MESSAGE.RESULTS")}:</h3>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead className='table-light border-bottom text-uppercase fw-bolder bg-secondary text-center'>
              <tr>
                <td>{translateObj("MESSAGE.NAME")}</td>
                <td>{translateObj("MESSAGE.CITYUF")}</td>
                <td>{translateObj("MESSAGE.PHONESUSERS")}</td>
                <td>{translateObj("MESSAGE.QUANTITYBALANCE")}</td>
                <td>{translateObj("MESSAGE.DATE.CREATED")}</td>
              </tr>
            </thead>
            <tbody className='text-center'>
              {loading ? (
                <tr><td colSpan={4}>{translateObj("MESSAGE.LOADING") + ' '}</td></tr>
              ) : (
                <>
                  {realEstate?.map((item: any) => {
                    return (
                      <tr>
                        <td>{item?.name}</td>
                        <td>{item?.city} / {item?.state}</td>
                        <td>{item?.consumer.map((item: any, key: number) => {
                          return `${maskPhone(item?.phone)}, `
                        })}</td>
                        <td>{translateObj("MESSAGE.TOTALCREDIT")}: {item?.credit} <br /> {translateObj("MESSAGE.TOTALIMAGES")}: {item?.images}</td>
                        <td>{moment(item?.created_at).format('DD/MM/YYYY HH:mm')}</td>
                      </tr>
                    )
                  })}
                </>
              )
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export { Executive }