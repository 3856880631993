import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useState } from 'react';
import { putAdminDelay } from '../../../services/Api';
import { Images } from '../../detailsProject/components/Images';
import moment from 'moment';
import { ImagesCeler } from '../../detailsProject/components/ImagesCeler';

export function ModalDetail(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, order: any, setOrder: Dispatch<SetStateAction<any>>, setMovimentItem: Dispatch<SetStateAction<any>> }) {
  const { translateObj, show, setShow, order, setOrder, setMovimentItem } = props;
  const [flag, setFlag] = useState<boolean>(false)
  const [removeFlag, setRemoveFlag] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const initialValues = {
    description: '',
  }

  const currentSchema = Yup.object().shape({
    "description": Yup.string().required(translateObj("VALID.REQUIRED")),
  })

  const sendDelay = (param: any, action: number) => {
    if (action === 2) {
      param.order_id = order.id
      param.description = translateObj("MESSAGE.REMOVEDELAY.DESCRIPTION")
    }

    putAdminDelay(param).then(() => {
      setFlag(false)
      setLoading(false)
      setMovimentItem(Math.random())
      setOrder({ ...order, delay: { desciption: (action === 1 && param.description) } })
    });
  }

  const submitForm = (data: any) => {
    setLoading(true)
    let param = { ...data, order_id: order.id }

    sendDelay(param, 1)
  }

  const screenDelay = (type: string) => {
    if (type === 'add') {
      setRemoveFlag(false)
      setFlag(true)
    } else if (type === 'removeDelay') {
      setFlag(true)
      setRemoveFlag(true)
    } else {
      setFlag(false)
      setRemoveFlag(false)
    }
  }

  const handleClose = () => {
    setShow(false)
    setFlag(false)
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div>
          <h3 className='text-primary border-bottom border-primary pb-1'>{translateObj("MESSAGE.DETAILSPROJECT")} #{order?.number}</h3>
          <div className='d-grid gap-2 mt-5 mb-8'>
            <div>
              <b>{translateObj("MESSAGE.TEAM")}:</b> {order?.team?.name}
            </div>
            <div>
              <b>{translateObj("MESSAGE.COMPANY")}:</b> {order?.company.name}
            </div>
            <div>
              <b>{translateObj("MESSAGE.IDENTIFICATION")}:</b> {order?.name}
            </div>
            <div>
              {order?.finish_in && (
                <div>
                  <b className='me-1'>
                    <i className='fa fa-clock me-1'></i>
                    {translateObj("MESSAGE.DATEFORECAST")}:
                  </b>
                  {moment(order.finish_in).format('DD/MM/YYYY HH:mm')}
                </div>
              )}

              {order?.started_at && (
                <div>
                  <b className='me-1'>
                    <i className='fa fa-clock me-1'></i>
                    {translateObj("MESSAGE.DATESTART")}:
                  </b>
                  {moment(order.started_at).format('DD/MM/YYYY HH:mm')}
                </div>
              )}

              {order?.finished_at && (
                <div>
                  <b className='me-1'>
                    <i className='fa fa-clock me-1'></i>
                    {translateObj("MESSAGE.DATECONCLUSION")}:
                  </b>
                  {moment(order.finished_at).format('DD/MM/YYYY HH:mm')}
                </div>
              )}
            </div>
            <a href={`detailsProject/${order?.id}`}>(+) {translateObj("MESSAGE.SEEMOREDETAILS")}</a>
          </div>

          {order?.delay?.desciption && (
            <div className='mb-8'>
              <h3 className='text-danger border-bottom border-danger pb-1'>{translateObj("MESSAGE.DESCRIPTIONOVERDUE")}</h3>
              <div className='text-danger fw-bold'>
                {order?.delay?.desciption}
              </div>
            </div>
          )}

          {flag ? (
            <>
              {removeFlag ? (
                <>
                  {loading ? (
                    <>
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        {translateObj("MESSAGE.LOADING") + ' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    </>
                  ) : (
                    <>
                      <div className='d-grid gap-5'>
                        <button type='button' className='btn btn-danger w-100' onClick={() => sendDelay({}, 2)}>
                          <i className='fa fa-check me-2'></i>
                          {translateObj("MESSAGE.REMOVEDELAY")}
                        </button>
                        <button type='button' className='btn btn-default w-100' onClick={() => screenDelay('remove')}>
                          {translateObj("BTN.BACK")}
                        </button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <h3 className='text-danger border-bottom border-danger pb-1'>{translateObj("MESSAGE.DESCRIPTIONDELAY")}</h3>

                  <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitForm}>
                    {() => (
                      <Form id='kt_delay_form'>
                        <div className='fv-row mb-7'>
                          <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.REASONDELAY")}</label>

                          <Field as='textarea' name='description' className='form-control form-control-lg form-control-solid' />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='description' />
                          </div>
                        </div>
                        {loading ? (
                          <>
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              {translateObj("MESSAGE.LOADING") + ' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          </>
                        ) : (
                          <>
                            <div className='d-grid gap-5'>
                              <button type='submit' className='btn btn-danger w-100'>
                                <i className='fa fa-check me-2'></i>
                                {translateObj("BTN.SAVE")}
                              </button>
                              <button type='button' className='btn btn-default w-100' onClick={() => setFlag(false)}>
                                {translateObj("BTN.BACK")}
                              </button>
                            </div>
                          </>
                        )}

                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </>
          ) : (
            <>
              <h3 className='text-primary border-bottom border-primary pb-1'>{translateObj("MESSAGE.PHOTOS")}</h3>
              <div className='mt-5'>
                {order?.enviroments.map((item: any, key: any) => {
                  let keyItem = key + 1

                  return (
                    <div key={keyItem} className={`${key > 0 && 'border-top border-3 my-5 pt-5'}`}>
                      <span className='badge badge-primary mb-3'>{keyItem}° {translateObj("MESSAGE.AMBIENCE")}</span>
                      <div>
                        <b>{translateObj("MESSAGE.RESPONSIBLE")}:</b> {item.user?.name ? item.user?.name : translateObj("MESSAGE.NOTDATA")}
                      </div>
                      <div>
                        <b>{translateObj("MESSAGE.NAME")}:</b> {item.name}
                      </div>
                      {item.album_link && (
                        <div>
                          <b>{translateObj("MESSAGE.LINKSITE")}:</b> {item.album_link}
                        </div>
                      )}
                      {item.description && (
                        <div>
                          <b>{translateObj("MESSAGE.DESCRIPTION")}:</b> {item.description}
                        </div>
                      )}
                      {item.measurements && (
                        <div>
                          <b>{translateObj("MESSAGE.DESCRIPTIONDETAILS")}:</b> {item.measurements}
                        </div>
                      )}
                      {/* <ImagesCeler translateObj={translateObj} photos={item.photos} status={order.status} setMovimentItem={setMovimentItem} /> */}
                      <ImagesCeler translateObj={translateObj} itemAmbience={item} setModified={setMovimentItem} />
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className='justify-content-between'>
        <div>
          {order?.delay?.desciption ? (
            <>
              {!removeFlag && (
                <a href="#" className='text-primary fs-5' onClick={() => screenDelay('removeDelay')}>
                  <i className='fa fa-times me-2 fs-5 text-primary'></i>
                  {translateObj("MESSAGE.REMOVEDELAY")}
                </a>
              )}
            </>
          ) : (
            <>
              {!flag && (
                <a href="#" className='text-danger' onClick={() => screenDelay('add')}>
                  <i className='fa fa-comment me-2 text-danger'></i>
                  {translateObj("MESSAGE.NOTIFYDELAY")}
                </a>
              )}
            </>
          )}
        </div>

        <button type='button' className='btn btn-default' onClick={handleClose}>
          {translateObj("BTN.CLOSE")}
        </button>
      </Modal.Footer>
    </Modal>
  )
}