/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DetailCompany from './permission/Company'
import DetailTeam from './permission/Team'
import { RootState } from '../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

export const infoTitle = {
  fontWeight: 800,
  fontFamily: "Arial",
  background: "#e4e4e4",
  lineHeight: "30px",
  paddingLeft: "8px",
  marginTop: "30px",
  marginBottom: "8px",
  borderBottom: "1px solid"
};

const DetailsProject: FC = () => {
  const intl = useIntl();
  const translateObj = (obj: any, varInfo?: any) => {
    if (varInfo) {
      return intl.formatMessage({ id: obj }, varInfo)
    }

    return intl.formatMessage({ id: obj })
  }
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const { id } = useParams<any>();

  return (
    <>
      {user && (user?.type ? <DetailTeam translateObj={translateObj} id={id} /> : <DetailCompany translateObj={translateObj} id={id} />)}
    </>
  )
}

export { DetailsProject }
