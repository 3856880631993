import { useState } from 'react'
import { IValues } from '../../../index'
import { maskCep, searchCategory, searchDecoration, searchProduct, searchType } from '../../../../../../utils'

export default function Step3(props: { translateObj: any, order: any, values: IValues, loading: any, user: any }) {
  const { translateObj, values } = props;

  return (
    <>
      <div className='w-100'>
        <h2 className='fw-bolder text-dark-era'>{translateObj("MESSAGE.CONFIRMATION")}</h2>

        <div className='text-gray-400 fw-bold fs-6 mb-10'>
          {translateObj("MESSAGE.CONFIRMATION.DESCRIPTION")}
        </div>

        {/* details */}
        <div className='row align-items-start fs-5'>
          <div className='col-12'>
            <h4 className='mb-3'>{translateObj("MESSAGE.IMMOBILE")}</h4>

            <div className='alert alert-secondary p-5 px-10'>
              <div>
                <b>{translateObj("MESSAGE.IDENTIFICATION")}:</b>
                <span className='px-3'>{values.name}</span>
              </div>
              <div>
                <b>{translateObj("MESSAGE.CATEGORY")}:</b>
                <span className='px-3'>{searchCategory(values.category)}</span>
              </div>
              <div>
                <b>{translateObj("MESSAGE.VALUERENTSALE")}:</b>
                <span className='px-3'>{`€ ` + values.price}</span>
              </div>
              <div>
                <b>{translateObj("MESSAGE.TYPE")}:</b>
                <span className='px-3'>{searchType(values.type)}</span>
              </div>
              <div>
                <b>{translateObj("MESSAGE.PROPERTYSTANDARD")}:</b>
                <span className='px-3'>{searchProduct(values.product)}</span>
              </div>
              <div>
                <b>Código de postal:</b>
                <span className='px-3'>{maskCep(values.zip_code)}</span>
              </div>
              <div>
                <b>{translateObj("MESSAGE.DECORATIONSTYLE")}:</b>
                <span className='px-3'>{searchDecoration(values.decoration)}</span>
              </div>
            </div>
          </div>
          <div className='col-12 mt-10'>
            <h4 className='mb-3'>Detalhes da Intervenção</h4>

            <div className='alert alert-secondary p-5 px-10'>
              {values.photos && values.photos.map((item, key) => (
                <div key={key}>
                  {key !== 0 && <hr />}
                  <div className='fw-bold text-dark-era text-uppercase mb-2'>{key + 1}° AMBIENTE PARA HOMESTAGING</div>
                  <div>
                    <b>Divisão:</b>
                    <span className='px-3'>{item.type}</span>
                  </div>
                  <div>
                    <b>Complemento:</b>
                    <span className='px-3'>{item.description ? item.description : translateObj("MESSAGE.NOTDATA")}</span>
                  </div>
                  <div>
                    <b>{translateObj("MESSAGE.DESCRIPTIONDETAILS")}:</b>
                    <span className='px-3'>{item.description_measures ? item.description_measures : translateObj("MESSAGE.NOTDATA")}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <hr />

        <div className='card box-shadow-none'>
          <div className="card-body m-0 p-0 pt-5">
            <h1 className="card-title text-success d-flex align-items-center">
              <i className='fa fa-check-circle text-success fa-2x'></i>
              <span className='mx-3'>Pedido submetido para pagamento!</span>
            </h1>
            <div className="card-text my-5 fs-5">
              <div>
                O seu pedido foi submetido para pagamento.
                <br /><br />
                Logo que o mesmo seja efetuado, será enviado para a equipa da CELER, que garante a entrega das imagens em apenas 4 dias úteis.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}