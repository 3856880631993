import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { deleteCouponId, getCoupons } from '../../services/Api'
import { convertDate } from "../../utils";
import { ModalCoupon } from "./components/ModalCoupon";

const ReportCouponsPermission = (props: { translateObj: any }) => {
  let { translateObj } = props;

  const [coupons, setCoupons] = useState<any[]>([])
  const [modalShow, setModalShow] = useState(false)
  const [modified, setModified] = useState(1)
  const [couponEdit, setCouponEdit] = useState<any>({});

  const limit = 10
  const [pagination, setPagination] = useState(1)
  const [totalPages, setTotalPages] = useState<any[]>([])
  const [listCoupons, setListCoupons] = useState<any[]>([])

  const verifyPagination = (page: any) => {
    let resultPage = (page - 1) * limit
    let newOffSet = { from: resultPage, to: resultPage + limit }

    coupons && setListCoupons(coupons.slice(newOffSet.from, newOffSet.to))
    setPagination(page)
  }

  const verifyTotalPages = (array: []) => {
    const total = []
    const length = array?.length / limit

    for (let i = 0; i < length; i++) {
      total.push(i + 1)
    }

    setTotalPages(total)
  }

  const verifyListCoupons = (array: []) => {
    setCoupons(array)
    array && setListCoupons(array.slice(0, 10))

    verifyTotalPages(array)
  }

  const deleteCoupon = (id: any) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(translateObj("MESSAGE.WANTREMOVECOUPON"))) {
      deleteCouponId(id).then(() => {
        setModified(Math.random())
      });
    }
  }

  const openModalEdit = (coupon: any) => {
    setCouponEdit(coupon)
    setModalShow(true)
  }

  useEffect(() => {
    getCoupons().then((data) => {
      console.log(data)
      verifyListCoupons(data)
    })
  }, [modified])

  return (
    <>
      <h1 className='mb-10'>{translateObj("MESSAGE.CONSULTCOUPONS")}</h1>
      <div className='card'>
        <div className='card-body'>

          <div className="d-flex justify-content-between align-items-center mb-5">
            <h3>{coupons?.length} {translateObj("MESSAGE.RESULTS")}</h3>

            <button className="btn btn-dark text-white" onClick={() => {
              setModalShow(true)
              setCouponEdit({})
            }}>
              {translateObj("MESSAGE.ADDCOUPON")}
            </button>
          </div>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead className='table-light border-bottom text-uppercase fw-bolder bg-secondary text-center'>
                <tr>
                  <td>{translateObj("MESSAGE.INFLUENCER")}</td>
                  <td>{translateObj("MESSAGE.CAMPAIGN")}</td>
                  <td>{translateObj("MESSAGE.DISCOUNT")}</td>
                  <td>{translateObj("MESSAGE.DATE.VALIDATE")}</td>
                  <td>{translateObj("MESSAGE.COUNT")}</td>
                  <td>{translateObj("MESSAGE.ACTIONS")}</td>
                </tr>
              </thead>
              <tbody className='text-center'>
                {listCoupons?.length ? (listCoupons?.map(item => {

                  if (item.is_active) {

                    return (
                      <tr key={item.id} className='cursor-pointer border-bottom'>
                        <td>{item?.influencer_user ? item.influencer_user.name : item.influencer}</td>
                        <td>{item.name}</td>
                        <td>{item.discount_amount}</td>
                        <td>{convertDate(item.valid_until)}</td>
                        <td>{item?.quantity ? item?.quantity : '---'}</td>
                        <td>
                          <div className="btn-group" role="group" aria-label="Ações">
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => openModalEdit(item)}>
                              <i className="fa fa-edit" />
                            </button>
                            <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteCoupon(item.id)}>
                              <i className="fa fa-times" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    )

                  }

                })) : (
                  <tr>
                    <td colSpan={5}>{translateObj("MESSAGE.NOTRESULT")}</td>
                  </tr>
                )}
              </tbody>
            </table>

            {totalPages.length > 0 && (
              <div className="float-end my-5 text-center">
                {translateObj("MESSAGE.PAGINATION")}
                <div className="d-flex pt-3">
                  {totalPages.map(item => {
                    return (
                      <button key={item} type="button" className={`btn btn-sm text-primary p-0 m-0 px-1 border mx-1 ${pagination === item && 'border-primary border-2'}`} onClick={() => verifyPagination(item)}>{item}</button>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalCoupon translateObj={translateObj} show={modalShow} setShow={setModalShow} setModified={setModified} coupon={couponEdit} />
    </>
  )
}

export default function ReportCoupons() {
  const intl = useIntl();
  const translateObj = (obj: any, varInfo?: any) => {
    if (varInfo) {
      return intl.formatMessage({ id: obj }, varInfo)
    }

    return intl.formatMessage({ id: obj })
  }
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any

  return (
    <>
      {user && (user?.type ? <ReportCouponsPermission translateObj={translateObj} /> : translateObj("VALID.PERMISSION"))}
    </>
  )
}