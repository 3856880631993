import { useEffect, useState } from "react";
import { IFilter, initialFilter, numberOfLines } from "../ReportProject";
import { getOrder } from '../../../services/Api'
import { maskCep, searchCategory, searchDecoration, searchStatus, searchStatusColor, searchType, typeProductName } from "../../../utils";
import moment from "moment";

export default function ReportCompany(props: { translateObj: any }) {
  let { translateObj } = props;

  const [params, setParams] = useState<any>(initialFilter);
  const [filter, setFilter] = useState<IFilter>(initialFilter)
  const [orders, setOrders] = useState<any[]>([])

  const limit = 10
  const [pagination, setPagination] = useState(1)
  const [totalPages, setTotalPages] = useState<any[]>([])
  const [listOrders, setListOrders] = useState<any[]>([])

  const changeParam = (element: any) => {
    const value = element.target.value
    const oldParams = params
    oldParams[`${element.target.name}`] = value
    setParams({ ...oldParams })
    verifyEnter(element)
  }

  const verifyEnter = (e: any) => {
    if (e.keyCode === 13) sendFilter()
  }

  const sendFilter = () => {
    setFilter({ ...params })
  }

  const verifyPagination = (page: any) => {
    let resultPage = (page - 1) * limit
    let newOffSet = { from: resultPage, to: resultPage + limit }

    setListOrders(orders.slice(newOffSet.from, newOffSet.to))
    setPagination(page)
  }

  const verifyTotalPages = (array: []) => {
    const total = []
    const length = array.length / limit

    for (let i = 0; i < length; i++) {
      total.push(i + 1)
    }

    setTotalPages(total)
  }

  const verifyListOrders = (array: []) => {
    setOrders(array)
    setListOrders(array.slice(0, 10))

    verifyTotalPages(array)
  }

  useEffect(() => {
    getOrder(filter).then((data) => {
      data && verifyListOrders(data)
    })
  }, [filter])

  return (
    <>
      <h1 className='mb-10'>{translateObj("MESSAGE.CONSULTPROJECTS")}</h1>
      <div className='card'>
        <div className='card-body'>
          <h3 className='mb-5'>{translateObj("MESSAGE.FILTER")}:</h3>
          <div className='mb-10'>
            <div className='row align-items-end'>
              <div className='col-3'>
                <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.IDENTIFICATION")}</label>
                <input type='text' name='tag' className='form-control form-control-sm form-control-solid' onKeyUp={(e: any) => changeParam(e)} />
              </div>
              <div className='col-3'>
                <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.CATEGORY")}</label>
                <select name='category' className='form-select form-select-sm form-select-solid' onChange={(e: any) => changeParam(e)}>
                  <option value=''>{translateObj("MESSAGE.ALL")}</option>
                  <option value='Rent'>{translateObj("MESSAGE.RENT")}</option>
                  <option value='Sell'>{translateObj("MESSAGE.SALE")}</option>
                </select>
              </div>
              <div className='col-3'>
                <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.ZIPCODE")}</label>
                <input type='text' name='zip_code' className='form-control form-control-sm form-control-solid' onKeyUp={(e: any) => changeParam(e)} />
              </div>
              <div className='col-3'>
                <button className='w-100 btn btn-dark text-white' onClick={() => sendFilter()}>
                  <i className='fa fa-search'></i>
                  <span>{translateObj("BTN.SEARCH")}</span>
                </button>
              </div>
            </div>
          </div>

          <h3 className='mb-5'>{orders?.length + ' ' + translateObj("MESSAGE.RESULTS")}:</h3>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead className='table-light border-bottom text-uppercase fw-bolder bg-secondary text-center'>
                <tr>
                  <td>{translateObj("MESSAGE.IDENTIFICATION")}</td>
                  <td>{translateObj("MESSAGE.ZIPCODE")}</td>
                  <td>{translateObj("MESSAGE.TYPE")}</td>
                  <td>{translateObj("MESSAGE.CATEGORY")}</td>
                  <td>{translateObj("MESSAGE.DECORATED")}</td>
                  <td>{translateObj("MESSAGE.STATUS")}</td>
                  <td>{translateObj("MESSAGE.PRODUCT")}</td>
                  <td>{translateObj("MESSAGE.CREATED_AT")}</td>
                  <td className='text-center'>#</td>
                </tr>
              </thead>
              <tbody className='text-center'>
                {listOrders?.length ? (listOrders?.map(item => {
                  return (
                    <tr key={item.id} className='cursor-pointer border-bottom' onClick={() => window.open(`/detailsProject/${item.id}`, 'blank')}>
                      <td style={numberOfLines} title={item.name}>{item.name}</td>
                      <td style={numberOfLines} title={item.property.zip_code}>{maskCep(item.property.zip_code)}</td>
                      <td>{searchType(item.property.type)}</td>
                      <td>{searchCategory(item.property.category)}</td>
                      <td>{searchDecoration(item.decoration)}</td>
                      <td>
                        <span className={`badge ${searchStatusColor(item.status)}`}>
                          {searchStatus(item.status)}
                        </span>
                      </td>
                      <td>{typeProductName(item)}</td>
                      <td>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</td>
                      <td className='text-center'>
                        <span className='p-3'>
                          <i className='fa fa-search'></i>
                        </span>
                      </td>
                    </tr>
                  )
                })) : (
                  <tr>
                    <td colSpan={9}>{translateObj("MESSAGE.NOTRESULT")}</td>
                  </tr>
                )}
              </tbody>
            </table>

            {totalPages.length > 0 && (
              <div className="float-end my-5 text-center w-100">
                {translateObj("MESSAGE.PAGINATION")}
                <div className="d-flex pt-3">
                  {totalPages.map(item => {
                    return (
                      <button key={item} type="button" className={`btn btn-sm text-primary p-0 m-0 px-1 border mx-1 ${pagination === item && 'border-primary border-2'}`} onClick={() => verifyPagination(item)}>{item}</button>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}