import moment from 'moment'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useDrag} from 'react-dnd'
import {useIntl} from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'
import {IBoards} from '..'
import {RootState} from '../../../../setup'
import {getAdminOrderId, patchAdminOrder} from '../../../services/Api'
import {diffDays, searchStatusNumberToString, verifyIconTimer, verifyStatus} from '../../../utils'

const Item = (props: {
  order: any
  itemBoard: any
  boards: IBoards[]
  setBoards: Dispatch<SetStateAction<IBoards[]>>
  setShow: Dispatch<SetStateAction<boolean>>
  setOrderItemSelected: Dispatch<SetStateAction<any>>
  setMovimentItem: Dispatch<SetStateAction<any>>
}) => {
  const intl = useIntl()
  const translateObj = (obj: any) => {
    return intl.formatMessage({id: obj})
  }
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any
  const {order, itemBoard, boards, setBoards, setShow, setOrderItemSelected, setMovimentItem} =
    props

  const openModalDetail = (orderDetail: any) => {
    let orderFull = orderDetail.order

    getAdminOrderId(orderDetail.order.id).then((data) => {
      orderFull.delay = data.delay
      orderFull.enviroments = data.order.enviroments
      setOrderItemSelected(orderFull)
      setShow(true)
    })
  }

  const changeItemBoard = async (itemOrder: any, idOldBoard: any, idNewBoard: any) => {
    if (idOldBoard !== idNewBoard) {
      let newBoard = boards
      let keyOld = newBoard.findIndex((item, key) => {
        if (item.id === idOldBoard) {
          let infoKey: number = key === 0 ? key + 1 : key
          return infoKey
        }
      })
      newBoard[keyOld].array = newBoard[keyOld].array.filter(
        (item) => item.id !== itemOrder.order.id
      )

      let keyNew = newBoard.findIndex((item, key) => {
        if (item.id === idNewBoard) {
          let infoKey: number = key === 0 ? key + 1 : key
          return infoKey
        }
      })
      newBoard[keyNew].array.push(itemOrder.order)
      setBoards([...newBoard])

      await patchAdminOrder({
        id: itemOrder.order.order.id,
        standard: itemOrder.order.order.standard,
        status: searchStatusNumberToString(idNewBoard),
      }).then((data) => {
        setMovimentItem(data)
      })
    }
  }

  let validItem = 'item'
  if (user.type === 'Operator') {
    if (order.status !== 'In_Progress' && order.status !== 'In_Review') {
      validItem = 'notWorking'
    }
  }

  const [{isDragging}, drag] = useDrag(() => ({
    type: validItem,
    item: {order},
    end: async (item, monitor) => {
      const dropResult: any = monitor.getDropResult()

      if (item && dropResult) {
        await changeItemBoard(item, itemBoard.id, dropResult.id)
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))

  const opacity = isDragging ? 0.4 : 1
  const iconTimer = verifyIconTimer(order?.status)

  return (
    <div
      ref={drag}
      className={`my-3 bg-white ps-2 ${
        order?.order?.company_id === '2351b304-f7f0-4ed5-bc51-8dbd9255b1ac' &&
        'bg-secondary px-3 text-primary'
      }`}
      style={{opacity, borderLeft: `4px solid ${itemBoard.color}`}}
      data-testid={`box`}
    >
      {order?.order.company && (
        <>
          <div>
            <h5 className='w-100' style={{color: itemBoard.color, position: 'relative'}}>
              # {order?.order.number}
              <span
                className='position-absolute p-1 cursor-pointer'
                style={{top: 0, right: 0}}
                onClick={() => openModalDetail(order)}
              >
                <i className='fa fa-search text-primary'></i>
              </span>
            </h5>
          </div>
          <div>
            <span className='badge badge-primary p-1 my-1'>
              {order?.order.is_celer_express ? 'CELER' : 'PREMIUM'}
            </span>
          </div>
          <div>
            <i className='fa fa-briefcase'></i>
            <span className='mx-1'>{order?.order.company.name}</span>
          </div>
          <div>
            <i className='fa fa-certificate'></i>
            <span className='mx-1'>{order?.order.property.name}</span>
          </div>
          <div className='fs-7'>
            {order?.order.finish_in && (
              <div className='border-top'>
                <b>
                  <i className='fa fa-clock me-1'></i>
                  {translateObj('MESSAGE.DATEFORECAST')}
                </b>
                <br />
                {moment(order?.order.finish_in).format('DD/MM/YYYY HH:mm')}
              </div>
            )}

            {order?.order.started_at && (
              <div className='border-top'>
                <b>
                  <i className='fa fa-clock me-1'></i>
                  {translateObj('MESSAGE.DATESTART')}
                </b>
                <br />
                {moment(order?.order.started_at).format('DD/MM/YYYY HH:mm')}
              </div>
            )}

            {order?.order.finished_at && (
              <div className='border-top'>
                <b>
                  <i className='fa fa-clock me-1'></i>
                  {translateObj('MESSAGE.DATECONCLUSION')}
                </b>
                <br />
                {moment(order?.order.finished_at).format('DD/MM/YYYY HH:mm')}
              </div>
            )}
          </div>
          <div className='row p-2 pt-3'>
            <div className='col-4 ps-1'>
              {order?.order.delay && (
                <i className='fa fa-exclamation-triangle pe-1 text-danger'></i>
              )}
              <i
                className='fa fa-flag'
                title={order?.order.team?.name}
                style={{cursor: 'help', color: order?.order.team?.color}}
              ></i>
            </div>
            <div className='col-8 text-end'>
              <span
                className={`w-100 fs-9 ${verifyStatus(
                  diffDays(order?.order?.orders_moviments[0].created_at, order?.order?.status, 0)
                )}`}
              >
                <i
                  className={`fa fa-${iconTimer} pe-1 ${verifyStatus(
                    diffDays(order?.order?.orders_moviments[0].created_at, order?.order?.status, 0)
                  )}`}
                ></i>
                {diffDays(
                  order?.order?.orders_moviments[0].created_at,
                  order?.order?.status,
                  order?.review?.created_at
                )}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export {Item}
