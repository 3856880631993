import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { getAdminCompanies, getExecutive } from '../../services/Api'
import { maskCep, maskCpOrCnpj } from "../../utils";

const ReportCompaniesPermission = (props: { translateObj: any }) => {
  let { translateObj } = props;

  const [params, setParams] = useState<any>([]);
  const [filter, setFilter] = useState<any[]>([])
  const [executive, setExecutive] = useState<any[]>([])

  const [companies, setCompanies] = useState<any[]>([])
  const limit = 10
  const [pagination, setPagination] = useState(1)
  const [totalPages, setTotalPages] = useState<any[]>([])
  const [listCompanies, setListCompanies] = useState<any[]>([])

  const changeParam = (element: any) => {
    let value = element.target.value
    const oldParams = params

    if (element.target.name === "document") {
      value = maskCpOrCnpj(value)
      element.target.value = value
    }

    oldParams[`${element.target.name}`] = value
    setParams({ ...oldParams })
    verifyEnter(element)
  }

  const verifyEnter = (e: any) => {
    if (e.keyCode === 13) sendFilter()
  }

  const sendFilter = () => {
    setFilter({ ...params })
  }

  const verifyPagination = (page: any) => {
    let resultPage = (page - 1) * limit
    let newOffSet = { from: resultPage, to: resultPage + limit }

    companies && setListCompanies(companies.slice(newOffSet.from, newOffSet.to))
    setPagination(page)
  }

  const verifyTotalPages = (array: []) => {
    const total = []
    const length = array?.length / limit

    for (let i = 0; i < length; i++) {
      total.push(i + 1)
    }

    setTotalPages(total)
  }

  const verifyListCompanies = (array: []) => {
    setCompanies(array)
    array && setListCompanies(array.slice(0, 10))

    verifyTotalPages(array)
  }

  useEffect(() => {
    getAdminCompanies(filter).then((data) => {
      verifyListCompanies(data)
    })

    getExecutive().then((data: any) => {
      data && setExecutive(data)
    })
  }, [filter])

  useEffect(() => {
    setTimeout(() => {
      setFilter({ ...filter })
    }, 100)
  }, [])

  return (
    <>
      <h1 className='mb-10'>{translateObj("MESSAGE.CONSULTCLIENTS")}</h1>
      <div className='card'>
        <div className='card-body'>
          <h3 className='mb-5'>{translateObj("MESSAGE.FILTER")}:</h3>
          <div className='mb-10'>
            <div className='row align-items-end'>
              <div className='col-6'>
                <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.COMPANY")}</label>
                <input type='text' name='name' className='form-control form-control-sm form-control-solid' onKeyUp={(e: any) => changeParam(e)} />
              </div>
              <div className='col-3'>
                <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.DOCUMENT")}</label>
                <input type='text' name='document' className='form-control form-control-sm form-control-solid' onKeyUp={(e: any) => changeParam(e)} onBlur={(e: any) => changeParam(e)} />
              </div>
              <div className="col-3">
                <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.EXECUTIVE")}</label>
                <select name='executive' className='form-select form-select-sm form-select-solid' onChange={(e: any) => changeParam(e)}>
                  <option value=''>{translateObj("MESSAGE.ALL")}</option>
                  {executive.map((item, key) => {
                    return (<option key={key} value={item.id}>{item.name}</option>)
                  })}
                </select>
              </div>
            </div>
            <div className="mt-5">
              <button type="submit" className='w-100 btn btn-dark text-white' onClick={sendFilter}>
                <i className='fa fa-search'></i>
                <span>{translateObj("BTN.SEARCH")}</span>
              </button>
            </div>
          </div>

          <h3 className='mb-5'>{companies?.length} {translateObj("MESSAGE.RESULTS")}</h3>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead className='table-light border-bottom text-uppercase fw-bolder bg-secondary text-center'>
                <tr>
                  <td>{translateObj("MESSAGE.NAME")}</td>
                  <td>{translateObj("MESSAGE.QUANTITYBALANCE")}</td>
                  <td>{translateObj("MESSAGE.LOCATION")}</td>
                  <td>{translateObj("MESSAGE.CREATED_AT")}</td>
                  <td className='text-center'>#</td>
                </tr>
              </thead>
              <tbody className='text-center'>
                {listCompanies?.length ? (listCompanies?.map(item => {
                  let created_at = moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')

                  return (
                    <tr key={item.id} className='cursor-pointer border-bottom' onClick={() => window.location.href = `/company/${item.id}`}>
                      <td>{item.name}</td>
                      <td>
                        {translateObj("MESSAGE.PRODUCT.CELER") + ': ' + item.images}
                        <br />
                        {translateObj("MESSAGE.PRODUCT.EXPRESS") + ': ' + item.images_express}
                      </td>
                      <td>{`${maskCep(item.zip_code)} - ${item.address}, ${item.city}/${item.state}`}</td>
                      <td>{created_at}</td>
                      <td className='text-center'>
                        <span className='p-3'>
                          <i className='fa fa-search'></i>
                        </span>
                      </td>
                    </tr>
                  )
                })) : (
                  <tr>
                    <td colSpan={4}>{translateObj("MESSAGE.NOTRESULT")}</td>
                  </tr>
                )}
              </tbody>
            </table>

            {totalPages.length > 0 && (
              <div className="float-end my-5 text-center w-100">
                {translateObj("MESSAGE.PAGINATION")}
                <div className="d-flex pt-3">
                  {totalPages.map(item => {
                    return (
                      <button key={item} type="button" className={`btn btn-sm text-primary p-0 m-0 px-1 border mx-1 ${pagination === item && 'border-primary border-2'}`} onClick={() => verifyPagination(item)}>{item}</button>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default function ReportCompanies() {
  const intl = useIntl();
  const translateObj = (obj: any) => {
    return intl.formatMessage({ id: obj })
  }
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any

  return (
    <>
      {user && (user?.type ? <ReportCompaniesPermission translateObj={translateObj} /> : translateObj("VALID.PERMISSION"))}
    </>
  )
}