import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useState } from 'react';
import { maskMoney } from "../../../utils";
import { postAdminManualRelease } from "../../../services/Api";

const ScreenTypeSelect = (props: { translateObj: any, typeSelect: any, selectPurchaseType: any, nextScreen: any, handleClose: any }) => {
  const { translateObj, typeSelect, selectPurchaseType, nextScreen, handleClose } = props;

  return (
    <div>
      <h2 className='fw-bolder text-dark mb-10'>{translateObj("MESSAGE.ADDVALUES")}</h2>

      <div className="d-flex gap-10 justify-content-center content-card-buy-item text-center">
        <div className={`card-buy-item min-width-100 ${typeSelect?.balance && 'active'}`} onClick={() => selectPurchaseType('balance')}>
          {translateObj("MESSAGE.ADDCREDITANDIMAGE")}
        </div>
        <div className={`card-buy-item min-width-100 ${typeSelect?.order && 'active'}`} onClick={() => selectPurchaseType('order')}>
          {translateObj("MESSAGE.ADDNEWORDER")}
        </div>
      </div>

      <div className="mt-10">
        <button type='button' className='btn btn-dark w-100' onClick={() => nextScreen()}>{translateObj("BTN.PROCCED")}</button>
        <button type='button' className='btn btn-default w-100' onClick={() => handleClose()}>{translateObj("BTN.CLOSE")}</button>
      </div>
    </div>
  )
}

const ScreenBalance = (props: { translateObj: any, nextScreen: any, companyId: any, setModified: Dispatch<SetStateAction<any>>, handleClose: any }) => {
  const { translateObj, nextScreen, companyId, setModified, handleClose } = props;

  const initialValues = {
    "company_id": companyId,
    "type": "",
    "amount": "",
    "is_paid": true
  }

  const currentSchema = Yup.object().shape({
    "type": Yup.string().required(translateObj("VALID.REQUIRED")),
    "amount": Yup.number().min(1, translateObj("VALID.MIN.CARACTER", { "length": 1 })).max(999, translateObj("VALID.MAX.CARACTER", { "length": 999 })).required(translateObj("VALID.REQUIRED")),
  })

  const submit = (values: any) => {
    let data = values;
    data.is_celer_express = values.type === 'express'

    postAdminManualRelease(data).then(() => {
      setModified(Math.random());
      handleClose();
    });
  }

  return (
    <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submit}>
      {({ setFieldValue }) => (
        <Form id='kt_order'>
          <div>
            <h2 className='fw-bolder text-dark mb-10'>{translateObj("MESSAGE.ADDCREDITANDIMAGE")}</h2>

            <div className='fv-row mb-5 mt-5'>
              <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.TYPE")}</label>

              <Field as="select" name='type' className='form-select form-select-lg form-select-solid'>
                <option value=''>{translateObj("MESSAGE.SELECT")}</option>
                <option value='image'>Imagem Premium</option>
                <option value='express'>Imagem Express</option>
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='type' />
              </div>
            </div>
            <div className='fv-row mb-5 mt-5'>
              <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.QUANTITY")}</label>

              <Field type="number" name='amount' className='form-control form-control-lg form-control-solid' />
              <div className='text-danger mt-2'>
                <ErrorMessage name='amount' />
              </div>
            </div>

            <div className="mt-10">
              <button type='submit' className='btn btn-dark w-100'>{translateObj("BTN.SAVE")}</button>
              <button type='button' className='btn btn-default w-100' onClick={() => nextScreen('select')}>{translateObj("BTN.BACK")}</button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const ScreenNewOrder = (props: { translateObj: any, nextScreen: any, companyId: any, setModified: Dispatch<SetStateAction<any>>, handleClose: any }) => {
  const { translateObj, nextScreen, companyId, setModified, handleClose } = props;

  const initialValues = {
    "company_id": companyId,
    "type": "",
    "amount": "",
    "value": "",
    "is_paid": false
  }

  const currentSchema = Yup.object().shape({
    "type": Yup.string().required(translateObj("VALID.REQUIRED")),
    "amount": Yup.number().min(1, translateObj("VALID.MIN.CARACTER", { "length": 1 })).max(999, translateObj("VALID.MAX.CARACTER", { "length": 999 })).required(translateObj("VALID.REQUIRED")),
    "value": Yup.string().required(translateObj("VALID.REQUIRED")),
  })

  const submit = (values: any) => {
    let data = values;
    data.is_celer_express = values.type === 'express'
    data.value = isNaN(data.value) ? data.value.replaceAll(".", "").replace(",", ".") : data.value;

    postAdminManualRelease(data).then(() => {
      setModified(Math.random());
      handleClose();
    });
  }

  return (
    <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submit}>
      {({ setFieldValue }) => (
        <Form id='kt_order'>
          <div>
            <h2 className='fw-bolder text-dark mb-10'>{translateObj("MESSAGE.ADDNEWORDER")}</h2>

            <div className='fv-row mb-5 mt-5'>
              <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.TYPE")}</label>

              <Field as="select" name='type' className='form-select form-select-lg form-select-solid'>
                <option value=''>{translateObj("MESSAGE.SELECT")}</option>
                <option value='image'>{translateObj("MESSAGE.IMAGE").toLocaleUpperCase()}</option>
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='type' />
              </div>
            </div>
            <div className='fv-row mb-5 mt-5'>
              <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.QUANTITY")}</label>

              <Field type="number" name='amount' className='form-control form-control-lg form-control-solid' />
              <div className='text-danger mt-2'>
                <ErrorMessage name='amount' />
              </div>
            </div>
            <div className='fv-row mb-5 mt-5'>
              <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.VALUE")}</label>

              <Field name='value' className='form-control form-control-lg form-control-solid' maxLength={13} onKeyUp={(e: any) => setFieldValue('value', maskMoney(e.target.value))} />
              <div className='text-danger mt-2'>
                <ErrorMessage name='value' />
              </div>
            </div>

            <div className="mt-10">
              <button type='submit' className='btn btn-dark w-100'>{translateObj("BTN.SAVE")}</button>
              <button type='button' className='btn btn-default w-100' onClick={() => nextScreen('select')}>{translateObj("BTN.BACK")}</button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export function ModalBalance(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, setModified: Dispatch<SetStateAction<number>>, companyId: any }) {
  const { translateObj, show, setShow, setModified, companyId } = props;
  const [typeSelect, setTypeSelect] = useState<any>({ balance: true, order: false });
  const [typeScreen, setTypeScreen] = useState('select');

  const handleClose = () => {
    setTypeScreen('select');
    setShow(false);
  }

  const selectPurchaseType = (type: any) => {
    let typeSelected: any = { balance: false, order: false }
    typeSelected[type] = true

    setTypeSelect(typeSelected)
  }

  const nextScreen = (screen: any) => {
    screen ? setTypeScreen(screen) : setTypeScreen(typeSelect.balance ? 'balance' : 'order');
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        {typeScreen === 'select' && <ScreenTypeSelect translateObj={translateObj} typeSelect={typeSelect} selectPurchaseType={selectPurchaseType} nextScreen={nextScreen} handleClose={handleClose} />}
        {typeScreen === 'balance' && <ScreenBalance translateObj={translateObj} nextScreen={nextScreen} companyId={companyId} setModified={setModified} handleClose={handleClose} />}
        {typeScreen === 'order' && <ScreenNewOrder translateObj={translateObj} nextScreen={nextScreen} companyId={companyId} setModified={setModified} handleClose={handleClose} />}
      </Modal.Body>
    </Modal>
  )
}