import { Dispatch, SetStateAction } from 'react';
import { useDrop } from 'react-dnd'
import { shallowEqual, useSelector } from 'react-redux';
import { IBoards } from '..';
import { RootState } from '../../../../setup';
import { Item } from "./Item";

const Board = (props: { itemBoard: any, items: any[], boards: IBoards[], setBoards: Dispatch<SetStateAction<any[]>>, setShow: Dispatch<SetStateAction<boolean>>, setOrderItemSelected: Dispatch<SetStateAction<any>>, setMovimentItem: Dispatch<SetStateAction<any>> }) => {
  const { itemBoard, items, boards, setBoards, setShow, setOrderItemSelected, setMovimentItem } = props
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any

  let validItem = 'item'
  if (user.type === "Operator") {
    if (itemBoard.id === 3 || itemBoard.id === 5) {
      validItem = 'item'
    }else{
      validItem = 'NotWorking'
    }
  }

  const [{ canDrop }, drop] = useDrop(() => ({
    accept: validItem,
    drop: () => ({ id: itemBoard.id, title: itemBoard.title, status: itemBoard.status }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  let backgroundColor = ''
  if (canDrop) {
    backgroundColor = '#fefefe'
  }

  return (
    <div key={itemBoard.id} className="w-100">
      <h5 className="text-center p-3" style={{ background: itemBoard.color, minHeight: "60px" }}>{itemBoard.title}</h5>
      <div ref={drop} style={{ backgroundColor, minHeight: "100%" }} data-testid={`board-${itemBoard.id}`}>
        {items.length ? items.map(item => {
          return (
            <Item key={item.order.id ? item.order.id : Math.random()} order={item} itemBoard={itemBoard} boards={boards} setBoards={setBoards} setShow={setShow} setOrderItemSelected={setOrderItemSelected} setMovimentItem={setMovimentItem} />
          )
        }) : (<></>)}
      </div>
    </div>
  )
}

export { Board }