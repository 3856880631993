import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useState } from 'react';
import { postAdminOrderComparative } from '../../../services/Api';

export function ModalOrderFinished(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, setModified: Dispatch<SetStateAction<number>>, orderId: any }) {
  const { translateObj, show, setShow, setModified, orderId } = props;
  
  const currentSchema = [Yup.object().shape({
    "sendFileImage": Yup.string().required(translateObj("VALID.REQUIRED")),
  }), Yup.object().shape({
    "sendFileVideo": Yup.string().required(translateObj("VALID.REQUIRED")),
  })]

  const initialValues = {
    fileImage: undefined,
    sendFileImage: '',
    fileVideo: undefined,
    sendFileVideo: ''
  }

  const [loading, setLoading] = useState(false)
  const [fileImage, setFileImage] = useState<any>();
  const [fileVideo, setFileVideo] = useState<any>();
  const [screen, setScreen] = useState(1);
  const [validate, setValidate] = useState(currentSchema[0]);

  const handleClose = () => {
    setShow(false);
  }

  const changeFile = (files: any, setFieldValue: any) => {
    if (screen === 1) {
      setFieldValue('sendFileImage', 'sendFile')
      setFileImage(files)
    } else {
      setFieldValue('sendFileVideo', 'sendFile')
      setFileVideo(files)
    }
  }

  const submitForm = () => {
    setLoading(true)

    const form = new FormData();
    form.append('order_id', orderId);
    form.append('type', screen === 1 ? 'image' : 'video');
    form.append('file', screen === 1 ? fileImage[0] : fileVideo[0]);

    postAdminOrderComparative(form).then(data => {
      console.log(data);
      setShow(false);
      setLoading(false);
      setModified(Math.random());
    })
  }

  const validateTab = (current: number) => {
    setValidate(currentSchema[current]);
    setScreen(current + 1);
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik validationSchema={validate} initialValues={initialValues} onSubmit={submitForm}>
        {({ values, setFieldValue }) => (
          <Form id='kt_upload_form'>
            <Modal.Header>
              <Modal.Title className='text-primary'>
                {translateObj("MESSAGE.TITLE.SENDCOMPLETIONDOCUMENT")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className='d-flex gap-3'>
                  <button type='button' className={`btn ${screen === 1 && 'btn-primary'}`} onClick={() => validateTab(0)}>{translateObj("MESSAGE.SENDFILEPDF")}</button>
                  <button type='button' className={`btn ${screen === 2 && 'btn-primary'}`} onClick={() => validateTab(1)}>{translateObj("MESSAGE.SENDFILEVIDEO")}</button>
                </div>

                {screen === 1 && (
                  <div className='fv-row mt-7 mb-7'>
                    <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.SENDFILEPDF")}</label>

                    <Field type='file' accept="image/*,.pdf" name='fileImage' className='form-control form-control-lg form-control-solid' onChange={(e: any) => changeFile(e.target.files, setFieldValue)} />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='sendFileImage' />
                    </div>
                  </div>
                )}

                {screen === 2 && (
                  <div className='fv-row mt-7 mb-7'>
                    <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.SENDFILEVIDEO")}</label>

                    <Field type='file' accept="video/*" name='fileVideo' className='form-control form-control-lg form-control-solid' onChange={(e: any) => changeFile(e.target.files, setFieldValue)} />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='sendFileVideo' />
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <>
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </>
              ) : (
                <>
                  <button type='button' className='btn btn-default' onClick={handleClose}>
                    {translateObj("BTN.CANCEL")}
                  </button>
                  <button type='submit' className='btn btn-dark text-white'>
                    <i className='fa fa-check'></i>
                    <span className='mx-1'>{translateObj("BTN.SAVE")}</span>
                  </button>
                </>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}