import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { ModalImage } from "./ModalImage"

export function Images(props: { translateObj: any, photos: any[], status: any, setMovimentItem: Dispatch<SetStateAction<any>> }) {
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  let { translateObj, photos, status, setMovimentItem } = props

  const [showImage, setShowImage] = useState(false)
  const [infoImage, setInfoImage] = useState<any>({})
  const photosCeler = photos.filter(item => item.is_celer).length > 0;
  const filterPhotos: any = status !== "Finished" ? photos.filter(item => user?.type ? (item.is_celer || item.is_valid) : (!item.is_celer && item.is_valid)) : photos.filter(item => item.is_celer && item.is_valid);

  const isCeler = photos.find(item => {
    return item.is_celer === true
  }) && status === "Finished"

  const openModalAmbience = (obj: any) => {
    setShowImage(!showImage)
    setInfoImage(obj)
  }

  return (
    <div>
      <div>
        <b>{isCeler ? translateObj("MESSAGE.PHOTOREADY") : translateObj("MESSAGE.PHOTOSOFAMBIENCE") + ` (${filterPhotos?.length ? filterPhotos?.length : photos.length})`}:</b>
      </div>
      <div className="w-100 overflow-auto">
        <div className="d-flex gap-5">

          {photos.map((item, key) => {
            if (!item.is_celer && item.is_valid && status !== "Finished") {
              return (
                <div key={key} className='py-2 text-center'>
                  {item.path.split(".pdf").length === 2 ? (
                    <a href={item.path} target='_blank' rel="noreferrer" download>
                      <div className="m-auto position-relative mb-2">
                        {item?.is_chosen && (<i className="fa fa-star text-warning info-star" />)}
                        <img src='/media/icons/pdf.png' className="heightImgMin" alt="Documento do cliente" />
                      </div>
                      <div className="bg-primary mt-0">
                        <i className='fa fa-search text-white'></i>
                        <span className='mx-3 text-white'>{translateObj("MESSAGE.OPEN.DOCUMENT")}</span>
                      </div>
                    </a>
                  ) : (
                    <span className="cursor-pointer" onClick={() => openModalAmbience(item)}>
                      <div className="m-auto position-relative">
                        {item?.is_chosen && (<i className="fa fa-star text-warning info-star" />)}
                        <img src={item.path} className="heightImgMin" alt="Foto do cliente" />
                      </div>
                      <div className="bg-primary mt-0">
                        <i className='fa fa-search text-white'></i>
                        <span className='mx-3 text-white'>{translateObj("MESSAGE.OPEN.PHOTO")}</span>
                      </div>
                    </span>
                  )}
                </div>
              )
            } else if (item.is_celer && item.is_valid && !item.is_example && (!user?.type ? status === "Finished" : true)) {
              return (
                <div key={key} className='py-2 text-center cursor-pointer' onClick={() => openModalAmbience(item)}>
                  <div className="m-auto">
                    <img src={item.path} className="heightImgMin" alt="Foto da Celer" />
                  </div>
                  <div className="bg-success mt-0">
                    <i className='fa fa-check text-white'></i>
                    <span className='mx-3 text-white'>{translateObj("MESSAGE.PHOTOCELER")}</span>
                  </div>
                </div>
              )
            } else if (item.is_example && item.is_valid && !photosCeler) {
              return (
                <div key={key} className='py-2 text-center cursor-pointer' onClick={() => openModalAmbience(item)}>
                  <div className="m-auto">
                    <img src={item.path} className="heightImgMin" alt="Foto exemplo Celer" />
                  </div>
                  <div className="bg-warning mt-0">
                    <i className='fa fa-check text-dark'></i>
                    <span className='mx-3 text-dark'>{translateObj("MESSAGE.EXAMPLECELER")}</span>
                  </div>
                </div>
              )
            }
          })}

          <ModalImage translateObj={translateObj} ambienceId={''} show={showImage} setShow={setShowImage} image={infoImage} setModified={setMovimentItem} photos={photos} filterPhotosAI={filterPhotos} />
        </div>
      </div>
    </div>
  )
}