export default function TypeProject(props: {
  translateObj: any
  typeSelect: any
  selectPurchaseType: any
  confirmTypeSelect: any
  hasPremiumCredits: any
  hasCredits: any
  isTeam: any
}) {
  const {translateObj, typeSelect, selectPurchaseType, confirmTypeSelect, hasPremiumCredits, hasCredits, isTeam} = props;

  return (
    <div>
      <div className='text-center'>
        <h3>{translateObj('MESSAGE.CHOOSETYPE')}</h3>
        <p className='mt-5 mb-10'>
          {translateObj('MESSAGE.CHOOSETYPEPROJECT.DESCRIPTION')}
        </p>

        <div className='d-flex gap-10 justify-content-center content-card-buy-item'>
          {isTeam || hasPremiumCredits ? (
            <div
              className={`card-buy-item ${typeSelect?.celer && 'active'}`}
              onClick={() => selectPurchaseType('celer')}
            >
              Celer Premium
              <hr />
              <span>
                {translateObj('MESSAGE.NEWPROJECT.CELERFULL.LINE1')}
                <br />
                {translateObj('MESSAGE.NEWPROJECT.CELERFULL.LINE2')}
              </span>
            </div>
          ) : (
            <div className={`card-buy-item text-slate-500 cursor-default`}>
              Celer Premium
              <hr />
              <span>
                {translateObj('MESSAGE.WITHOUTCREDIT')}
              </span>
              <br />
              <button
                className='btn mt-2 btn-primary px-20'
                onClick={() => window.location.replace('/credit/buy')}
              >
                {translateObj('MESSAGE.GETCREDITS')}
              </button>
            </div>
          )}

          {isTeam || hasCredits ? (
            <div
              className={`card-buy-item ${typeSelect?.express && 'active'}`}
              onClick={() => selectPurchaseType('express')}
            >
              Celer
              <hr />
              <span>
                {translateObj('MESSAGE.NEWPROJECT.CELERBASIC.LINE1')}
                <br />
                {translateObj('MESSAGE.NEWPROJECT.CELERBASIC.LINE3')}
              </span>
            </div>
          ) : (
            <div className={`card-buy-item text-slate-500 cursor-default`}>
              Celer
              <hr />
              <span>
                {translateObj('MESSAGE.WITHOUTCREDIT')}
              </span>
              <br />
              <button
                className='btn mt-2 btn-primary px-20'
                onClick={() => window.location.replace('/credit/buy')}
                >
                {translateObj('MESSAGE.GETCREDITS')}
              </button>
            </div>
          )}
        </div>

        <button className='mt-10 btn btn-dark px-20' onClick={confirmTypeSelect}
        disabled={isTeam || hasCredits || hasPremiumCredits ? false : true}>
          {translateObj('BTN.PROCCED')}
        </button>
      </div>
    </div>
  )
}
