import {useEffect, useState} from 'react'
import '../../../_metronic/assets/css/layout.css'
import { ModalAbout } from './components/Modal'

export const Demonstrative = () => {
  const [modalShow, setModalShow] = useState(false)
  const [photoSelected, setPhotoSelected] = useState({})

  const listImages = [
    {
      titulo: 'Comercial',
      ambientes: [
        {
          titulo: 'Agência de Publicidade',
          imagens: {
            antes: '/media/demo/comercial/agencia_publicidade/01.jpg',
            depois: '/media/demo/comercial/agencia_publicidade/02.jpg',
          },
        },
        {
          titulo: 'Coworking',
          imagens: {
            antes: '/media/demo/comercial/coworking/01.jpeg',
            depois: '/media/demo/comercial/coworking/02.jpg',
          },
        },
        {
          titulo: 'Escritório de Arquitetura',
          imagens: {
            antes: '/media/demo/comercial/escritorio_arquitetura/01.jpeg',
            depois: '/media/demo/comercial/escritorio_arquitetura/02.jpg',
          },
        },
      ],
    },
    {
      titulo: 'Residencial',
      ambientes: [
        {
          titulo: 'Cozinha 1',
          imagens: {
            antes: '/media/demo/residencial/cozinha1/01.jpg',
            depois: '/media/demo/residencial/cozinha1/02.jpg',
          },
        },
        {
          titulo: 'Cozinha 2',
          imagens: {
            antes: '/media/demo/residencial/cozinha2/01.jpg',
            depois: '/media/demo/residencial/cozinha2/02.jpg',
          },
        },
        {
          titulo: 'Quarto 1',
          imagens: {
            antes: '/media/demo/residencial/quarto1/01.jpg',
            depois: '/media/demo/residencial/quarto1/02.jpg',
          },
        },
        {
          titulo: 'Quarto 2',
          imagens: {
            antes: '/media/demo/residencial/quarto2/01.jpg',
            depois: '/media/demo/residencial/quarto2/02.jpg',
          },
        },
        {
          titulo: 'Sala 1',
          imagens: {
            antes: '/media/demo/residencial/sala1/01.jpg',
            depois: '/media/demo/residencial/sala1/02.jpg',
          },
        },
        {
          titulo: 'Sala 2',
          imagens: {
            antes: '/media/demo/residencial/sala2/01.jpg',
            depois: '/media/demo/residencial/sala2/02.jpg',
          },
        },
      ],
    },
    {
      titulo: 'Área Externa',
      ambientes: [
        {
          titulo: 'Fachada 1',
          imagens: {
            antes: '/media/demo/externa/fachada1/01.jpg',
            depois: '/media/demo/externa/fachada1/02.jpg',
          },
        },
        {
          titulo: 'Fachada 2',
          imagens: {
            antes: '/media/demo/externa/fachada2/01.jpg',
            depois: '/media/demo/externa/fachada2/02.jpg',
          },
        },
        {
          titulo: 'Quintal',
          imagens: {
            antes: '/media/demo/externa/quintal1/01.jpeg',
            depois: '/media/demo/externa/quintal1/02.jpg',
          },
        },
      ],
    },
  ]

  const moveBeforeAfter = () => {
    let active = false

    document.querySelector('.scroller').addEventListener('mousedown', function () {
      active = true
      document.querySelector('.scroller').classList.add('scrolling')
    })

    document.body.addEventListener('mouseup', function () {
      active = false
      document.querySelector('.scroller').classList.remove('scrolling')
    })
    document.body.addEventListener('mouseleave', function () {
      active = false
      document.querySelector('.scroller').classList.remove('scrolling')
    })

    document.body.addEventListener('mousemove', function (e) {
      if (!active) return
      let x = e.pageX
      x -= document.querySelector('.wrapper').getBoundingClientRect().left
      scrollIt(x)
    })

    document.body.addEventListener('touchmove', function (e) {
      if (!active) return
      let x = e.targetTouches[0]?.pageX
      x -= document.querySelector('.wrapper').getBoundingClientRect().left
      scrollIt(x)
    })

    function scrollIt(x) {
      let transform = Math.max(0, Math.min(x, document.querySelector('.wrapper').offsetWidth))
      document.querySelector('.after').style.width = transform + 'px'
      document.querySelector('.scroller').style.left = transform - 25 + 'px'
    }

    scrollIt(150)

    document.querySelector('.scroller').addEventListener('touchstart', function () {
      active = true
      document.querySelector('.scroller').classList.add('scrolling')
    })
    document.body.addEventListener('touchend', function () {
      active = false
      document.querySelector('.scroller').classList.remove('scrolling')
    })
    document.body.addEventListener('touchcancel', function () {
      active = false
      document.querySelector('.scroller').classList.remove('scrolling')
    })
  }

  const openImage = (item) => {
    setPhotoSelected(item.imagens)
  }

  useEffect(() => {
    moveBeforeAfter()
    setPhotoSelected(listImages[0].ambientes[0].imagens)
  }, [])

  return (
    <>
      <div className='demo d-flex'>
        <div className='vw-100' id='page'>
          <div className='wrapper'>
            <div className='before'>
              <img className='content-image' src={photoSelected?.antes} draggable='false' />
            </div>
            <div className='after'>
              <img className='content-image' src={photoSelected?.depois} draggable='false' />
            </div>
            <div className='scroller'>
              <div className='iconMove'>
                <span>{`<`}</span>
                <span>{`>`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='info-menu'>
          <div className='text-center'>
            <img src='/media/logos/logo-1.png' className='logo-demo my-10' />
          </div>

          {listImages?.map((item, key) => {
            return (
              <div key={key} className='px-10'>
                <h3>{item.titulo}</h3>
                <ul className='p-3'>
                  {item.ambientes.map((itemAmbiente, keyAmbiente) => {
                    return (
                      <li key={keyAmbiente} onClick={() => openImage(itemAmbiente)}>
                        {itemAmbiente.titulo}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}

          <div className='text-center'>
            <button type='button' className='btn btn-primary' onClick={() => setModalShow(!modalShow)}>
              Sobre a CELER
            </button>
          </div>
        </div>
      </div>
      <ModalAbout show={modalShow} setShow={setModalShow} />
    </>
  )
}
