import { Dispatch, SetStateAction, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { ModalConfirm } from "./ModalConfirm";
import { ModalImage } from "./ModalImage";

export function ImagesCeler(props: { translateObj: any, itemAmbience: any, setModified: Dispatch<SetStateAction<number>> }) {
  let { translateObj, itemAmbience, setModified } = props
  const photos: any[] = itemAmbience.photos
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const [typeConfirm, setTypeConfirm] = useState('');
  const [modalConfirm, setModalConfirm] = useState(false);
  const [itemConfirm, setItemConfirm] = useState<any>({});
  const [showImage, setShowImage] = useState(false)
  const [infoImage, setInfoImage] = useState<any>({})
  const filterPhotosAI = photos.filter(item => item.is_ai_render && item.is_celer);
  const approveCelerAI = filterPhotosAI.length > 0;

  const confirmModal = (item: any, type: string) => {
    setModalConfirm(true)
    setItemConfirm(item)
    setTypeConfirm(type)
  }

  const openModalAmbience = (obj: any) => {
    setShowImage(!showImage)
    setInfoImage(obj)
  }

  return (
    <div>
      <ModalConfirm translateObj={translateObj} show={modalConfirm} setShow={setModalConfirm} item={itemConfirm} type={typeConfirm} setModified={setModified} />
      <div>
        <b>{translateObj("MESSAGE.PHOTOS")} ({approveCelerAI ? filterPhotosAI.length : photos?.length}):</b>
      </div>
      <div className="w-100 overflow-auto">
        <div className="d-flex gap-5">
          {approveCelerAI ? (
            <>
              {photos?.map((item, key) => {
                if (item.is_valid && item.is_celer) {
                  return (
                    <div key={key}>
                      <div className='py-2 text-center position-relative'>
                        {!itemAmbience.is_approved && (
                          <div className="bg-danger position-absolute p-3 px-4 end-0 cursor-pointer" onClick={() => confirmModal(item, 'reprove')}>
                            <i className="fa fa-times text-white"></i>
                          </div>
                        )}
                        <span className="cursor-pointer" onClick={() => openModalAmbience(item)}>
                          <div className="m-auto">
                            <img src={item.path} className="heightImgMin" alt="Foto do cliente" />
                          </div>
                          <div className="bg-success mt-0">
                            <i className='fa fa-check text-white'></i>
                            <span className='mx-3 text-white'>{translateObj("MESSAGE.PHOTOCELER")}</span>
                          </div>
                        </span>
                      </div>
                      {((user?.type === 'Squad' || user?.type === 'Squadinator') && !itemAmbience.is_approved) && (
                        <>
                          <button type="button" className="btn btn-success w-100 mt-2" onClick={() => confirmModal(item, 'approve')}>
                            <i className="fa fa-check me-2"></i>
                            {translateObj("MESSAGE.APPROVEIMAGE")}
                          </button>
                        </>
                      )}
                    </div>
                  )
                }
              })}
            </>
          ) : (
            <>
              {photos?.map((item, key) => {
                if (!item.is_celer && item.is_valid) {
                  return (
                    <div key={key} className='py-2 text-center'>
                      {item.path.split(".pdf").length === 2 ? (
                        <a href={item.path} target='_blank' rel="noreferrer" download>
                          <div className="m-auto position-relative mb-2">
                            {item?.is_chosen && (<i className="fa fa-star text-warning info-star" />)}
                            <img src='/media/icons/pdf.png' className="heightImgMin" alt="Documento do cliente" />
                          </div>
                          <div className="bg-primary mt-0">
                            <i className='fa fa-search text-white'></i>
                            <span className='mx-3 text-white'>{translateObj("MESSAGE.DOCUMENTCLIENT")}</span>
                          </div>
                        </a>
                      ) : (
                        <span className="cursor-pointer" onClick={() => openModalAmbience(item)}>
                          <div className="m-auto position-relative">
                            {item?.is_chosen && (<i className="fa fa-star text-warning info-star" />)}
                            <img src={item.path} className="heightImgMin" alt="Foto do cliente" />
                          </div>
                          <div className="bg-primary mt-0">
                            <i className='fa fa-search text-white'></i>
                            <span className='mx-3 text-white'>{translateObj("MESSAGE.PHOTOCLIENT")}</span>
                          </div>
                        </span>
                      )}
                    </div>
                  )
                } else if (item.is_example && item.is_valid) {
                  return (
                    <span className="cursor-pointer" onClick={() => openModalAmbience(item)}>
                      <div className='py-2 text-center'>
                        <div className="m-auto">
                          <img src={item.path} className="heightImgMin" alt="Foto do cliente" />
                        </div>
                        <div className="bg-warning mt-0">
                          <i className='fa fa-check text-dark'></i>
                          <span className='mx-3 text-dark'>{translateObj("MESSAGE.EXAMPLECELER")}</span>
                        </div>
                      </div>
                    </span>
                  )
                } else if (item.is_valid && item.is_celer) {
                  return (
                    <div key={key}>
                      <div className='py-2 text-center position-relative'>
                        {(user.type !== 'Operator' && !itemAmbience.is_approved) && (
                          <div className="bg-danger position-absolute p-3 px-4 end-0 cursor-pointer" onClick={() => confirmModal(item, 'reprove')}>
                            <i className="fa fa-times text-white"></i>
                          </div>
                        )}
                        <span className="cursor-pointer" onClick={() => openModalAmbience(item)}>
                          <div className="m-auto">
                            <img src={item.path} className="heightImgMin" alt="Foto do cliente" />
                          </div>
                          <div className="bg-success mt-0">
                            <i className='fa fa-check text-white'></i>
                            <span className='mx-3 text-white'>{translateObj("MESSAGE.PHOTOCELER")}</span>
                          </div>
                        </span>
                      </div>
                      {((user?.type === 'Squad' || user?.type === 'Squadinator') && !itemAmbience.is_approved) && (
                        <>
                          <button type="button" className="btn btn-success w-100 mt-2" onClick={() => confirmModal(item, 'approve')}>
                            <i className="fa fa-check me-2"></i>
                            {translateObj("MESSAGE.APPROVEIMAGE")}
                          </button>
                        </>
                      )}
                    </div>
                  )
                } else if (!item.is_valid) {
                  return (
                    <span className="cursor-pointer" onClick={() => openModalAmbience(item)}>
                      <div className='py-2 text-center'>
                        <div className="m-auto">
                          <img src={item.path} className="heightImgMin" alt="Foto do cliente" />
                        </div>
                        <div className="bg-danger mt-0">
                          <i className='fa fa-times text-white'></i>
                          <span className='mx-3 text-white'>Reprovada</span>
                        </div>
                      </div>
                    </span>
                  )
                }
              })}
            </>
          )}

          <ModalImage translateObj={translateObj} ambienceId={itemAmbience?.id} show={showImage} setShow={setShowImage} image={infoImage} setModified={setModified} photos={photos} filterPhotosAI={filterPhotosAI} />
        </div>
      </div>
    </div>
  )
}