/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DetailCompany from './permission/Company'
import { RootState } from '../../../../setup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import * as auth from '../../../modules/auth/redux/AuthRedux'
import { setLanguageExternal } from '../../../../_metronic/i18n/Metronici18n'

export const infoTitle = {
  fontWeight: 800,
  fontFamily: "Arial",
  background: "#e4e4e4",
  lineHeight: "30px",
  paddingLeft: "8px",
  marginTop: "30px",
  marginBottom: "8px",
  borderBottom: "1px solid"
};

const DetailsProject: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch()
  const { paramToken, acessToken, externalId } = useParams<any>();
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any;
  const [blockScreen, setBlockScreen] = useState(false);
  const [authPage, setAuthPage] = useState<any>("");

  const translateObj = (obj: any, varInfo?: any) => {
    if (varInfo) {
      return intl.formatMessage({ id: obj }, varInfo)
    }

    return intl.formatMessage({ id: obj })
  }

  useEffect(() => {
    if (authPage === "") {
      setLanguageExternal("pt");

      if (paramToken !== "94848AdKdNZY0j0K.o") {
        setBlockScreen(true);
      } else if (!authPage) {
        localStorage.clear();
        dispatch(auth.actions.login(acessToken));
        setAuthPage(true);
      } else {
        setBlockScreen(user ? false : true);
      }
    }
  }, [authPage, user])

  if (blockScreen) {
    return (<>Sem acesso, verifique os dados.</>);
  }

  if (authPage && user) {
    return (
      <div className='content-era-layout'>
        <DetailCompany translateObj={translateObj} id={externalId} />
      </div>
    )
  } else {
    return (<>Autenticação necessária.</>)
  }
}

export { DetailsProject }
