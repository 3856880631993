/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { loginAdmin } from '../redux/AuthCRUD'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

const initialValues = {
  email: '',
  password: '',
}

export function Login(props: { translateObj: any }) {
  let { translateObj } = props;

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(translateObj("VALID.EMAIL"))
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(100, translateObj("VALID.MAX.CARACTER", { "length": 100 }))
      .required(translateObj("VALID.REQUIRED")),
    password: Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
  })
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        loginAdmin(values.email, values.password)
          .then((data) => {
            setLoading(false)
            dispatch(auth.actions.loginAdmin(data.data))
          })
          .catch((err) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(translateObj("VALID.INCORRET"))
          })
      }, 1000)
    },
  })

  const verifyPassword = () => {
    const input: any = document.getElementById('input-password')
    input.type === 'password' ? input.type = 'text' : input.type = 'password'
    setShowPassword(!showPassword)
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <h1 className='text-primary mb-3'>{translateObj("MESSAGE.LOGIN")}</h1>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>{translateObj("MESSAGE.EMAIL")}</label>
        <input
          placeholder={translateObj("MESSAGE.EMAIL")}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{translateObj("MESSAGE.PASSWORD")}</label>
            <Link
              to='/auth/forgot-password-admin'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
              {translateObj('MESSAGE.FORGOTPASSWORD')}
            </Link>
          </div>
        </div>
        <div className='position-relative'>
          <input
            id='input-password'
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <span className='position-absolute bg-white top-0 end-0 p-4 cursor-pointer' onClick={() => verifyPassword()}>
            {showPassword ? (<i className="fa fa-eye" aria-hidden="true"></i>) : (<i className="fa fa-eye-slash" aria-hidden="true"></i>)}
          </span>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-dark text-white w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{translateObj('BTN.ENTER')}</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {translateObj('MESSAGE.LOADING')}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
