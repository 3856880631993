import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useState } from 'react';
import { patchCompany } from '../../../services/Api';
import { maskCep, maskCpOrCnpj, validCPFandCNPJ, validCep } from '../../../utils';

export function ModalCompany(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, setModified: Dispatch<SetStateAction<number>>, companyId: any, company: any }) {
  const { translateObj, show, setShow, setModified, companyId, company } = props;
  const [loading, setLoading] = useState(false)
  const [loadingZipCode, setLoadingZipCode] = useState(false)

  const handleClose = () => {
    setShow(false);
  }

  const initialValues = {
    "companyId": companyId,
    document: company?.document ? company.document : '',
    "name": company?.name ? company.name : "",
    state: company?.state ? company.state : '',
    city: company?.city ? company.city : '',
    address: company?.address ? company.address : '',
    address_number: company?.address_number ? company.address_number : '',
    zip_code: company?.zip_code ? maskCep(company.zip_code) : '',
  }

  const currentSchema = Yup.object().shape({
    "document": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "name": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(100, translateObj("VALID.MAX.CARACTER", { "length": 100 }))
      .required(translateObj("VALID.REQUIRED")),
    "zip_code": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(9, translateObj("VALID.MAX.CARACTER", { "length": 9 }))
      .required(translateObj("VALID.REQUIRED")),
    "state": Yup.string()
      .min(2, translateObj("VALID.MIN.CARACTER", { "length": 2 }))
      .max(2, translateObj("VALID.MAX.CARACTER", { "length": 2 }))
      .required(translateObj("VALID.REQUIRED")),
    "address": Yup.string()
      .required(translateObj("VALID.REQUIRED")),
    "city": Yup.string()
      .required(translateObj("VALID.REQUIRED")),
    "address_number": Yup.string()
      .min(1, translateObj("VALID.MIN.CARACTER", { "length": 1 }))
      .max(4, translateObj("VALID.MAX.CARACTER", { "length": 4 }))
      .required(translateObj("VALID.REQUIRED")),
  })

  const submitForm = (values: any) => {
    setLoading(true)

    values.address_number = Number(values.address_number);
    values.zip_code = isNaN(values.zip_code) ? Number(values.zip_code.replace("-", "")) : values.zip_code;

    patchCompany(values).then(() => {
      setModified(Math.random())
      handleClose()
      setLoading(false)
    })
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitForm}>
        {({ setFieldValue }) => (
          <Form id='kt_user_form'>
            <Modal.Body>
              <div>
                <h2 className='fw-bolder text-dark'>{translateObj("MESSAGE.ALTERDATA")}</h2>

                <div className='fv-row my-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.NAME")}</label>

                  <Field name='name' className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='name' />
                  </div>
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.DOCUMENT')}</label>

                  <Field name='document' className='form-control form-control-lg form-control-solid' onKeyUp={(e: any) => setFieldValue('document', maskCpOrCnpj(e.target.value))}
                    onBlur={(e: any) => {
                      var length = e?.target?.value.length;

                      if ((length === 14 || length === 18) && !validCPFandCNPJ(e?.target?.value)) {
                        setFieldValue('document', '')
                      }
                    }} />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='document' />
                  </div>
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.ZIPCODE')}</label>
                  <Field name='zip_code' className='form-control form-control-lg form-control-solid' maxLength={9} onKeyUp={(e: any) => setFieldValue('zip_code', maskCep(e.target.value))} onBlur={(e: any) => e.target.value.length > 8 && validCep(e.target.value, setFieldValue, setLoadingZipCode)} />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='zip_code' />
                  </div>
                  {loadingZipCode && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Buscando informações...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </div>

                <div className="row">
                  <div className='col-8 fv-row mb-7'>
                    <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.CITY')}</label>

                    <Field name='city' className='form-control form-control-lg form-control-solid' />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='city' />
                    </div>
                  </div>

                  <div className='col-4 fv-row mb-7'>
                    <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.STATE')}</label>

                    <Field name='state' className='form-control form-control-lg form-control-solid' />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='state' />
                    </div>
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.ADDRESS')}</label>

                  <Field name='address' className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='address' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.NUMBER')}</label>

                  <Field name='address_number' className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='address_number' />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <>
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </>
              ) : (
                <>
                  <button type='button' className='btn btn-default' onClick={handleClose}>
                    {translateObj("BTN.CANCEL")}
                  </button>
                  <button type='submit' className='btn btn-dark text-white'>
                    <i className='fa fa-check'></i>
                    <span className='mx-1'>{translateObj("BTN.SAVE")}</span>
                  </button>
                </>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}