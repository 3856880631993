import { Field, ErrorMessage } from 'formik'
import { maskPhone } from '../../../../utils'

export function User(props: { translateObj: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void; }) {
  let { translateObj, setFieldValue } = props

  return (
    <>
      <div className='w-100'>
        <h2 className='fw-bolder text-dark'>
          <i className='fa fa-user-circle fa-1x me-3 text-primary'></i>
          {translateObj('MESSAGE.USER.DATA')}
        </h2>

        <div className='text-dark fw-bold fs-6 mb-15'>
          {translateObj('MESSAGE.USER.NEWACCOUNT')}
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.NAME')}</label>

          <Field name='consumer_name' className='form-control form-control-lg form-control-solid' />
          <div className='text-danger mt-2'>
            <ErrorMessage name='consumer_name' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.PHONE')}</label>

          <Field name='phone' className='form-control form-control-lg form-control-solid' maxLength={15} onKeyUp={(e: any) => setFieldValue('phone', maskPhone(e.target.value))} />
          <div className='text-danger mt-2'>
            <ErrorMessage name='phone' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.EMAIL')}</label>

          <Field type="email" name='email' className='form-control form-control-lg form-control-solid' />
          <div className='text-danger mt-2'>
            <ErrorMessage name='email' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.PASSWORD')}</label>

          <Field type="password" name='password' className='form-control form-control-lg form-control-solid' />
          <div className='text-danger mt-2'>
            <ErrorMessage name='password' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj('MESSAGE.CONFPASSWORD')}</label>

          <Field type="password" name='password_change' className='form-control form-control-lg form-control-solid' />
          <div className='text-danger mt-2'>
            <ErrorMessage name='password_change' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>
            <Field type="checkbox" name='accept_terms' className='form-check-input' value='1' />
            <span className='mx-3'>
              {translateObj('BTN.ACCEPT') + ' '}
              <a href='/media/termos de uso.pdf' target='_blank' rel='noreferrer' className='ms-1 link-primary'>
                {translateObj('BTN.ACCEPT.HREF')}
              </a>
            </span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='accept_terms' />
          </div>
        </div>
      </div>
    </>
  )
}