import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction } from 'react';

export function ModalToken(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, token: any }) {
  const { translateObj, show, setShow, token } = props;

  const handleClose = () => {
    setShow(false);
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div className='pb-15'>
          <div className='d-flex'>
            <button type='button' className='btn p-0 m-0 position-absolute' style={{ right: '5%' }} onClick={() => setShow(false)}>
              <i className='fa fa-times'></i>
            </button>
          </div>

          <div className='mt-10 text-center'>
            <b>{translateObj("MESSAGE.PURCHASETOKEN")}</b>
            <h1 className='mt-4 text-primary'>{token}</h1>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}