import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux"
import { RootState } from "../../../setup"
import { deleteUsersTeamId, getUsersTeam } from "../../services/Api";
import { ModalTeam } from "./components/ModalTeam";

export const ReportTeam = () => {
  const intl = useIntl();
  const translateObj = (obj: any, varInfo?: any) => {
    if (varInfo) {
      return intl.formatMessage({ id: obj }, varInfo)
    }

    return intl.formatMessage({ id: obj })
  }
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const [modified, setModified] = useState(1)
  const [modalShow, setModalShow] = useState(false);
  const [users, setUsers] = useState<any[]>([])
  const [team, setTeam] = useState<any>({})

  const limit = 10
  const [pagination, setPagination] = useState(1)
  const [totalPages, setTotalPages] = useState<any[]>([])
  const [listUsers, setListUsers] = useState<any[]>([])

  const verifyPagination = (page: any) => {
    let resultPage = (page - 1) * limit
    let newOffSet = { from: resultPage, to: resultPage + limit }

    setListUsers(users.slice(newOffSet.from, newOffSet.to))
    setPagination(page)
  }

  const verifyTotalPages = (array: []) => {
    const total = []
    const length = array.length / limit

    for (let i = 0; i < length; i++) {
      total.push(i + 1)
    }

    setTotalPages(total)
  }

  const verifyListUsers = (array: []) => {
    setUsers(array)
    setListUsers(array.slice(0, 10))

    verifyTotalPages(array)
  }

  const openModalEdit = (item: any) => {
    setTeam(item)
    setModalShow(true)
  }

  const removeTeam = (id: any) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Deseja remover este time?")) {
      deleteUsersTeamId(id).then(() => {
        setModified(Math.random())
      })
    }
  }

  useEffect(() => {
    getUsersTeam().then((data: any) => {
      verifyListUsers(data)
    })
  }, [modified])

  return (
    <>
      {(user.type === "Squad" || user.type === "Squadinator") ? (
        <>
          <h1 className='mb-10'>{translateObj("MESSAGE.CONSULTTEAMS")}</h1>
          <div className='card'>
            <div className='card-body'>
              <div className="d-flex justify-content-between align-items-center mb-5">
                <h3 className='mb-5'>{users?.length + ' ' + translateObj("MESSAGE.RESULTS")}:</h3>
                <button className="btn btn-dark text-white" onClick={() => {
                  setModalShow(true)
                  setTeam({})
                }}>
                  {translateObj("MESSAGE.ADDTEAM")}
                </button>
              </div>

              <div className="table-responsive">
                <table className="table table-hover">
                  <thead className='table-light border-bottom text-uppercase fw-bolder bg-secondary text-center'>
                    <tr>
                      <td>{translateObj("MESSAGE.NAME")}</td>
                      <td>{translateObj("MESSAGE.COLOR")}</td>
                      <td className='text-center'>{translateObj("MESSAGE.ACTIONS")}</td>
                    </tr>
                  </thead>
                  <tbody className='text-center'>
                    {listUsers?.length ? (listUsers.map(item => {
                      return (
                        <tr key={item.id} className='cursor-pointer border-bottom'>
                          <td>{item.name}</td>
                          <td align="center"><div style={{ width: '30px', height: '30px', background: `${item.color}` }}></div></td>
                          <td className='text-center'>
                            <div className="btn-group" role="group" aria-label="Ações">
                              <button type="button" className="btn btn-primary btn-sm" onClick={() => openModalEdit(item)}>
                                <i className="fa fa-edit" />
                              </button>
                              <button type="button" className="btn btn-danger btn-sm" onClick={() => removeTeam(item.id)}>
                                <i className="fa fa-times" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })) : (
                      <tr>
                        <td colSpan={3}>{translateObj("MESSAGE.NOTRESULT")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {totalPages?.length > 0 && (
                  <div className="float-end my-5 text-center">
                    {translateObj("MESSAGE.PAGINATION")}
                    <div className="d-flex pt-3">
                      {totalPages.map(item => {
                        return (
                          <button key={item} type="button" className={`btn btn-sm text-primary p-0 m-0 px-1 border mx-1 ${pagination === item && 'border-primary border-2'}`} onClick={() => verifyPagination(item)}>{item}</button>
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ModalTeam translateObj={translateObj} show={modalShow} setShow={setModalShow} setModified={setModified} team={team} />
        </>
      ) : (
        <>
          {translateObj("VALID.PERMISSION")}
        </>
      )}
    </>
  )
}