/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import DashboardCompany from './permission/Company'
import DashboardTeam from './permission/Team'

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  const translateObj = (obj: any, varInfo?: any) => {
    if (varInfo) {
      return intl.formatMessage({ id: obj }, varInfo)
    }

    return intl.formatMessage({ id: obj })
  }
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any

  return (
    <>
      {user && (user?.type ? <DashboardTeam translateObj={translateObj} /> : <DashboardCompany translateObj={translateObj} />)}
    </>
  )
}

export { DashboardWrapper }
