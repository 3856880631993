import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useState } from 'react';
import { postOrderPhoto } from '../../../../services/Api';

export function ModalSendImages(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, ambience: any, isAdmin: boolean, setModified: Dispatch<SetStateAction<number>> }) {
  const { translateObj, show, setShow, ambience, isAdmin, setModified } = props;
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState<any>();

  const handleClose = () => {
    setShow(false);
  }

  const initialValues = {
    file: undefined,
    sendFile: '',
  }

  const currentSchema = Yup.object().shape({
    "sendFile": Yup.string().required(translateObj("VALID.REQUIRED")),
  })

  const changeFile = (files: any, setFieldValue: any) => {
    setFile(files)
    setFieldValue('sendFile', 'sendFile')
  }

  const submitForm = () => {
    setLoading(true)
    const form = new FormData();
    form.append('name', ambience.name);
    form.append('description', ambience.description);
    form.append('file', file[0]);
    form.append('order_id', ambience.order_id);

    postOrderPhoto(form).then(() => {
      setModified(Math.random())
      setLoading(false)
      handleClose()
    })
  }

  return (
    <Modal show={show} onHide={handleClose} className='content-era-layout'>
      <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitForm}>
        {({ values, setFieldValue }) => (
          <Form id='kt_upload_form'>
            <Modal.Header>
              <Modal.Title className='text-primary-dark'>
                {isAdmin ? translateObj("MESSAGE.COMPLETIONPHOTO") : translateObj("MESSAGE.SENDNEWPHOTOS")}

                <div className='fs-6 text-dark-era mt-2'>
                  <b>{translateObj("MESSAGE.AMBIENCE")}:</b>
                  <span className='mx-1'>{ambience?.name}</span>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className='fv-row mt-7 mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.SENDDOCUMENTPHOTO")}</label>

                  <Field type='file' accept="image/*" name='file' className='form-control form-control-lg form-control-solid' onChange={(e: any) => changeFile(e.target.files, setFieldValue)} />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='sendFile' />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <>
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </>
              ) : (
                <>
                  <button type='button' className='btn btn-default' onClick={handleClose}>
                    {translateObj("BTN.CANCEL")}
                  </button>
                  <button type='submit' className='btn btn-dark text-white'>
                    <i className='fa fa-check'></i>
                    <span className='mx-1'>{translateObj("BTN.SAVE")}</span>
                  </button>
                </>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}