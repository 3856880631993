import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import CreateProject from '../pages/create/CreateProject'
import { ReportProject } from '../pages/report/ReportProject'
import { DetailsProject } from '../pages/detailsProject/DetailsProject'
import { Kanban } from '../pages/kanban'
import { ReportUsers as ReportUsersCompany } from '../pages/company/users/ReportUsers'
import { ChangePassword } from '../pages/changePassword'
import { BuyCredit } from '../pages/credit/buy'
import ReportCredit from '../pages/credit/report'
import ReportCompanies from '../pages/company/report'
import DetailCompany from '../pages/company/details'
import { ReportUsers as ReportUsersTeam } from '../pages/users/Report'
import { ReportTeam } from '../pages/team/report'
import ReportCoupons from '../pages/coupon/report'
import { ReportExecutives } from '../pages/executives/Report'
import { ReportInfluencers } from '../pages/influencers/Report'
import { Notifications } from '../pages/notifications'

export function PrivateRoutes() {
  return (
    <Suspense fallback={<>Aguarde...</>}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/create' component={CreateProject} />
        <Route path='/report/:status' component={ReportProject} />
        <Route path='/report' component={ReportProject} />
        <Route path='/detailsProject/:id' component={DetailsProject} />
        <Route path='/kanban' component={Kanban} />
        <Route path='/company/users' component={ReportUsersCompany} />
        <Route path='/users/report' component={ReportUsersTeam} />
        <Route path='/executives/report' component={ReportExecutives} />
        <Route path='/influencers/report' component={ReportInfluencers} />
        <Route path='/teams/report' component={ReportTeam} />
        <Route path='/changePassword' component={ChangePassword} />
        <Route path='/credit/buy' component={BuyCredit} />
        <Route path='/creditBuy/:id/:amount' component={BuyCredit} />
        <Route path='/credit/report' component={ReportCredit} />
        <Route path='/company/report' component={ReportCompanies} />
        <Route path='/company/:id' component={DetailCompany} />
        <Route path='/company/detail' component={DetailCompany} />
        <Route path='/coupon/report' component={ReportCoupons} />
        <Route path='/notifications' component={Notifications} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
