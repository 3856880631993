import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useState } from 'react';
import { statusOption } from '../../../utils';
import { patchAdminOrder } from '../../../services/Api';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';

export function ModalChangeStatus(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, order: any, setModified: Dispatch<SetStateAction<number>> }) {
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const { translateObj, show, setShow, order, setModified } = props;
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setShow(false);
  }

  const initialValues = {
    status: '',
  }

  const currentSchema = Yup.object().shape({
    "status": Yup.string().required(translateObj("VALID.REQUIRED")),
  })

  const submitForm = (values: any) => {
    setLoading(true)

    patchAdminOrder({ id: order.id, status: values.status, standard: order.standard }).then(() => {
      setModified(Math.random())
      handleClose()
      setLoading(false)
    })
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitForm}>
        {() => (
          <Form id='kt_change_status_form'>
            <Modal.Header>
              <Modal.Title className='text-primary'>
                {translateObj("MESSAGE.UPDATEDSTATUSPROJECT")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='fv-row mb-7'>
                <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.STATUS")}</label>

                <Field as='select' name='status' className='form-select form-select-lg form-select-solid'>
                  <option value=''>{translateObj("MESSAGE.SELECT")}</option>
                  {statusOption.map(item => {
                    if (user.type === "Operator" && (item.value === "In_Progress" || item.value === "In_Review")) {
                      return <option key={item.value} value={item.value}>{item.label}</option>
                    } else if (user.type !== "Operator") {
                      return <option key={item.value} value={item.value}>{item.label}</option>
                    }
                  })}
                </Field>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='status' />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <>
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </>
              ) : (
                <>
                  <button type='button' className='btn btn-default' onClick={handleClose}>
                    {translateObj("BTN.CANCEL")}
                  </button>
                  <button type='submit' className='btn btn-dark text-white'>
                    <i className='fa fa-check'></i>
                    <span className='mx-1'>{translateObj("BTN.SAVE")}</span>
                  </button>
                </>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}