import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useState } from 'react';

export function ModalAbout(props: { show: any, setShow: Dispatch<SetStateAction<boolean>> }) {
  const { show, setShow } = props;

  const handleClose = () => {
    setShow(false);
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div>
          <div className='d-flex'>
            <img src='/media/logos/logo-1.png' className='logo-demo m-auto' />
            <button type='button' className='btn p-0 m-0 position-absolute' style={{ right: '5%' }} onClick={() => setShow(false)}>
              <i className='fa fa-times'></i>
            </button>
          </div>

          <hr />

          <div className='mt-10'>
            <h3 className='text-center'>A ferramenta que vai acelerar suas vendas</h3>
            <ul className='p-0 text-center fs-4 mt-10' style={{ listStyle: 'none' }}>
              <li><b>+ de 50%</b> de aumento de leads</li>
              <li><b>+ qualificação</b> dos anúncios</li>
              <li><b>+ valorização</b> da experiência do cliente</li>
              <li><b>+ exclusividade</b> na comercialização</li>
              <li><b>+ conversão</b> para venda</li>
            </ul>

            <div className='text-center mt-15'>
              <p>Aponte a câmera do seu celular e leia o código QR</p>
              <img src='/media/demo/qrCode.svg' width='200' />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}