export default function DashboardCompany(props: { translateObj: any }) {
  let { translateObj } = props;

  return (
    <>
      <h1 className='text-uppercase'>{translateObj("MESSAGE.WHATTODO")}</h1>
      <p>{translateObj("MESSAGE.WHATTODO.DESCRIPTION")}</p>

      <div className="row mt-15">
        <div className="col-md-6 col-sm-12 my-5">
          <a href="/create" className="text-dark">
            <div className="card">
              <div className="card-body">
                <div className="d-flex gap-5 align-items-center">
                  <div>
                    <i className="fa fa-plus-circle fa-5x text-lightdark"></i>
                  </div>
                  <div>
                    <h5 className="card-title">{translateObj("MESSAGE.NEWPROJECT")}</h5>
                    <p className="card-text">{translateObj("MESSAGE.DASHBOARD.NEWPROJECT")}</p>
                  </div>
                </div>
                <button type="button" className="btn btn-dark text-white w-100 mt-10">{translateObj("BTN.CREATE")}</button>
              </div>
            </div>
          </a>
        </div>
        <div className="col-md-6 col-sm-12 my-5">
          <a href="/report" className="text-dark">
            <div className="card">
              <div className="card-body">
                <div className="d-flex gap-5 align-items-center">
                  <div>
                    <i className="fa fa-file fa-5x text-lightdark"></i>
                  </div>
                  <div>
                    <h5 className="card-title">{translateObj("MESSAGE.CONSULTPROJECTS")}</h5>
                    <p className="card-text">{translateObj("MESSAGE.DASHBOARD.CONSULTPROJECTS")}</p>
                  </div>
                </div>
                <button type="button" className="btn btn-dark text-white w-100 mt-10">{translateObj("BTN.CONSULT")}</button>
              </div>
            </div>
          </a>
        </div>
        <div className="col-md-6 col-sm-12 my-5">
          <a href="/credit/buy" className="text-dark">
            <div className="card">
              <div className="card-body">
                <div className="d-flex gap-5 align-items-center">
                  <div>
                    <i className="fa fa-cart-plus fa-5x text-lightdark"></i>
                  </div>
                  <div>
                    <h5 className="card-title">{translateObj("MESSAGE.BUYCREDITANDIMAGE")}</h5>
                    <p className="card-text">{translateObj("MESSAGE.DASHBOARD.BUYCREDITANDIMAGE")}</p>
                  </div>
                </div>
                <button type="button" className="btn btn-dark text-white w-100 mt-10">{translateObj("BTN.BUY")}</button>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  )
}