import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getAdminTeamsChoice, putAdminTeam } from '../../../services/Api';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';

export function ModalChangeTeam(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, order: any, setModified: Dispatch<SetStateAction<number>> }) {
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const { translateObj, show, setShow, order, setModified } = props;
  const [loading, setLoading] = useState(false)
  const [teams, setTeams] = useState([])

  const handleClose = () => {
    setShow(false);
  }

  const initialValues = {
    team: order?.team_id ? order.team_id : '',
  }

  const currentSchema = Yup.object().shape({
    "team": Yup.string().required(translateObj("VALID.REQUIRED")),
  })

  const submitForm = (values: any) => {
    setLoading(true)

    putAdminTeam({ order_id: order.id, team_id: values.team }).then(() => {
      handleClose()
      setModified(Math.random())
      setLoading(false)
    })
  }

  useEffect(() => {
    getAdminTeamsChoice().then(data => {
      setTeams(data)
    })
  }, [])

  return (
    <Modal show={show} onHide={handleClose}>
      {(user?.type === 'Squad' || user?.type === 'Squadinator') && (
        <>
          <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitForm}>
            {({ setFieldValue }) => (
              <Form id='kt_change_status_form'>
                <Modal.Header>
                  <Modal.Title className='text-primary'>
                    {translateObj("MESSAGE.UPDATEDTEAM")}

                    <div className='text-dark fs-5'>
                      <b className='me-2'>{translateObj("MESSAGE.TEAMNOW")}:</b>
                      {order?.team?.name}
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div className='fv-row mb-7'>
                      <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.CHOICETEAM")}:</label>

                      <Field as='select' name='team' className='form-select form-select-lg form-select-solid'>
                        <option value=''>{translateObj("MESSAGE.SELECT")}</option>
                        {teams?.map((item: any) => {
                          return <option key={item.id} value={item.id}>{item.name}</option>
                        })}
                      </Field>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='team' />
                      </div>
                    </div>

                    <p className='fs-5'>{translateObj("MESSAGE.UPDATEDTEAM.DESCRIPTION")}</p>

                    {loading ? (
                      <>
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          {translateObj("MESSAGE.LOADING") + ' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      </>
                    ) : (
                      <div className='d-grid gap-5 mt-10'>
                        <button type='submit' className='btn btn-dark text-white'>
                          <i className='fa fa-check me-2'></i>
                          {translateObj("BTN.CONFIRM")}
                        </button>
                        <button type='button' className='btn btn-default' onClick={handleClose}>{translateObj("BTN.CANCEL")}</button>
                      </div>
                    )}
                  </div>
                </Modal.Body>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Modal>
  )
}