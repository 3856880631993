import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { maskPhone } from '../../../utils';
import { deleteUsersId, getUsersId, getUsersTeam, patchUsersId, postUsers } from '../../../services/Api';

export function ModalUser(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, setModified: Dispatch<SetStateAction<number>>, user: any }) {
  const { translateObj, show, setShow, setModified, user } = props;
  const [loading, setLoading] = useState(false)
  const [teams, setTeams] = useState([])

  const handleClose = () => {
    setShow(false);
  }

  const initialValues = {
    "name": user?.name ? user.name : "",
    "email": user?.email ? user.email : "",
    "phone": user?.phone ? maskPhone(user.phone) : "",
    "password": "",
    "type": user?.type ? user.type : "",
    "team_id": user?.team?.id ? user?.team?.id : "",
  }

  const currentSchema = user?.id ? Yup.object().shape({
    "name": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "email": Yup.string()
      .email(translateObj("VALID.EMAIL"))
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(100, translateObj("VALID.MAX.CARACTER", { "length": 100 }))
      .required(translateObj("VALID.REQUIRED")),
    "type": Yup.string()
      .required(translateObj("VALID.REQUIRED")),
    "team_id": Yup.string()
      .required(translateObj("VALID.REQUIRED")),
  }) : Yup.object().shape({
    "name": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "email": Yup.string()
      .email(translateObj("VALID.EMAIL"))
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(100, translateObj("VALID.MAX.CARACTER", { "length": 100 }))
      .required(translateObj("VALID.REQUIRED")),
    "phone": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "password": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "type": Yup.string()
      .required(translateObj("VALID.REQUIRED")),
    "team_id": Yup.string()
      .required(translateObj("VALID.REQUIRED")),
  })

  const submitForm = (values: any) => {
    setLoading(true)

    let data = values

    data.alias_name = values.name
    data.phone = values.phone.replaceAll("-", "").replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "")

    if (user?.id && !data.password) {
      delete data.password
    }

    if (user?.id) {
      patchUsersId(user?.id, data).then(() => {
        setModified(Math.random())
        handleClose()
        setLoading(false)
      })
    } else {
      postUsers(data).then(() => {
        setModified(Math.random())
        handleClose()
        setLoading(false)
      })
    }
  }

  const removeUser = (id: any) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(translateObj("MESSAGE.WANTREMOVEUSER"))) {
      setLoading(true)

      deleteUsersId(id).then(() => {
        setModified(Math.random())
        handleClose()
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    getUsersTeam().then(data => {
      setTeams(data)
    })
  }, [])

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik validationSchema={currentSchema} initialValues={initialValues} enableReinitialize onSubmit={submitForm}>
        {({ setFieldValue }) => (
          <Form id='kt_user_form'>
            <Modal.Body>
              <div>
                <h2 className='fw-bolder text-dark'>{translateObj("MESSAGE.USER.DATA")}</h2>

                <div className='text-gray-400 fw-bold fs-6 mb-15'>
                  {translateObj("MESSAGE.USER.NEWACCOUNT")}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.TYPEOFACESS")}</label>

                  <Field as='select' name='type' className='form-select form-select-lg form-select-solid'>
                    <option value=''>{translateObj("MESSAGE.SELECT")}</option>
                    <option value='Operator'>{translateObj("MESSAGE.OPERATOR")}</option>
                    <option value='Coordinator'>{translateObj("MESSAGE.COORDINATOR")}</option>
                    <option value='Squad'>{translateObj("MESSAGE.SQUAD")}</option>
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='type' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.TEAM")}</label>

                  <Field as='select' name='team_id' className='form-select form-select-lg form-select-solid'>
                    <option value=''>{translateObj("MESSAGE.SELECT")}</option>
                    {teams && teams.map((itemTeam: any, keyTeam: any) => {
                      return (<option key={keyTeam} value={itemTeam.id} selected={user?.team_id === itemTeam.id}>{itemTeam.name}</option>)
                    })}
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='team_id' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.NAME")}</label>

                  <Field name='name' className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='name' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.PHONE")}</label>

                  <Field name='phone' className='form-control form-control-lg form-control-solid' maxLength={15} onKeyUp={(e: any) => setFieldValue('phone', maskPhone(e.target.value))} />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='phone' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.EMAIL")}</label>

                  <Field type="email" name='email' className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='email' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.PASSWORD")}</label>

                  <Field type="password" name='password' className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='password' />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <>
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </>
              ) : (
                <>
                  {user?.id && (
                    <button type='button' className='btn btn-danger text-white position-absolute' style={{ left: '4.5%' }} onClick={() => removeUser(user?.id)}>
                      <i className='fa fa-times'></i>
                      <span className='mx-1'>{translateObj("MESSAGE.DELETEUSER")}</span>
                    </button>
                  )}
                  <button type='button' className='btn btn-default' onClick={handleClose}>
                    {translateObj("BTN.CANCEL")}
                  </button>
                  <button type='submit' className='btn btn-dark text-white'>
                    <i className='fa fa-check'></i>
                    <span className='mx-1'>{translateObj("BTN.SAVE")}</span>
                  </button>
                </>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}