/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import ReportCompany from './permission/Company'
import ReportTeam from './permission/Team'
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { useIntl } from 'react-intl';

export const numberOfLines: any = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: "100px",
  cursor: "pointer",
};

export interface IFilter {
  zip_code?: number | string;
  tag?: string;
  category?: string;
  status?: string;
}

export const initialFilter = {
  zip_code: '', tag: '', category: ''
}

export const initialFilterAdmin = {
  company: '', zip_code: '', tag: '', category: '', status: ''
}

const ReportProject: FC = () => {
  const intl = useIntl();
  const translateObj = (obj: any, varInfo?: any) => {
    if (varInfo) {
      return intl.formatMessage({ id: obj }, varInfo)
    }

    return intl.formatMessage({ id: obj })
  }
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any

  return (
    <>
      {user && (user?.type ? <ReportTeam translateObj={translateObj} /> : <ReportCompany translateObj={translateObj} />)}
    </>
  )
}

export { ReportProject }
