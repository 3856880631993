import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { Board } from './components/Board';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { getAdminOrder, getAdminOrderId } from '../../services/Api';
import { searchStatusStringToNumber } from '../../utils';
import { ModalDetail } from './components/Modal';
import { useIntl } from 'react-intl';

export interface IBoards { id: number; color: string; title: string; array: { id: number; }[]; };

const Kanban = () => {
  const intl = useIntl();
  const translateObj = (obj: any, varInfo?: any) => {
    if (varInfo) {
      return intl.formatMessage({ id: obj }, varInfo)
    }

    return intl.formatMessage({ id: obj })
  }
  const initialBoard = [
    { id: 1, color: "#aaaaaa", title: translateObj("MESSAGE.WAITINGPAYMENT"), array: [] },
    { id: 2, color: "#f24726", title: translateObj("MESSAGE.WAITINGSERIVCE"), array: [] },
    { id: 3, color: "#2e9bf0", title: translateObj("MESSAGE.INPROGRESS"), array: [] },
    { id: 4, color: "#ff9e4b", title: translateObj("MESSAGE.CUSTOMERCHANGE"), array: [] },
    { id: 5, color: "#af78ff", title: translateObj("MESSAGE.REVIEWCOORDINATION"), array: [] },
    { id: 6, color: "#ac9cc4", title: translateObj("MESSAGE.REVIEWSQUAD"), array: [] },
    { id: 7, color: "#8fd150", title: translateObj("MESSAGE.FINISHED"), array: [] },
  ]
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const [boards, setBoards] = useState<any[]>(initialBoard)
  const [isArray, setIsArray] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [orderItemSelected, setOrderItemSelected] = useState<any>()
  const [movimentItem, setMovimentItem] = useState()
  const [typeFilter, setTypeFilter] = useState('ALL');
  const [typeFilterMyOrders, setTypeFilterMyOrders] = useState('');

  const clearFilter = () => {
    setTypeFilter('ALL');
    setTypeFilterMyOrders('');
  }

  useEffect(() => {
    const param = {
      my_orders: typeFilterMyOrders === "MY_ORDERS"
    };
    getAdminOrder(param).then(data => {
      let orders = data.data

      const newBoards = boards.map(item => {
        const ordersStatus = orders.filter((itemOrder: any) => searchStatusStringToNumber(itemOrder.order.status) === item.id)
        let orderStatusFilter = ordersStatus.filter((itemOrderFilter: any) => typeFilter === 'EXPRESS' ? itemOrderFilter.order.is_celer_express === true : (typeFilter === 'CELER' ? itemOrderFilter.order.is_celer_express === false : true))
        if(item.id === 7) {
          const sevenDaysAgo = new Date();
          sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

          orderStatusFilter = orderStatusFilter.filter((itemOrder: any) => {
            if (itemOrder.order.finished_at) {
              const itemDate = new Date(itemOrder.order.finished_at);
              return itemDate >= sevenDaysAgo && itemDate <= new Date();
            }
          })
        }
        item.array = orderStatusFilter

        return item
      })

      setIsArray(true)
      setBoards(newBoards)
    })

    if (orderItemSelected) {
      let orderFull = orderItemSelected
      getAdminOrderId(orderItemSelected.id).then(data => {
        orderFull.delay = data.delay
        orderFull.enviroments = data.order.enviroments
        setOrderItemSelected(orderFull)
      })
    }
  }, [movimentItem, orderItemSelected, typeFilter, typeFilterMyOrders])

  return (
    <>
      {user && (user?.type ? (
        <div>
          <div className='mb-5'>
            <h3>{translateObj("MESSAGE.FILTER")}:</h3>
            <div className='d-flex gap-3'>
              <button type='button' className={`btn ${typeFilter === "ALL" ? 'btn-primary' : 'btn-default'}  btn-sm`} onClick={() => setTypeFilter('ALL')}>{translateObj("MESSAGE.ALL")}</button>
              <button type='button' className={`btn ${typeFilter === "CELER" ? 'btn-primary' : 'btn-default'} btn-sm`} onClick={() => setTypeFilter('CELER')}>{translateObj("MESSAGE.PRODUCT.CELER")}</button>
              <button type='button' className={`btn ${typeFilter === "EXPRESS" ? 'btn-primary' : 'btn-default'} btn-sm`} onClick={() => setTypeFilter('EXPRESS')}>{translateObj("MESSAGE.PRODUCT.EXPRESS")}</button>
              <button type='button' className={`btn ${typeFilterMyOrders === "MY_ORDERS" ? 'btn-primary' : 'btn-default'} btn-sm`} onClick={() => setTypeFilterMyOrders('MY_ORDERS')}>{translateObj("MESSAGE.PRODUCT.MY_ORDERS")}</button>
              <button type='button' className='btn btn-secondary btn-sm' onClick={() => clearFilter()}>
                <i className='fa fa-times me-2'></i>
                Limpar filtro
              </button>
            </div>
            <hr />
          </div>
          <div className="d-flex gap-4 align-items-top">
            <ModalDetail translateObj={translateObj} show={modalDetail} setShow={setModalDetail} order={orderItemSelected} setOrder={setOrderItemSelected} setMovimentItem={setMovimentItem} />
            <DndProvider backend={HTML5Backend}>
              {isArray ?
                boards.map(item => {
                  return <Board key={item.id} itemBoard={item} items={item.array} boards={boards} setBoards={setBoards} setShow={setModalDetail} setOrderItemSelected={setOrderItemSelected} setMovimentItem={setMovimentItem} />
                }) : (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ' '}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
            </DndProvider>
          </div>
        </div>
      ) : (
        <h5>{translateObj("VALID.PERMISSION")}</h5>
      ))}
    </>
  )
}

export { Kanban }