import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction } from 'react';

export function ModalAlertUseCredit(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, functionAlert: () => void, typeSelect: any, setTypeSelect: Dispatch<SetStateAction<any>> }) {
  const { translateObj, show, setShow, functionAlert, typeSelect, setTypeSelect } = props;
  
  const selectPurchaseType = (type: any) => {
    let typeSelected: any = { credit: false, image: false }
    typeSelected[type] = true

    setTypeSelect(typeSelected)
  }

  const handleClose = () => {
    setShow(!show);
  }

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Body>
        <div>
          <button type='button' className='btn p-0 m-0 position-absolute' style={{ top: '3%', right: '3%', padding: 5 }} onClick={handleClose}>
            <i className='fa fa-times'></i>
          </button>

          <h3>Confirmar pedido</h3>
          <p className="mt-5 mb-10">
            Necessário confirmar seu pedido.
          </p>

          <div className="d-flex gap-10 justify-content-center content-card-buy-item">
            <div className={`card-buy-item text-center ${typeSelect?.image && 'active'}`} onClick={() => selectPurchaseType('image')}>
              <span>
                CELER PREMIUM
              </span>
            </div>
            <div className={`card-buy-item text-center ${typeSelect?.credit && 'active'}`} onClick={() => selectPurchaseType('credit')}>
              <span>
                CELER
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-default' onClick={handleClose}>{translateObj("BTN.CANCEL")}</button>
        <button type='button' className='btn btn-primary' onClick={functionAlert}>{translateObj("BTN.CONFIRM")}</button>
      </Modal.Footer>
    </Modal>
  )
}