import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { statusOption } from '../../../utils';
import { patchAdminOrder } from '../../../services/Api';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import moment from 'moment';

export function ModalChangeDateForecast(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, order: any, setModified: Dispatch<SetStateAction<number>> }) {
  const { translateObj, show, setShow, order, setModified } = props;
  const [loading, setLoading] = useState(false)
  const [dateInput, setDateInput] = useState('')

  const handleClose = () => {
    setShow(false);
  }

  const initialValues = {
    date: order?.finish_in ? moment(order.finish_in).format('YYYY-MM-DD HH:mm') : '',
  }

  const currentSchema = Yup.object().shape({
    "date": Yup.string().required(translateObj("VALID.REQUIRED")),
  })

  const verifyDate = (date: any, setFieldValue: any) => {
    const dateSelected = moment(date).format('YYYY-MM-DD HH:mm');
    setDateInput(dateSelected);
    setFieldValue('date', dateSelected)
  }

  const submitForm = (values: any) => {
    // setLoading(true)

    console.log(values)

    // patchAdminOrder({ id: order.id, status: values.status, standard: order.standard }).then(() => {
    //   setModified(Math.random())
    //   handleClose()
    //   setLoading(false)
    // })
  }

  useEffect(() => {
    order?.finish_in && setDateInput(moment(order.finish_in).format('YYYY-MM-DD HH:mm'))
  }, [order?.finish_in])

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitForm}>
        {({ setFieldValue }) => (
          <Form id='kt_change_date_forecast_form'>
            <Modal.Header>
              <Modal.Title className='text-primary'>
                {translateObj("MESSAGE.UPDATEDDATEFORECAST")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='fv-row mb-7'>
                <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.DATEFORECAST")}</label>
                <Field name='date' value={dateInput} className='form-control form-control-lg form-control-solid d-none' />
                <input type='datetime-local' value={dateInput} className='form-control form-control-lg form-control-solid' onChange={(e: any) => verifyDate(e.target.value, setFieldValue)} />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='date' />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <>
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </>
              ) : (
                <>
                  <button type='button' className='btn btn-default' onClick={handleClose}>
                    {translateObj("BTN.CANCEL")}
                  </button>
                  <button type='submit' className='btn btn-dark text-white'>
                    <i className='fa fa-check'></i>
                    <span className='mx-1'>{translateObj("BTN.SAVE")}</span>
                  </button>
                </>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}