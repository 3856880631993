import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../../setup';

export function ModalImage(props: { translateObj: any, ambienceId: string, show: any, setShow: Dispatch<SetStateAction<boolean>>, image: any, setModified: Dispatch<SetStateAction<any>>, photos: any, filterPhotosAI: any }) {
  const { translateObj, ambienceId, show, setShow, image, setModified, photos, filterPhotosAI } = props;
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const [loading, setLoading] = useState(false);

  const photosView = filterPhotosAI.length ? filterPhotosAI : photos;

  const handleClose = () => {
    setShow(!show)
  }

  return (
    <Modal size="lg" show={show} onHide={handleClose} className='content-era-layout'>
      <Modal.Body>
        <div>
          <h5>{translateObj("MESSAGE.VIEWIMAGE")}:</h5>

          <button type='button' className='btn p-0 m-0 position-absolute' style={{ top: 20, right: '5%' }} onClick={() => setShow(false)}>
            <i className='fa fa-times'></i>
          </button>
        </div>

        <div className='w-100'>
          <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
            <div className="carousel-indicators">
              {photosView.map((itemPathClick: any, keyClick: number) => {
                return (<button key={keyClick} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={keyClick} className={`${itemPathClick.path === image.path && 'active'}`} aria-current="true" aria-label={`Photo ${keyClick++}`}></button>)
              })}
            </div>
            <div className="carousel-inner">
              {photosView.map((itemPathClick: any, keyPathClick: number) => {
                return (
                  <div key={keyPathClick} id={`image-${itemPathClick.id}`} className={`carousel-item ${itemPathClick.path === image.path && 'active'}`}>
                    <div className="position-relative">
                      {!itemPathClick?.is_valid && (
                        <div className="position-absolute w-100 text-center">
                          <div className="fs-3 bg-danger py-3 text-white">
                            Imagem reprovada
                          </div>
                        </div>
                      )}
                      <img src={itemPathClick.path} className="d-block w-100 heightMin" alt="..." />
                    </div>

                    <div className="carousel-caption d-none d-md-block">
                      <div className='d-flex gap-5 justify-content-center'>
                        <a href={itemPathClick?.path} className='btn btn-dark' target='_blank' rel="noreferrer" download>
                          <i className='fa fa-download' /> {translateObj("BTN.DOWNLOADIMAGE")}
                        </a>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <i className="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}