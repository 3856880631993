import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction } from 'react';

export function ModalConfirm(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, setValidSendPhotos: Dispatch<SetStateAction<boolean>> }) {
  const { translateObj, show, setShow, setValidSendPhotos } = props;

  const handleClose = () => {
    setShow(!show);
  }

  const confirmPhotosValid = () => {
    handleClose()
    setValidSendPhotos(true)
    document.getElementById('btnSubmitPhotos')?.click()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className='text-primary'>
          {translateObj("MESSAGE.PURCHASENOTICE")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {translateObj("MESSAGE.PURCHASENOTICE.DESCRIPTION")}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-default' onClick={handleClose}>
          {translateObj("BTN.CANCEL")}
        </button>
        <button type='button' className='btn btn-dark text-white' onClick={confirmPhotosValid}>
          <i className='fa fa-check'></i>
          <span className='mx-2'>{translateObj("BTN.CONFIRM")}</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}