import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup'
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux"
import { RootState } from "../../../setup"
import { patchAuthUserPassword } from "../../services/Api";
import { useIntl } from "react-intl";

export const ChangePassword = () => {
  const intl = useIntl();
  const translateObj = (obj: any, varInfo?: any) => {
    if (varInfo) {
      return intl.formatMessage({ id: obj }, varInfo)
    }

    return intl.formatMessage({ id: obj })
  }
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const [loading, setLoading] = useState(false)

  const initialValues = {
    password: '',
    password_change: '',
  }

  const currentSchema = Yup.object().shape({
    "password": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "password_change": Yup.string()
      .required(translateObj("VALID.REQUIRED"))
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], translateObj("VALID.CONFPASSWORD")),
      })
  })

  const submitStep = (values: any) => {
    setLoading(true)

    values.id = user.id

    patchAuthUserPassword(values).then((data) => {
      setLoading(false)
    })
  }

  return (
    <div>
      <h1 className='mb-10'>{translateObj("MESSAGE.CHANGEPASSWORD")}</h1>
      <div className='card'>
        <div className='card-body'>
          <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitStep}>
            {({ setFieldValue }) => (
              <Form id='kt_change_passowrd_form'>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.PASSWORD")}</label>

                  <Field type="password" name='password' className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='password' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.CONFPASSWORD")}</label>

                  <Field type="password" name='password_change' className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='password_change' />
                  </div>
                </div>

                {loading ? (
                  <span className='text-center indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <div>
                    <button type='submit' className='btn btn-lg btn-dark text-white w-100'>
                      <i className="fa fa-check pe-3" />
                      {translateObj("BTN.SAVE")}
                    </button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}