import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { postCoupon, patchCouponId, getInfluencer } from '../../../services/Api';
import { convertDateUs, convertDateOnly } from '../../../utils';

export function ModalCoupon(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, setModified: Dispatch<SetStateAction<number>>, coupon: any }) {
  const { translateObj, show, setShow, setModified, coupon } = props;
  const [loading, setLoading] = useState(false)
  const [influencers, setInfluencers] = useState<any>()

  const handleClose = () => {
    setShow(false);
  }

  const initialValues = {
    "influencer_id": coupon.influencer ? coupon.influencer : "",
    "name": coupon.name ? coupon.name : "",
    "discount_amount": coupon.discount_amount ? coupon.discount_amount : "",
    "valid_until": coupon.valid_until ? convertDateOnly(coupon.valid_until) : "",
    "quantity": coupon.quantity ? coupon.quantity : "",
  }

  const currentSchema = Yup.object().shape({
    "name": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(80, translateObj("VALID.MAX.CARACTER", { "length": 80 }))
      .required(translateObj("VALID.REQUIRED")),
    "discount_amount": Yup.string()
      .required(translateObj("VALID.REQUIRED")),
    "valid_until": Yup.string()
      .required(translateObj("VALID.REQUIRED")),
  })

  const submitForm = (values: any) => {
    setLoading(true)

    !values.quantity && delete values.quantity

    if (coupon.id) {
      patchCouponId(coupon.id, values).then(() => {
        setModified(Math.random())
        handleClose()
        setLoading(false)
      })
    } else {
      postCoupon(values).then(() => {
        setModified(Math.random())
        handleClose()
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    getInfluencer().then(data => {
      data && setInfluencers(data)
    })
  }, [])

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitForm}>
        {({ setFieldValue }) => (
          <Form id='kt_team_form'>
            <Modal.Body>
              <div>
                <h2 className='fw-bolder text-dark'>{translateObj("MESSAGE.DATACOUPON")}</h2>

                <div className='text-gray-400 fw-bold fs-6 mb-15'>
                  {translateObj("MESSAGE.DATACOUPON.DESCRIPTION")}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.INFLUENCER")}</label>

                  <Field as='select' name='influencer_id' className='form-select form-select-lg form-select-solid'>
                    <option value={0}>{translateObj("MESSAGE.SELECT")}</option>
                    {influencers.map((item: any, key: number) => {
                      return (<option key={key} value={item.id}>{item.name}</option>)
                    })}
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='influencer_id' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.CAMPAIGN")}</label>

                  <Field name='name' className='form-control form-control-lg form-control-solid' disabled={coupon.id ? true : false} placeholder={'conecta10'} />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='name' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.DISCOUNT")}</label>

                  <Field type='number' name='discount_amount' min="0" max="100" className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='discount_amount' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.DATE.VALIDATE")}</label>

                  <Field type='date' min={convertDateUs(new Date().toLocaleDateString())} name='valid_until' className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='valid_until' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.COUNT")}</label>

                  <Field type='number' name='quantity' min="0" className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='quantity' />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <>
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </>
              ) : (
                <>
                  <button type='button' className='btn btn-default' onClick={handleClose}>
                    {translateObj("BTN.CANCEL")}
                  </button>
                  <button type='submit' className='btn btn-dark text-white'>
                    <i className='fa fa-check'></i>
                    <span className='mx-1'>{translateObj("BTN.SAVE")}</span>
                  </button>
                </>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}