import { useRef, useState } from 'react'

export default function ContentPix(props: { translateObj: any, data: any }) {
  let { translateObj, data } = props
  const [copy, setCopy] = useState(false)
  const input = useRef<any>()

  const copyCod = () => {
    setCopy(true)
    navigator.clipboard.writeText(input?.current.value)

    setTimeout(() => setCopy(false), 5000)
  }

  return (
    <>
      {data?.payload ? (
        <div className='w-100'>
          <h4>{translateObj("MESSAGE.PAYUSING.PIX")}</h4>

          <div className='mt-10 mb-5'>
            <b>{translateObj("MESSAGE.PIXCODE")}</b>
            <input type='text' ref={input} className='form-control form-control-solid' value={data?.payload} disabled={true} />
            {copy ? (
              <div className='alert alert-success mt-1'>{translateObj("MESSAGE.COPYCODE.DESCRIPTION")}</div>
            ) : (
              <button type='button' className='btn btn-secondary mt-1 w-100' onClick={() => copyCod()}>{translateObj("MESSAGE.COPYCODE")}</button>
            )}
          </div>
          <div>
            <b>QrCode</b>
            <br />
            <img src={`data:image/png;base64,${data?.encodedImage}`} alt='QrCode Pix' width={160} height={160} />
          </div>

          <div className='text-info mt-10'>
            <i className='fas fa-exclamation-circle ms-2 fs-7 mx-3'></i>
            {translateObj("MESSAGE.COMPENSATION.PIX")}
          </div>
        </div>
      ) : (
        <>
          {translateObj("MESSAGE.ERROR.PAYMENT")}
        </>
      )}
    </>
  )
}