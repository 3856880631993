/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import ReportCompany from "./permission/report/Company";
import ReportTeam from "./permission/report/Team";

export default function ReportCredit() {
  const intl = useIntl();
  const translateObj = (obj: any, varInfo?: any) => {
    return intl.formatMessage({ id: obj })
  }
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any

  return (
    <>
      <h1 className='mb-10'>{user?.type ? translateObj("MESSAGE.CONSULTPAYMENTS") : translateObj("MESSAGE.MYPAYMENTS")}</h1>
      <nav>
        <div className="nav nav-tabs" role="tablist">
          <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#nav-payment" type="button" role="tab" aria-selected="true">{translateObj("MESSAGE.PAYMENTS")}</button>
          <button className="nav-link" data-bs-toggle="tab" data-bs-target="#nav-historic-use" type="button" role="tab" aria-selected="false">{translateObj("MESSAGE.HISTORICUSE")}</button>
        </div>
      </nav>
      <div className="tab-content">
        <div className="tab-pane fade show active" id="nav-payment" role="tabpanel">
          <div className='card'>
            <div className='card-body'>
              {user && (user?.type ? <ReportTeam translateObj={translateObj} screen='payment' /> : <ReportCompany translateObj={translateObj} screen='payment' />)}
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="nav-historic-use" role="tabpanel">
          <div className='card'>
            <div className='card-body'>
              {user && (user?.type ? <ReportTeam translateObj={translateObj} screen='use' /> : <ReportCompany translateObj={translateObj} screen='use' />)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}