import { useEffect, useState } from "react"
import { getInfluencerDashboard } from "../../../services/Api"
import moment from 'moment';

const Influencer = (props: { translateObj: any }) => {
  let { translateObj } = props;
  const [loading, setLoading] = useState(false)
  const [coupons, setCoupons] = useState<any>()

  useEffect(() => {
    setLoading(true)
    getInfluencerDashboard().then(data => {
      if (data) {
        setCoupons(data)
        setLoading(false)
      }
    })
  }, [])

  return (
    <>
      <p>{translateObj("MESSAGE.TRACKCOUPONS")}</p>

      <div className="card p-10 mt-10">
        <h3 className='mb-5'>{coupons?.length + ' ' + translateObj("MESSAGE.RESULTS")}:</h3>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead className='table-light border-bottom text-uppercase fw-bolder bg-secondary text-center'>
              <tr>
                <td>{translateObj("MESSAGE.NAME")}</td>
                <td>{translateObj("MESSAGE.DISCOUNT")}</td>
                <td>{translateObj("MESSAGE.VALIDUNTIL")}</td>
                <td>{translateObj("MESSAGE.QUANTITYSALES")}</td>
              </tr>
            </thead>
            <tbody className='text-center'>
              {loading ? (
                <tr><td colSpan={4}>{translateObj("MESSAGE.LOADING") + ' '}</td></tr>
              ) : (
                <>
                  {coupons?.map((item: any) => {
                    return (
                      <tr>
                        <td>{item?.name}</td>
                        <td>{item?.discount_amount}</td>
                        <td>{moment(item?.valid_until).format('DD/MM/YYYY HH:mm')}</td>
                        <td>{item?.sells}</td>
                      </tr>
                    )
                  })}
                </>
              )
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export { Influencer }