import { useEffect, useState } from "react"
import { getAdminGeneratePdfPaymentId, getPaymentCredits, getPaymentUsageCredits } from "../../../../services/Api"
import { convertDate, formatReal } from "../../../../utils"

export function TableUse(props: { translateObj: any, showResult: boolean }) {
  const { translateObj, showResult } = props

  const [historyCredits, setHistoryCredits] = useState<any[]>([])

  const limit = 10
  const [pagination, setPagination] = useState(1)
  const [totalPages, setTotalPages] = useState<any[]>([])
  const [listHistoryCredits, setListHistoryCredits] = useState<any[]>([])

  const verifyPagination = (page: any) => {
    let resultPage = (page - 1) * limit
    let newOffSet = { from: resultPage, to: resultPage + limit }

    setListHistoryCredits(historyCredits.slice(newOffSet.from, newOffSet.to))
    setPagination(page)
  }

  const verifyTotalPages = (array: []) => {
    const total = []
    const length = array.length / limit

    for (let i = 0; i < length; i++) {
      total.push(i + 1)
    }

    setTotalPages(total)
  }

  const verifyListHistoryCredits = (array: []) => {
    setHistoryCredits(array)
    setListHistoryCredits(array.slice(0, 10))

    verifyTotalPages(array)
  }

  useEffect(() => {
    getPaymentUsageCredits().then((data) => {
      data && verifyListHistoryCredits(data)
    })
  }, [])

  return (
    <>
      {showResult && (<h3 className='mb-5'>{historyCredits?.length + ' ' + translateObj("MESSAGE.RESULTS")}:</h3>)}
      <div className="table-responsive">
        <table className="table table-hover">
          <thead className='table-light border-bottom text-uppercase fw-bolder bg-secondary text-center'>
            <tr>
              <td>{translateObj("MESSAGE.STATUS")}</td>
              <td>{translateObj("MESSAGE.TYPE")}</td>
              <td>{translateObj("MESSAGE.DATE")}</td>
              <td>{translateObj("MESSAGE.LINK")}</td>
            </tr>
          </thead>
          <tbody className='text-center'>
            {listHistoryCredits?.length ? (listHistoryCredits?.map(item => {
              return (
                <tr key={item.id} className='cursor-pointer border-bottom' onClick={() => {
                  if (item.order_id && item.status === "Paid") {
                    window.location.href = `/detailsProject/${item.order_id}`
                  } else if (item.status !== "Paid") {
                    window.location.href = `/creditBuy/${item.id}/${item.amount}`
                  }
                }}>
                  <td>
                    <div className="d-flex justify-content-center">
                      <div>
                        <i className='fa fa-check text-success' />
                      </div>
                      <div className="ms-3" style={{ minWidth: '70px' }}>
                        {item.status === "Paid" ? (
                          <span className="badge badge-default text-success">{translateObj("MESSAGE.USED")}</span>
                        ) : (
                          <span className="badge badge-warning">{translateObj("MESSAGE.PENDING")}</span>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>{item.is_credit ? "Express" : "Premium"}</td>
                  <td>{convertDate(item.updated_at)}</td>
                  <td className='text-center'>
                    {item.status === "Paid" ? (<i className='fa fa-check text-primary' />) : (<i className='fa fa-search text-primary' />)}
                  </td>
                </tr>
              )
            })) : (
              <tr>
                <td colSpan={7}>{translateObj("MESSAGE.NOTRESULT")}</td>
              </tr>
            )}
          </tbody>
        </table>

        {totalPages.length > 0 && (
          <div className="float-end my-5 text-center">
            {translateObj("MESSAGE.PAGINATION")}
            <div className="d-flex pt-3">
              {totalPages.map(item => {
                return (
                  <button key={item} type="button" className={`btn btn-sm text-primary p-0 m-0 px-1 border mx-1 ${pagination === item && 'border-primary border-2'}`} onClick={() => verifyPagination(item)}>{item}</button>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export function TablePayment(props: { translateObj: any, showResult: boolean }) {
  const { translateObj, showResult } = props

  const [historyCredits, setHistoryCredits] = useState<any[]>([])

  const limit = 10
  const [pagination, setPagination] = useState(1)
  const [totalPages, setTotalPages] = useState<any[]>([])
  const [listHistoryCredits, setListHistoryCredits] = useState<any[]>([])

  const verifyPagination = (page: any) => {
    let resultPage = (page - 1) * limit
    let newOffSet = { from: resultPage, to: resultPage + limit }

    setListHistoryCredits(historyCredits.slice(newOffSet.from, newOffSet.to))
    setPagination(page)
  }

  const verifyTotalPages = (array: []) => {
    const total = []
    const length = array.length / limit

    for (let i = 0; i < length; i++) {
      total.push(i + 1)
    }

    setTotalPages(total)
  }

  const verifyListHistoryCredits = (array: []) => {
    const arrayDeposit: any = array.filter((item: any) => item.type === 'Deposit');

    setHistoryCredits(arrayDeposit)
    setListHistoryCredits(arrayDeposit.slice(0, 10))

    verifyTotalPages(arrayDeposit)
  }

  const validItem = (obj: any, search = 0) => {
    if ((obj.order_id && obj?.payments[0]?.paid_at === "Paid") || search) {
      window.location.href = `/detailsProject/${obj.order_id}`
    } else if (obj.status !== "Paid" && !search) {
      window.location.href = `/creditBuy/${obj.id}/${obj.amount}`
    }
  }

  const downloadTermPayment = async (idPayment: any) => {
    await getAdminGeneratePdfPaymentId(idPayment).then(term => {
      const windowPrint = window.open('CELER');
      windowPrint?.document.write(term);
      windowPrint?.window.print();
      windowPrint?.window.close();
      return false;
    });
  }

  useEffect(() => {
    getPaymentCredits().then((data) => {
      data && verifyListHistoryCredits(data)
    })
  }, [])

  return (
    <>
      {showResult && (<h3 className='mb-5'>{historyCredits?.length + ' ' + translateObj("MESSAGE.RESULTS")}:</h3>)}
      <div className="table-responsive">
        <table className="table table-hover">
          <thead className='table-light border-bottom text-uppercase fw-bolder bg-secondary text-center'>
            <tr>
              <td>{translateObj("MESSAGE.STATUS")}</td>
              <td>{translateObj("MESSAGE.TYPE")}</td>
              <td>{translateObj("MESSAGE.QUANTITY")}</td>
              <td>{translateObj("MESSAGE.VALUE")}</td>
              <td>{translateObj("MESSAGE.DATE.ORDER")}</td>
              <td>{translateObj("MESSAGE.DATE.PAYMENT")}</td>
              <td>Termo</td>
              <td>{translateObj("MESSAGE.ACTIONS")}</td>
            </tr>
          </thead>
          <tbody className='text-center'>
            {listHistoryCredits?.length ? (listHistoryCredits?.map(item => {
              return (
                <tr key={item.id} className='cursor-pointer border-bottom' onClick={() => validItem(item)}>
                  <td>
                    {item?.payments[0]?.paid_at ? (
                      <span className="badge badge-success">{translateObj("MESSAGE.PAID")}</span>
                    ) : (
                      <span className="badge badge-warning">{translateObj("MESSAGE.PENDING")}</span>
                    )}
                  </td>
                  <td>{item.is_credit ? "Express" : "Premium"}</td>
                  <td className="fs-4">
                    <span className="text-success fw-bold">{item.amount}</span>
                  </td>
                  <td>
                    {item.price ? formatReal(item.price) : '--'}
                    {item.cupon_id && <span className="badge badge-info p-1 m-0 ms-3">{translateObj("MESSAGE.COUPON")}</span>}
                  </td>
                  <td>{convertDate(item.updated_at)}</td>
                  <td>{item?.payments[0]?.paid_at ? convertDate(item?.payments[0]?.paid_at + "T") : '--'}</td>
                  <td>
                    <button className='btn btn-dark btn-sm px-3' title="Termo" onClick={() => downloadTermPayment(item?.id)}>
                      <i className="fa fa-file" />
                    </button>
                  </td>
                  <td>
                    {item?.order_id ? (
                      <button className="btn btn-primary btn-sm px-3" onClick={() => validItem(item, 1)}>
                        <i className="fa fa-search" />
                      </button>
                    ) : '--'}
                  </td>
                </tr>
              )
            })) : (
              <tr>
                <td colSpan={8}>{translateObj("MESSAGE.NOTRESULT")}</td>
              </tr>
            )}
          </tbody>
        </table>

        {totalPages.length > 0 && (
          <div className="float-end my-5 text-center">
            {translateObj("MESSAGE.PAGINATION")}
            <div className="d-flex pt-3">
              {totalPages.map(item => {
                return (
                  <button key={item} type="button" className={`btn btn-sm text-primary p-0 m-0 px-1 border mx-1 ${pagination === item && 'border-primary border-2'}`} onClick={() => verifyPagination(item)}>{item}</button>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default function ReportCompany(props: { translateObj: any, screen: string }) {
  const { translateObj, screen } = props

  return (
    <>
      {screen === 'payment' ? <TablePayment translateObj={translateObj} showResult={true} /> : <TableUse translateObj={translateObj} showResult={true} />}
    </>
  )
}
