import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction } from 'react';

export function ModalHelp(props: { show: any, setShow: Dispatch<SetStateAction<boolean>> }) {
  const { show, setShow } = props;

  const handleClose = () => {
    setShow(false);
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div className='fs-5'>
          <h1>Ajudas em telas</h1>
          <div>Abaixo estão os links de ajuda de tela:</div>

          <ul className='d-grid gap-5 mt-10'>
            <a href='media/tour/create.mp4' target='_blank'>
              <li>Criação de projeto</li>
            </a>
            <a href='media/tour/report.mp4' target='_blank'>
              <li>Acompanhamento de projetos</li>
            </a>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-default' onClick={handleClose}>
          Cancelar
        </button>
      </Modal.Footer>
    </Modal>
  )
}