import { KTSVG } from '../../../_metronic/helpers'
import { useEffect, useState } from 'react';
import { getAdminCompanyId, getAdminGeneratePdfCompanyId } from '../../services/Api';
import { maskPhone } from '../../utils';
import moment from 'moment';
import { infoTitle } from '../detailsProject/DetailsProject';
import { useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { ModalExecutive } from './components/ModalExecutive';
import { useIntl } from 'react-intl';
import { ModalBalance } from './components/ModalBalance';
import { FormNfe } from './components/FormNfe';
import { ModalCompany } from './components/ModalCompany';
import { ModalBalanceEdit } from './components/ModalBalanceEdit';
import { ModalUser } from './components/ModalUser';

const DetailCompanyPermission = (props: { translateObj: any }) => {
  let { translateObj } = props;

  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const { id } = useParams<any>();
  const [modified, setModified] = useState(1)
  const [modalBalance, setModalBalance] = useState(false);
  const [modalBalanceEdit, setModalBalanceEdit] = useState(false);
  const [modalExecutive, setModalExecutive] = useState(false);
  const [modalCompany, setModalCompany] = useState(false);
  const [company, setCompany] = useState<any>()
  const [verifyNfe, setVerifyNfe] = useState<any>(false)
  const [screenEdit, setScreenEdit] = useState('')
  const [valueEdit, setValueEdit] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [dataUser, setDataUser] = useState({})

  const downloadTermCompany = async (idParam = null) => {
    await getAdminGeneratePdfCompanyId(idParam ? idParam : id).then(term => {
      const windowPrint = window.open('CELER');
      windowPrint?.document.write(term);
      windowPrint?.window.print();
      windowPrint?.window.close();
    });
  }

  const onHandleEditAmount = (type: any) => {
    setScreenEdit(type)
    setValueEdit(type === "express" ? company?.express : company?.images)
    setModalBalanceEdit(true)
  }

  const onHandleEditUser = (data: any) => {
    setDataUser(data)
    setModalShow(true)
  }

  useEffect(() => {
    if (id && id !== "detail") {
      getAdminCompanyId(id).then((data) => {
        const company = data?.company
        const executive = data?.executive ? data.executive : {}
        const dataInfo = { ...company, executive: executive }
        setCompany(dataInfo)
      })
    } else {
      user?.company_id && getAdminCompanyId(user?.company_id).then((data) => {
        const company = data.company
        const executive = data?.executive ? data.executive : {}
        const dataInfo = { ...company, executive: executive }
        setCompany(dataInfo)
        setVerifyNfe(!company?.is_same_tax)
      })
    }
  }, [id, modified])

  return (
    <>
      {(user.type === "Squad" || user.type === "Squadinator") && (
        <>
          <ModalUser translateObj={translateObj} show={modalShow} setShow={setModalShow} setModified={setModified} companyId={id} dataUser={dataUser} />
          <ModalBalance translateObj={translateObj} show={modalBalance} setShow={setModalBalance} setModified={setModified} companyId={id} />
          <ModalBalanceEdit translateObj={translateObj} show={modalBalanceEdit} setShow={setModalBalanceEdit} setModified={setModified} companyId={id} screen={screenEdit} valueEdit={valueEdit} />
          <ModalExecutive translateObj={translateObj} show={modalExecutive} setShow={setModalExecutive} setModified={setModified} companyId={id} />
          <ModalCompany translateObj={translateObj} show={modalCompany} setShow={setModalCompany} setModified={setModified} companyId={id} company={company} />
        </>
      )}
      <div className='mb-15'>
        <a href="/company/report">
          <KTSVG
            path='/media/icons/duotune/arrows/arr063.svg'
            className='svg-icon-4 me-1'
          />
          {translateObj("MESSAGE.PREVIOUSPAGE")}
        </a>
      </div>
      {company?.id && (
        <>
          <h1 className='mb-5'>
            {translateObj("MESSAGE.DETAILSCLIENT")}
          </h1>

          <div className='card'>
            <div className='card-body'>
              <div className='d-flex justify-content-between'>
                <h3 className='w-50 text-primary border-bottom pb-5'>{translateObj("MESSAGE.DATA")}</h3>
                {(user.type === "Squad" || user.type === "Squadinator") && (
                  <div className='d-flex gap-3'>
                      <button className='btn btn-dark' onClick={() => setModalBalance(true)}>{translateObj("MESSAGE.ADDVALUES")}</button>
                      <button className='btn btn-dark' onClick={() => setModalExecutive(true)}>{translateObj("MESSAGE.ATRIBUTEEXECUTIVE")}</button>
                      <button className='btn btn-dark' onClick={() => setModalCompany(true)}>{translateObj("MESSAGE.ALTERDATA")}</button>
                    </div>
                )}
                
                {!user?.type && (
                  <button className='btn btn-dark' onClick={() => downloadTermCompany(company?.id)}>Termo Cliente</button>
                )}
              </div>

              <div className='mb-15'>
                <div className='row'>
                  <div className='col-md-3'>
                    <div style={infoTitle}>{translateObj("MESSAGE.DOCUMENT")}</div>
                    {company?.document}
                  </div>
                  <div className='col-md-9'>
                    <div style={infoTitle}>{translateObj("MESSAGE.NAME")}</div>
                    {company?.name}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div style={infoTitle}>{translateObj("MESSAGE.LOCATION")}</div>
                    {`${company?.zip_code} - ${company?.address}, ${company?.address_number}, ${company?.city}/${company?.state}`}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div style={infoTitle}>{translateObj("MESSAGE.CREATED_AT")}</div>
                    {moment(company?.created_at).format('DD/MM/YYYY HH:mm:ss')}
                  </div>
                  <div className='col-md-6'>
                    <div style={infoTitle}>{translateObj("MESSAGE.UPDATED_AT")}</div>
                    {moment(company?.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                  </div>
                </div>
              </div>

              <div className='mb-15'>
                <div className='d-flex justify-content-between mb-5'>
                  <h3 className='text-primary border-bottom'>{translateObj("MESSAGE.CONTACTS")}</h3>
                  {(user.type === "Squad" || user.type === "Squadinator") && (
                    <button className="btn btn-dark text-white" onClick={() => onHandleEditUser({})}>
                      {translateObj("MESSAGE.ADDUSER")}
                    </button>
                  )}
                </div>

                <table className="table table-hover">
                  <thead className='table-secondary border-bottom text-uppercase fw-bolder bg-secondary text-center'>
                    <tr>
                      <td>{translateObj("MESSAGE.NAME")}</td>
                      <td>{translateObj("MESSAGE.EMAIL")}</td>
                      <td>{translateObj("MESSAGE.PHONE")}</td>
                      <td>{translateObj("MESSAGE.ADMIN")}</td>
                      <td>#</td>
                    </tr>
                  </thead>
                  <tbody className='text-center'>
                    {company?.consumer?.length ? (company?.consumer?.map((item: any, key: number) => {
                      return (
                        <tr key={key} className='cursor-pointer border-bottom'>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{maskPhone(item.phone)}</td>
                          <td>{item.is_admin ? translateObj("MESSAGE.YES") : translateObj("MESSAGE.NO")}</td>
                          <td><i className='fa fa-edit p-3' onClick={(e) => onHandleEditUser(item)}></i></td>
                        </tr>
                      )
                    })) : (
                      <tr>
                        <td colSpan={4}>{translateObj("MESSAGE.NOTRESULT")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className='mb-15'>
                <div className='row'>
                  <div className='col-4'>
                    <h3 className='text-primary border-bottom pb-5'>{translateObj("MESSAGE.TOTALIMAGES")}</h3>

                    <div className='row'>
                      <div className='col-md-12 d-flex'>
                        <h4 className='mt-3'>{company?.images}</h4>
                        <i className='fa fa-edit p-3 cursor-pointer' onClick={() => onHandleEditAmount('image')}></i>
                      </div>
                    </div>
                  </div>
                  <div className='col-4'>
                    <h3 className='text-primary border-bottom pb-5'>{translateObj("MESSAGE.TOTALIMAGESEXPRESS")}</h3>

                    <div className='row'>
                      <div className='col-md-12 d-flex'>
                        <h4 className='mt-3'>{company?.images_express}</h4>
                        <i className='fa fa-edit p-3 cursor-pointer' onClick={() => onHandleEditAmount('express')}></i>
                      </div>
                    </div>
                  </div>
                  {(user.type === "Squad" || user.type === "Squadinator") && (
                    <div className='col-4'>
                      <h3 className='text-primary border-bottom pb-5'>{translateObj("MESSAGE.EXECUTIVE")}</h3>

                      <div className='row'>
                        <div className='col-md-12'>
                          <h4 className='mt-3'>{company?.executive?.name ? company.executive.name : translateObj("MESSAGE.NOTDATA")}</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>


              {!user?.type ? (
                <div>
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="switchNfe" checked={verifyNfe} onClick={() => setVerifyNfe(!verifyNfe)} />
                    <label className="form-check-label fw-bold" htmlFor="switchNfe">{translateObj("MESSAGE.DIFFDATA")}</label>
                  </div>

                  {verifyNfe && (
                    <div className='mb-15'>
                      <h3 className='text-primary border-bottom pb-5 mt-10'>{translateObj("MESSAGE.DATASNFE")}</h3>

                      <FormNfe translateObj={translateObj} company={company} setModified={setModified} />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <h3 className='text-primary border-bottom pb-5 mt-10'>{translateObj("MESSAGE.DATASNFE")}</h3>

                  {company?.tax_document ? (
                    <div>
                      <div className='row'>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj("MESSAGE.DOCUMENT")}</div>
                          {company?.tax_document}
                        </div>
                        <div className='col-md-9'>
                          <div style={infoTitle}>{translateObj("MESSAGE.NAME")}</div>
                          {company?.tax_name}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div style={infoTitle}>{translateObj("MESSAGE.EMAILNFE")}</div>
                          {company?.tax_email}
                        </div>
                        <div className='col-md-6'>
                          <div style={infoTitle}>{translateObj("MESSAGE.STATEREGISTRATION")}</div>
                          {company?.tax_state_reg ? company?.tax_state_reg : 'Não informado'}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div style={infoTitle}>{translateObj("MESSAGE.LOCATION")}</div>
                          {`${company?.tax_zip_code} - ${company?.tax_address}, ${company?.tax_number}, ${company?.tax_city}/${company?.tax_state}`}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>{translateObj("MESSAGE.NOTDATA")}</div>
                  )}
                </div>
              )}

            </div>
          </div>
        </>
      )}
    </>
  )
}

export default function DetailCompany() {
  const intl = useIntl();
  const translateObj = (obj: any, varInfo?: any) => {
    if (varInfo) {
      return intl.formatMessage({ id: obj }, varInfo)
    }

    return intl.formatMessage({ id: obj })
  }

  return (
    <>
      <DetailCompanyPermission translateObj={translateObj} />
    </>
  )
}