import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useState } from 'react';
import { putAdminOrderPhotosReject } from '../../../services/Api';

export function ModalReproachAmbience(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, ambience: any, setModified: Dispatch<SetStateAction<number>> }) {
  const { translateObj, show, setShow, ambience, setModified } = props;
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState<any>({})

  const handleClose = () => {
    setShow(!show);
  }

  const initialValues = {
    description: '',
  }

  const currentSchema = Yup.object().shape({
    "description": Yup.string().required(translateObj("VALID.REQUIRED")),
  })

  const submitForm = (values: any) => {

    const form = new FormData();
    form.append('order_id', ambience.order_id);
    form.append('enviroment_id', ambience.id);
    form.append('name', ambience.name);
    form.append('description', values.description);
    form.append('file', image);

    setLoading(true)

    putAdminOrderPhotosReject(form).then(response => {
      setModified(Math.random())
      setLoading(false)
      handleClose()
    })
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitForm}>
        {({ values, setFieldValue }) => (
          <Form id='kt_reproach_form'>
            <Modal.Header>
              <Modal.Title className='text-danger'>
                {translateObj("MESSAGE.REPROVEDPHOTOS")}

                <div className='fs-6 text-dark mt-2'>
                  <b>{translateObj("MESSAGE.AMBIENCE")}:</b>
                  <span className='mx-1'>{ambience?.name}</span>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {translateObj("MESSAGE.REQUESTNEWPHOTOS")}
                <br />
                {translateObj("MESSAGE.REQUESTNEWPHOTOS.DESCRIPTION")}
              </p>

              <div className='fv-row mb-7'>
                <label className='fs-6 fw-bold form-label'>{translateObj("MESSAGE.SCRIBBLEIMAGE")}</label>

                <Field type='file' accept="image/*,application/pdf" name='file' className='form-control form-control-lg form-control-solid' onChange={(e: any) => setImage(e.target.files[0])} />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='file' />
                </div>
              </div>

              <div className='fv-row mb-7'>
                <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.REASONREJECTION")}</label>

                <Field as='textarea' name='description' className='form-control form-control-lg form-control-solid' />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='description' />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <>
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </>
              ) : (
                <>
                  <button type='button' className='btn btn-default' onClick={handleClose}>
                    {translateObj("BTN.CANCEL")}
                  </button>
                  <button type='submit' className='btn btn-danger'>
                    <i className='fa fa-times'></i>
                    <span className='mx-2'>{translateObj("BTN.REPROVED")}</span>
                  </button>
                </>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}