import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useState } from 'react';
import { postAdminAiNewVariation, putAdminOrderPhotoChoseId } from '../../../services/Api';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { ModalConfirm } from './ModalConfirm';

export function ModalImage(props: { translateObj: any, ambienceId: string, show: any, setShow: Dispatch<SetStateAction<boolean>>, image: any, setModified: Dispatch<SetStateAction<any>>, photos: any, filterPhotosAI: any }) {
  const { translateObj, ambienceId, show, setShow, image, setModified, photos, filterPhotosAI } = props;
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const [typeConfirm, setTypeConfirm] = useState('');
  const [modalConfirm, setModalConfirm] = useState(false);
  const [itemConfirm, setItemConfirm] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const photosView = filterPhotosAI.length ? filterPhotosAI : photos;
  const validatePhoto = photosView.filter((item: any) => ((item.is_ai_render && item.is_celer) || item.is_chosen)).length > 0;
  const imageAI = photosView.filter((item: any) => item.is_ai_render).length > 0;

  const handleClose = () => {
    setShow(!show)
  }

  const confirmModal = (item: any, type: string) => {
    setModalConfirm(true)
    setItemConfirm(item)
    setTypeConfirm(type)
  }

  const chosenImage = (itemObj: any) => {
    if (!loading) {
      setLoading(true)
      putAdminOrderPhotoChoseId(itemObj?.id).then(data => {
        setLoading(false)
        setModified(Math.random())
        setShow(!show)
      })
    }
  }

  const generateNewImageAI = () => {
    postAdminAiNewVariation({ enviroment_id: ambienceId }).then(() => {
      setModified(Math.random())
    })
  }

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Body>
        <div>
          <h5>{translateObj("MESSAGE.VIEWIMAGE")}:</h5>

          <button type='button' className='btn p-0 m-0 position-absolute' style={{ top: 20, right: '5%' }} onClick={() => setShow(false)}>
            <i className='fa fa-times'></i>
          </button>
        </div>

        <div className='w-100'>
          <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
            <div className="carousel-indicators">
              {photosView.map((itemPathClick: any, keyClick: number) => {
                return (<button key={keyClick} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={keyClick} className={`${itemPathClick.path === image.path && 'active'}`} aria-current="true" aria-label={`Photo ${keyClick++}`}></button>)
              })}
            </div>
            <div className="carousel-inner">
              {photosView.map((itemPathClick: any, keyPathClick: number) => {
                return (
                  <div key={keyPathClick} id={`image-${itemPathClick.id}`} className={`carousel-item ${itemPathClick.path === image.path && 'active'}`}>
                    <div className="position-relative">
                      {!itemPathClick?.is_valid && (
                        <div className="position-absolute w-100 text-center">
                          <div className="fs-3 bg-danger py-3 text-white">
                            Imagem reprovada
                          </div>
                        </div>
                      )}
                      <img src={itemPathClick.path} className="d-block w-100 heightMin" alt="..." />
                    </div>

                    <div className="carousel-caption d-none d-md-block">
                      <div className='d-flex gap-5 justify-content-center'>
                        <a href={itemPathClick?.path} className='btn btn-primary' target='_blank' rel="noreferrer" download>
                          <i className='fa fa-download' /> {translateObj("BTN.DOWNLOADIMAGE")}
                        </a>

                        {((user?.type === 'Squad' || user?.type === 'Squadinator') && !itemPathClick.is_approved && itemPathClick.is_ai_render && validatePhoto) && (
                          <button type="button" className="btn btn-success" onClick={() => confirmModal(itemPathClick, 'approve')}>
                            <i className="fa fa-check me-2"></i>
                            {translateObj("MESSAGE.APPROVEIMAGE")}
                          </button>
                        )}

                        {(!itemPathClick.is_celer && itemPathClick.is_valid && !itemPathClick.is_ai_render) && (
                          <>
                            {itemPathClick?.is_chosen ? (
                              <button type="button" className="btn btn-default bg-white float-end">
                                <i className='fa fa-check text-success' /> {translateObj("MESSAGE.IMAGESELECTED")}
                              </button>
                            ) : (
                                (user?.type === 'Squad' || user?.type === 'Squadinator') && (<button type="button" className="btn btn-primary float-end" onClick={() => chosenImage(itemPathClick)}>
                                <i className='fa fa-star' /> {!loading ? translateObj("MESSAGE.SELECTIMAGE") : translateObj("MESSAGE.LOADING")}
                              </button>)
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <i className="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
          </div>

          {(imageAI && ambienceId) && (
            <button type='button' className='btn btn-warning mt-5' onClick={() => generateNewImageAI()}>
              <i className="fa fa-plus me-2" aria-hidden="true"></i>
              {translateObj("MESSAGE.GENERATENEWIMAGEAI")}
            </button>
          )}
        </div>

        <ModalConfirm translateObj={translateObj} show={modalConfirm} setShow={setModalConfirm} item={itemConfirm} type={typeConfirm} setModified={setModified} />
      </Modal.Body>
    </Modal>
  )
}