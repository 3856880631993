/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {RootState} from '../../../../setup'
import * as auth from "../../../../app/modules/auth/redux/AuthRedux";
import {useDispatch} from 'react-redux'
import { useIntl } from 'react-intl';

const HeaderUserMenu: FC = () => {
  const intl = useIntl();
  const translateObj = (obj: any) => {
    return intl.formatMessage({ id: obj })
  }
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }

  const numberOfLines: any = {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "200px",
    cursor: "pointer",
  };

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3 mx-5'>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.firstname}
            </div>
            <Link to='/changePassword' className={`fw-bold text-muted text-hover-primary fs-7`} style={numberOfLines} title={user.email}>
              {user.email}
            </Link>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {user.is_admin && (
        <div className='menu-item px-5 my-1'>
          <Link to='/company/users' className='menu-link px-5'>
            {translateObj("MESSAGE.USERS")}
          </Link>
        </div>
      )}

      <div className='menu-item px-5 my-1'>
        <Link to='/changePassword' className='menu-link px-5'>
          {translateObj("MESSAGE.CHANGEPASSWORD")}
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {translateObj("MESSAGE.LOGOUT")}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
