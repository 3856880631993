import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getExecutive, patchCompanyExecutive } from '../../../services/Api';

export function ModalExecutive(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, setModified: Dispatch<SetStateAction<number>>, companyId: any }) {
  const { translateObj, show, setShow, setModified, companyId } = props;
  const [loading, setLoading] = useState(false)
  const [executive, setExecutive] = useState<any[]>([])

  const handleClose = () => {
    setShow(false);
  }

  const initialValues = {
    "executive": "",
    "id": companyId,
  }

  const currentSchema = Yup.object().shape({
    "executive": Yup.string().required(translateObj("VALID.REQUIRED")),
  })

  const submitForm = (values: any) => {
    setLoading(true)

    patchCompanyExecutive(values).then(() => {
      setModified(Math.random())
      handleClose()
      setLoading(false)
    })
  }

  useEffect(() => {
    getExecutive().then((data: any) => {
      data && setExecutive(data)
    })
  }, [])

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitForm}>
        {({ setFieldValue }) => (
          <Form id='kt_user_form'>
            <Modal.Body>
              <div>
                <h2 className='fw-bolder text-dark'>{translateObj("MESSAGE.CHOOSEEXECUTIVE")}</h2>

                <div className='fv-row my-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.SELECTEXECUTIVE")}</label>

                  <Field as='select' name='executive' className='form-select form-select-lg form-select-solid'>
                    <option value={0}>{translateObj("MESSAGE.SELECTEXECUTIVE")}</option>
                    {executive.map((item, key) => {
                      return (<option key={key} value={item.id}>{item.name}</option>)
                    })}
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='executive' />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <>
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </>
              ) : (
                <>
                  <button type='button' className='btn btn-default' onClick={handleClose}>
                    {translateObj("BTN.CANCEL")}
                  </button>
                  <button type='submit' className='btn btn-dark text-white'>
                    <i className='fa fa-check'></i>
                    <span className='mx-1'>{translateObj("BTN.SAVE")}</span>
                  </button>
                </>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}