import { Field, ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { maskCepPt, maskMoney } from '../../../../../../utils'
import { useEffect, useState } from 'react';
import { ModalImage } from '../../ModalImage';


export default function Step1(props: { translateObj: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void, loading: boolean, typeSelect: any, user: any }) {
  const { translateObj, setFieldValue, loading, typeSelect, user } = props

  const listDecorations = typeSelect?.celer ? [
    { value: "", name: translateObj("MESSAGE.SELECT") },
    { value: "Empty", name: translateObj("MESSAGE.EMPTY") },
    { value: "Classic", name: translateObj("MESSAGE.CLASSIC") },
    { value: "Sophisticated", name: translateObj("MESSAGE.SOPHISTICATED") },
    { value: "Young", name: translateObj("MESSAGE.YOUNG") },
  ] : [
    { value: "", name: translateObj("MESSAGE.SELECT") },
    { value: "Modern", name: translateObj("MESSAGE.MODERN") },
    { value: "Scandinavian", name: translateObj("MESSAGE.SCANDINAVIAN") },
    { value: "Industrial", name: translateObj("MESSAGE.INDUSTRIAL") },
    { value: "Farmhouse", name: translateObj("MESSAGE.FARMHOUSE") },
    { value: "Luxury", name: translateObj("MESSAGE.LUXURY") },
  ]

  return (
    <>
      <div className='w-100'>
        <h2 className='fw-bolder text-dark-era'>{translateObj("MESSAGE.IMMOBILE.TITLE")}</h2>

        <div className='text-gray-400 fw-bold fs-6 mb-15'>
          {translateObj("MESSAGE.IMMOBILE.DATA")}
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.IDENTIFICATION")}</label>

          <Field name='name' className='form-control form-control-lg form-control-solid' />
          <div className='text-danger mt-2'>
            <ErrorMessage name='name' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.CATEGORY")}</label>

          <Field as='select' name='category' className='form-select form-select-lg form-select-solid'>
            <option value=''>{translateObj("MESSAGE.SELECT")}</option>
            <option value='Rent'>{translateObj("MESSAGE.RENT")}</option>
            <option value='Sell'>{translateObj("MESSAGE.SALE")}</option>
            {/* <option value='Trespasse'>Trespasse</option> */}
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='category' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.VALUERENTSALE")}</label>
          <Field name='price' className='form-control form-control-lg form-control-solid' maxLength={13} onKeyUp={(e: any) => setFieldValue('price', maskMoney(e.target.value))} />
          <div className='text-danger mt-2'>
            <ErrorMessage name='price' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.TYPE")}</label>

          <Field as='select' name='type' className='form-select form-select-lg form-select-solid'>
            <option value=''>{translateObj("MESSAGE.SELECT")}</option>
            <option value='Residential'>{translateObj("MESSAGE.RESIDENTIAL")}</option>
            <option value='Commercial'>{translateObj("MESSAGE.COMMERCIAL")}</option>
            <option value='Others'>{translateObj("MESSAGE.OTHERS")}</option>
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='type' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.PROPERTYSTANDARD")}</label>

          <Field as="select" name='product' id='product' className='form-select form-select-lg form-select-solid'>
            <option value="">{translateObj("MESSAGE.SELECT")}</option>
            <option value='High'>{translateObj("MESSAGE.HIGH")}</option>
            <option value='Average'>{translateObj("MESSAGE.AVERAGE")}</option>
            <option value='Economic'>{translateObj("MESSAGE.ECONOMIC")}</option>
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='product' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.DECORATIONSTYLE")}</label>

          <Field as='select' name='decoration' className='form-select form-select-lg form-select-solid'>
            {listDecorations.map((item, key) => <option key={key} value={item.value}>{item.name}</option>)}
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='decoration' />
          </div>
        </div>

        <div className='fv-row mb-7'>
          <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.ZIPCODE")}</label>

          <Field name='zip_code' className='form-control form-control-lg form-control-solid' maxLength={8} onKeyUp={(e: any) => setFieldValue('zip_code', maskCepPt(e.target.value))} />
          <div className='text-danger mt-2'>
            <ErrorMessage name='zip_code' />
          </div>
        </div>

        <div className='d-flex flex-stack pt-15'>
          <div className='mr-2'></div>
          <div>
            {loading ? (
              <span className='text-center indicator-progress' style={{ display: 'block' }}>
                {translateObj("MESSAGE.LOADING") + ' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <button type='submit' className='btn btn-lg btn-dark text-white me-3'>
                <span className='indicator-label'>
                  {translateObj("BTN.NEXT")}
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}