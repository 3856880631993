import { Modal } from 'react-bootstrap-v5'
import { PDFViewer, PDFDownloadLink, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../../setup';
import { Dispatch, SetStateAction, useState } from 'react';

const styles = StyleSheet.create({
  page: {
    padding: 0,
    margin: 0
  },
  viewTitle: {
    textAlign: 'center',
    margin: '30px 0px'
  },
  fontSizeDefault: {
    fontSize: 12,
    marginBottom: 10,
    padding: '10px 50px'
  }
});

export const DocumentPdf = (props: { data: any, user: any }) => {
  const { data, user } = props

  return (
    <>
      {(data && user) && (
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.viewTitle}>
              <Text style={{ padding: '10px 50px', fontSize: 14 }}>CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE PROJETOS DE DESIGN DE INTERIOR PARA TRATAMENTO DE IMAGENS DE IMÓVEIS</Text>
            </View>
            <View style={styles.fontSizeDefault}>
              <Text>
                •	QUADRO RESUMO
              </Text>
            </View>
            <View style={styles.fontSizeDefault}>
              <Text>
                CONTRATANTE: {user?.company?.name}, e-mail: {user?.email}, doravante denominada apenas (“CONTRATANTE”)
              </Text>
            </View>
            <View style={styles.fontSizeDefault}>
              <Text>
                CONTRATADA: CELER LTDA, inscrita no CNPJ sob o nº 51.684.950/0001-13 com sede a Rua dos Inconfidentes nº 911, sala 1901-A, bairro Savassi, CEP 30140-128 cidade de Belo Horizonte/ MG, doravante denominada apenas (“CONTRATADA”)
              </Text>
            </View>
            <View style={styles.fontSizeDefault}>
              {data?.type === 1 ?
                <Text>QUANTIDADE: {data?.price} ({data?.amount}) ambientes, em um imóvel.</Text>
                :
                <Text>QUANTIDADE: {data?.price} ({data?.amount}) imóveis, com direito a 3 (três) fotografias por imóvel.</Text>
              }
            </View>
            <View style={styles.fontSizeDefault}>
              <Text>
                VALOR: valor e forma de pagamento estão discriminados no site da CONTRATADA.
              </Text>
            </View>
            <View style={styles.fontSizeDefault}>
              <Text>
                MULTA E JUROS: O atraso ou não pagamento dos valores nas datas acertadas serão acrescidos de multa de 2% e juros de mora de 1% ao mês. Se o inadimplemento permanecer por prazo superior a 30 (trinta) dias, serão conferido o direito de CONTRATADA suspender a execução dos serviços até a purgação da mora.
              </Text>
            </View>
            <View style={styles.fontSizeDefault}>
              <Text>
                PRAZO DE VALIDADE: 90 (noventa) dias.
              </Text>
            </View>
            <View style={styles.fontSizeDefault}>
              <Text>
                PRAZO ENTREGA DOS SERVIÇOS: 15 (quinze) dias após a validação das fotos.
              </Text>
            </View>
            <View style={styles.fontSizeDefault}>
              <Text>
                OBSERVAÇÕES: Este QUADRO RESUMO integra as condições gerais do “Contrato de Prestação de Serviços de Projetos de Design de Interior para tratamento de Imagens de Imóveis”, e sobre o qual, as Partes acima identificadas, reconhecem e aderem integralmente os seus termos.
              </Text>
            </View>
            <View style={styles.fontSizeDefault}>
              <Text>
                Belo Horizonte, {moment(new Date()).format('DD/MM/YYYY')}
              </Text>
            </View>
          </Page>
        </Document>)}
    </>
  )
}

const ModalTermsCredit = (props: { translateObj: any, data: any, user: any, show: any, setShow: Dispatch<SetStateAction<boolean>> }) => {
  const { translateObj, data, user, show, setShow } = props

  const handleClose = () => {
    setShow(false);
  }

  return (
    <Modal size='lg' show={show} onHide={handleClose}>
      <Modal.Body>
        <div className='pt-5'>
          <button type='button' className='btn p-0 m-0 position-absolute' style={{ top: '3%', right: '3%', padding: 5 }} onClick={() => setShow(false)}>
            <i className='fa fa-times'></i>
          </button>

          <h5>{translateObj("MESSAGE.TERMSCONDITIONS")}</h5>
          <PDFViewer style={{ width: '100%', minHeight: '60vh' }}>
            <DocumentPdf data={data} user={user} />
          </PDFViewer>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PDFDownloadLink document={<DocumentPdf data={data} user={user} />} fileName="documento.pdf">
          {({ blob, url, loading, error }) =>
            loading ? '...' : (
              <button type='button' className='btn btn-primary'>{translateObj("BTN.DOWNLOAD")}</button>
            )
          }
        </PDFDownloadLink>
      </Modal.Footer>
    </Modal>
  )
}

export const TermsCredit = (props: { translateObj: any, data: any }) => {
  const { translateObj, data } = props
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any

  const [show, setShow] = useState(false)

  return (
    <>
      <ModalTermsCredit translateObj={translateObj} data={data} user={user} show={show} setShow={setShow} />
      <span className='text-primary cursor-pointer' onClick={() => setShow(true)}>{translateObj("BTN.ACCEPT.HREF")}</span>
    </>
  )
}