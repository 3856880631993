import { KTSVG } from '../../../../_metronic/helpers'
import { useEffect, useState } from 'react';
import { deleteAdminOrderId, getAdminOrderId, getAdminTeams } from '../../../services/Api';
import { ModalSendImages } from '../components/ModalSendImages';
import { ModalReproachAmbience } from '../components/ModalReproachAmbience';
import { ImagesCeler } from '../components/ImagesCeler';
import { formatReal, maskCep, searchCategory, searchDecoration, searchProduct, searchStatus, searchType, typeProductName } from '../../../utils';
import { ModalChangeStatus } from '../components/ModalChangeStatus';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { ModalChangeTeam } from '../components/ModalChangeTeam';
import { ModalChangeUser } from '../components/ModalChangeUser';
import { ModalOrderFinished } from '../components/ModalOrderFinished';
import { ModalChangeDateForecast } from '../components/ModalChangeDateForecast';

export default function DetailTeam(props: { translateObj: any, id: any }) {
  const { translateObj, id } = props;
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const [modified, setModified] = useState(1)
  const [data, setData] = useState<any>()
  const [isAdmin, setIsAdmin] = useState(true);
  const [modalShowApprove, setModalShowApprove] = useState(false);
  const [modalShowReproach, setModalShowReproach] = useState(false);
  const [modalShowStatus, setModalShowStatus] = useState(false);
  const [modalShowTeam, setModalShowTeam] = useState(false);
  const [modalChangeForecast, setModalChangeForecast] = useState(false);
  const [modalOrderFinished, setModalOrderFinished] = useState(false);
  const [ambience, setAmbience] = useState(null);
  const [teams, setTeams] = useState<any>();
  const [ambienceUserSelected, setAmbienceUserSelected] = useState<any>({});
  const [modalChangeUser, setModalChangeUser] = useState(false);

  const infoTitle = {
    fontWeight: 800,
    fontFamily: "Arial",
    background: "#e4e4e4",
    lineHeight: "30px",
    paddingLeft: "8px",
    marginTop: "30px",
    marginBottom: "8px",
    borderBottom: "1px solid"
  };

  const changeTeam = () => {
    setModalShowTeam(true)
  }

  const changeStatus = () => {
    setModalShowStatus(true)
  }

  const sendImageAmbience = (data: any) => {
    setIsAdmin(true);
    setAmbience(data);
    setModalShowApprove(true);
  }

  const modalFailImages = (data: any) => {
    setAmbience(data);
    setModalShowReproach(!modalShowReproach);
  }

  const changeUserAmbience = (idUser: any, idAmbience: any) => {
    setModalChangeUser(true)
    setAmbienceUserSelected({ idUser, idAmbience })
  }

  const removeOrder = async (id: any) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(translateObj("MESSAGE.CANCELORDER"))) {
      await deleteAdminOrderId(id).then(() => {
        window.location.href = "../report";
      })
    }
  }

  const sendNewImage = (data: any) => {
    setIsAdmin(false);
    setAmbience(data);
    setModalShowApprove(true);
  }

  const sendFileFinished = (data: any) => {
    setModalOrderFinished(true);
  }

  const changeDateForecast = () => {
    setModalChangeForecast(true);
  };

  useEffect(() => {
    getAdminOrderId(id).then((data) => {
      setData(data)
    })
  }, [id, modified])

  useEffect(() => {
    getAdminTeams(data?.order?.team_id).then((dataTeam) => {
      setTeams(dataTeam)
    })
  }, [data])

  return (
    <>
      <ModalSendImages translateObj={translateObj} show={modalShowApprove} setShow={setModalShowApprove} ambience={ambience} isAdmin={isAdmin} setModified={setModified} />
      <ModalReproachAmbience translateObj={translateObj} show={modalShowReproach} setShow={setModalShowReproach} ambience={ambience} setModified={setModified} />
      <ModalChangeStatus translateObj={translateObj} show={modalShowStatus} setShow={setModalShowStatus} order={data?.order} setModified={setModified} />
      {(data?.order.status === 'In_Progress' || data?.order.status === 'Finished') && <ModalOrderFinished translateObj={translateObj} show={modalOrderFinished} setShow={setModalOrderFinished} setModified={setModified} orderId={data?.order?.id} />}
      {(user.type === 'Coordinator' || user?.type === 'Squad' || user?.type === 'Squadinator') && <ModalChangeUser translateObj={translateObj} show={modalChangeUser} setShow={setModalChangeUser} ambienceUserSelected={ambienceUserSelected} setModified={setModified} />}
      {user.type !== 'Operator' && (<ModalChangeTeam translateObj={translateObj} show={modalShowTeam} setShow={setModalShowTeam} order={data?.order} setModified={setModified} />)}
      {(user?.type === 'Squad' || user?.type === 'Squadinator') && <ModalChangeDateForecast translateObj={translateObj} show={modalChangeForecast} setShow={setModalChangeForecast} order={data?.order} setModified={setModified} />}

      <div className='mb-15'>
        <a href="/report">
          <KTSVG
            path='/media/icons/duotune/arrows/arr063.svg'
            className='svg-icon-4 me-1'
          />
          {translateObj("BTN.BACK")}
        </a>
      </div>
      {data?.order?.property && (
        <>
          <h1>
            {translateObj("MESSAGE.DETAILSPROJECT")}
            <span className='text-primary mx-3'>#{data?.order.number}</span>
          </h1>
          <h5 className='mb-5'>
            <b>{translateObj("MESSAGE.CREATED_AT")}: </b>
            <span className='fw-normal'>{moment(data?.order?.created_at).format('DD/MM/YYYY HH:mm')}</span>
          </h5>

          <div className='card'>
            <div className='card-body'>
              <h3>
                <b>{translateObj("MESSAGE.TEAM")}: </b>
                <span className='fw-normal'>{data?.order?.team?.name}</span>
                {(user?.type === 'Squad' || user?.type === 'Squadinator') && (
                  <button className='btn btn-dark text-white btn-sm mx-5' onClick={() => changeTeam()}>
                    <i className='fa fa-edit'></i>
                    <span>{translateObj("MESSAGE.UPDATEDTEAM")}</span>
                  </button>
                )}
              </h3>

              <h3 className='mb-4'>
                <b>{translateObj("MESSAGE.COMPANY")}: </b>
                <span className={`fw-normal ${data?.order.company_id === "2351b304-f7f0-4ed5-bc51-8dbd9255b1ac" && "bg-secondary px-3 text-primary"}`}>{data?.order.company.name}</span>
              </h3>

              <h3>
                <b>{translateObj("MESSAGE.PRODUCT")}: </b>
                <span className='fw-normal'>{typeProductName(data?.order)}</span>
              </h3>

              <h3>
                <b>{translateObj("MESSAGE.STATUS")}: </b>
                <span className='fw-normal'>{searchStatus(data?.order.status)}</span>
                <button className='btn btn-dark text-white btn-sm mx-5' onClick={() => changeStatus()}>
                  <i className='fa fa-edit'></i>
                  <span>{translateObj("MESSAGE.UPDATEDSTATUS")}</span>
                </button>
              </h3>

              <div>
                <hr />
                {data?.order?.finish_in && (
                  <h3>
                    <b className='me-1'>
                      <i className='fa fa-clock me-1'></i>
                      {translateObj("MESSAGE.DATEFORECAST")}:
                    </b>
                    <span className='fw-normal'>{moment(data?.order.finish_in).format('DD/MM/YYYY HH:mm')}</span>
                    {/* <button className='btn btn-dark text-white btn-sm mx-5' onClick={() => changeDateForecast()}>
                      <i className='fa fa-edit'></i>
                      <span>{translateObj("MESSAGE.UPDATEDDATEFORECAST")}</span>
                    </button> */}
                  </h3>
                )}

                {data?.order?.started_at && (
                  <h3>
                    <b className='me-1'>
                      <i className='fa fa-clock me-1'></i>
                      {translateObj("MESSAGE.DATESTART")}:
                    </b>
                    <span className='fw-normal'>{moment(data?.order.started_at).format('DD/MM/YYYY HH:mm')}</span>
                  </h3>
                )}

                {data?.order?.finished_at && (
                  <h3>
                    <b className='me-1'>
                      <i className='fa fa-clock me-1'></i>
                      {translateObj("MESSAGE.DATECONCLUSION")}:
                    </b>
                    <span className='fw-normal'>{moment(data?.order.finished_at).format('DD/MM/YYYY HH:mm')}</span>
                  </h3>
                )}
              </div>

              {data?.delay && (
                <>
                  <h3 className='text-danger border-bottom mt-10 pb-5'>{translateObj("MESSAGE.DESCRIPTIONOVERDUE")}</h3>
                  <div className='text-danger fw-bold mb-5'>
                    {data?.delay?.desciption}
                  </div>
                </>
              )}

              <div className='mt-10'>
                <div className='accordion-item'>
                  <h2 className='accordion-header'>
                    <button className="accordion-button fs-4 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1" aria-expanded="true" aria-controls="kt_accordion_1">{translateObj("MESSAGE.IMMOBILE.TITLE")}</button>
                  </h2>
                  <div id='kt_accordion_1' className='accordion-collapse collapse show'>
                    <div className='accordion-body pt-0 mt-0'>

                      <div className='row'>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj("MESSAGE.CATEGORY")}</div>
                          <span className='badge bg-primary fs-4'>{searchCategory(data?.order.property.category)}</span>
                        </div>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj("MESSAGE.IDENTIFICATION")}</div>
                          {data?.order.property.name}
                        </div>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj("MESSAGE.TYPE")}</div>
                          {searchType(data?.order.property.type)}
                        </div>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj("MESSAGE.PROPERTYSTANDARD")}</div>
                          {searchProduct(data?.order.standard)}
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj("MESSAGE.ZIPCODE")}</div>
                          {maskCep(data?.order.property.zip_code)}
                        </div>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj("MESSAGE.VALUE")}</div>
                          {formatReal(data?.order.property.price)}
                        </div>
                        <div className='col-md-3'>
                          <div style={infoTitle}>{translateObj("MESSAGE.DECORATIONSTYLE")}</div>
                          {searchDecoration(data?.order.decoration)}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button fs-4 fw-bold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#kt_accordion_2'
                      aria-expanded='false'
                      aria-controls='kt_accordion_2'
                    >
                      {translateObj("MESSAGE.PHOTOSIMMOBILE")}
                    </button>
                  </h2>
                  <div
                    id='kt_accordion_2'
                    className='accordion-collapse collapse'
                  >
                    <div className='accordion-body pt-0 mt-0'>
                      {data?.order?.enviroments.map((item: any, key: any) => {
                        let keyItem = key + 1
                        let checkImageChosen = item.photos.map((photoAmbience: any) => photoAmbience.is_chosen).includes(true)

                        return (
                          <div key={keyItem} className={`mt-5`}>
                            <h5 className='m-0 p-0 border-bottom pb-3 mb-3'>{keyItem}° {translateObj("MESSAGE.AMBIENCE")}</h5>

                            <div>
                              <b>{translateObj("MESSAGE.RESPONSIBLE")}: </b>
                              {(user.type === 'Coordinator' || user?.type === 'Squad' || user?.type === 'Squadinator') ? (
                                <select className='form-select form-select-lg form-select-solid' onChange={(e: any) => changeUserAmbience(e.target.value, item.id)}>
                                  <option>{translateObj("MESSAGE.SELECT")}</option>
                                  {teams && teams.map((itemTeam: any, keyTeam: any) => {
                                    return (<option key={keyTeam} value={itemTeam.id} selected={item.user?.id === itemTeam.id}>{itemTeam.name}</option>)
                                  })}
                                </select>
                              ) : (
                                <>
                                  {item.user?.name ? item.user?.name : translateObj("MESSAGE.NOTDATA")}
                                </>
                              )}
                            </div>
                            <div>
                              <b>{translateObj("MESSAGE.AMBIENCE")}:</b>
                              <span> {item.name}</span>
                            </div>
                            {item.album_link && (
                              <div>
                                <b>{translateObj("MESSAGE.LINKSITE")}:</b>
                                <span> {item.album_link}</span>
                              </div>
                            )}
                            {item.description && (
                              <div>
                                <b>{item.review ? translateObj("MESSAGE.REASONREJECTION") : translateObj("MESSAGE.DESCRIPTION")}:</b>
                                <span> {item.description}</span>
                              </div>
                            )}
                            {item.measurements && (
                              <div>
                                <b>{translateObj("MESSAGE.DESCRIPTIONDETAILS")}:</b>
                                <span> {item.measurements}</span>
                              </div>
                            )}

                            <ImagesCeler translateObj={translateObj} itemAmbience={item} setModified={setModified} />

                            <div>
                              {/* {data?.order.status !== 'Finished' && (
                                <button type='button' className='btn btn-dark text-white btn-sm w-100 mt-10' onClick={() => sendNewImage(item)}>
                                  <i className='fa fa-plus'></i>
                                  <span className='mx-2'>{translateObj("MESSAGE.SENDNEWPHOTO")}</span>
                                </button>
                              )} */}

                              {(data?.order.status !== 'Finished' && !item.is_approved && checkImageChosen) && (
                                <button type='button' className='btn btn-success btn-sm w-100 mt-5' onClick={() => sendImageAmbience(item)}>
                                  <i className='fa fa-check'></i>
                                  <span className='mx-3'>{translateObj("MESSAGE.CONCLUDEAMBIENCE", { "length": keyItem })}</span>
                                </button>
                              )}

                              {(data?.order.status !== 'Finished' && item.is_approved) && (
                                <button type='button' className='btn btn-success btn-sm w-100 mt-5' onClick={() => sendImageAmbience(item)}>
                                  <i className='fa fa-check'></i>
                                  <span className='mx-3'>{translateObj("MESSAGE.RESENDCONCLUDEAMBIENCE", { "length": keyItem })}</span>
                                </button>
                              )}

                              {((user?.type === 'Squad' || user?.type === 'Squadinator' || user.type === 'Coordinator') && (data?.order.status === 'Waiting_Payment' || data?.order.status === 'Waiting_Service')) && (
                                <button type='button' className='btn btn-danger btn-sm w-100 my-3' onClick={() => modalFailImages(item)}>
                                  <i className='fa fa-times'></i>
                                  <span className='mx-3'>{translateObj("MESSAGE.REPROVEDAMBIENCE", { "length": keyItem })}</span>
                                </button>
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button fs-4 fw-bold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#kt_accordion_3'
                      aria-expanded='false'
                      aria-controls='kt_accordion_3'
                    >
                      ({data?.order.orders_moviments.length}) {translateObj("MESSAGE.MOVEMENTS")}
                    </button>
                  </h2>
                  <div id='kt_accordion_3' className='accordion-collapse collapse'>
                    <div className='accordion-body'>
                      <ul>
                        {data?.order.orders_moviments.map((item: any) => {
                          let created_at = moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')

                          return (
                            <li key={item.id} className='p-3'>{created_at} - {item.desciption}</li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {(data?.order.status === 'Finished' && (data?.order.comparative_document_url || data?.order.comparative_video_url)) && (
                <div className='mt-8'>
                  <h5>{translateObj("MESSAGE.TITLE.COMPARATIVE")}</h5>
                  <div className='d-flex gap-5'>
                    <a href={data?.order.comparative_document_url} target='_blank' className='btn btn-primary btn-sm' rel="noreferrer">
                      <i className='fa fa-download me-3'></i>
                      {translateObj("MESSAGE.COMPARATIVEDOCUMENT")}
                    </a>
                    <a href={data?.order.comparative_video_url} target='_blank' className='btn btn-primary btn-sm' rel="noreferrer">
                      <i className='fa fa-download me-3'></i>
                      {translateObj("MESSAGE.COMPARATIVEVIDEO")}
                    </a>
                  </div>
                </div>
              )}

              {(data?.order.status === 'In_Progress' || data?.order.status === 'Finished') && (
                <div className='mt-10'>
                  <h5>{translateObj("MESSAGE.TITLE.SENDCOMPLETIONDOCUMENT")}</h5>
                  <button type='button' className='btn btn-success btn-sm' onClick={() => sendFileFinished(data?.order)}>
                    <i className='fa fa-star'></i>
                    <span className='mx-3'>{translateObj("MESSAGE.SENDCOMPLETIONDOCUMENT")}</span>
                  </button>
                </div>
              )}

              {(data?.order.status === 'Waiting_Payment' || data?.order.status === 'Draft') && (
                <div className='mt-10'>
                  <h4>{translateObj("MESSAGE.CANCELORDER")}</h4>
                  <p className='fs-5'>{translateObj("MESSAGE.CANCELORDER.DESCRIPTION")}</p>

                  <button type='button' className='btn btn-danger cursor-pointer w-100' onClick={() => removeOrder(data?.order?.id)}>
                    <i className='fa fa-times me-4' />
                    {translateObj("BTN.CANCEL")}
                  </button>
                </div>
              )}

            </div>
          </div>
        </>
      )}
    </>
  )
}