import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { patchUsersTeamId, getUsers, postUsersTeam } from '../../../services/Api';

export function ModalTeam(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, setModified: Dispatch<SetStateAction<number>>, team: any }) {
  const { translateObj, show, setShow, setModified, team } = props;
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])

  const handleClose = () => {
    setShow(false);
  }

  const initialValues = {
    "name": team?.name ? team.name : "",
    "color": team?.color ? team.color : "",
    "coordinator_id": team?.coordinator_id ? team.coordinator_id : "",
  }

  const currentSchema = Yup.object().shape({
    "name": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "color": Yup.string()
      .required(translateObj("VALID.REQUIRED")),
    "coordinator_id": Yup.string()
      .required(translateObj("VALID.REQUIRED")),
  })

  const submitForm = (values: any) => {
    setLoading(true)

    if (team?.id) {
      patchUsersTeamId(team?.id, values).then(() => {
        setModified(Math.random())
        handleClose()
        setLoading(false)
      })
    } else {
      postUsersTeam(values).then(() => {
        setModified(Math.random())
        handleClose()
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    getUsers().then(data => {
      setUsers(data)
    })
  }, [])

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitForm}>
        {({ setFieldValue }) => (
          <Form id='kt_team_form'>
            <Modal.Body>
              <div>
                <h2 className='fw-bolder text-dark'>{translateObj("MESSAGE.DATATEAM")}</h2>

                <div className='text-gray-400 fw-bold fs-6 mb-15'>
                  {translateObj("MESSAGE.DATATEAM.DESCRIPTION")}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.NAME")}</label>

                  <Field name='name' className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='name' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.COORDINATOR")}</label>

                  <Field as='select' name='coordinator_id' className='form-select form-select-lg form-select-solid'>
                    <option value=''>{translateObj("MESSAGE.SELECT")}</option>
                    {users && users.filter((item: any) => item?.type === "Coordinator").map((itemTeam: any, keyTeam: any) => {
                      return (<option key={keyTeam} value={itemTeam.id}>{itemTeam.name}</option>)
                    })}
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='coordinator_id' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.COLOR")}</label>

                  <input type='color' name='color' defaultValue={team?.color} className='form-control form-control-lg form-control-solid' onChange={(e: any) => setFieldValue('color', e.target.value)} onKeyUp={(e: any) => setFieldValue('color', e.target.value)} />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='color' />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <>
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </>
              ) : (
                <>
                  <button type='button' className='btn btn-default' onClick={handleClose}>
                    {translateObj("BTN.CANCEL")}
                  </button>
                  <button type='submit' className='btn btn-dark text-white'>
                    <i className='fa fa-check'></i>
                    <span className='mx-1'>{translateObj("BTN.SAVE")}</span>
                  </button>
                </>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}