/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { AsideMenu } from './AsideMenu'
import { ModalHelp } from '../ModalHelp'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { useIntl } from 'react-intl'

const AsideDefault: FC = () => {
  const intl = useIntl();
  const translateObj = (obj: any) => {
    return intl.formatMessage({ id: obj })
  }
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const [show, setShow] = useState(false)
  const { config, classes } = useLayout()
  const { aside } = config

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      <ModalHelp show={show} setShow={setShow} />
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {aside.theme === 'light' && (
          <Link to='/dashboard'>
            <img
              alt='Logo'
              className='w-90px logo'
              src={toAbsoluteUrl('/media/logos/logo-1.png')}
            />
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-dark aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr079.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {!user?.type && (
        <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
          <button type='button' className='btn btn-custom w-100' onClick={() => setShow(true)} style={{ color: '#000', background: '#ffc200' }}>
            <span className='btn-label'>{translateObj("BTN.HELP")}</span>
            <span className='svg-icon btn-icon svg-icon-2 ms-2' style={{ color: '#000' }}>
              <KTSVG path='/media/icons/duotune/general/gen004.svg' />
            </span>
          </button>
        </div>
      )}
    </div>
  )
}

export { AsideDefault }
