import { useEffect, useState } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { RootState } from "../../../../setup"
import { getAdminDashboard } from "../../../services/Api"
import { Executive } from "../components/Executive"
import { Influencer } from "../components/Influencer"

const DashboardTeamSquad = (props: { translateObj: any, user: any }) => {
  const { translateObj, user } = props
  const [dashboard, setDashboard] = useState<any>()

  const minHeight = {
    minHeight: "40px",
    fontSize: "14px"
  }

  const sizeText = {
    fontSize: "34px",
    fontWeight: 900,
  }

  const routeReport = (status: string) => {
    window.location.href = `report/${status}`
  }

  useEffect(() => {
    getAdminDashboard().then((data: any) => {
      setDashboard(data)
    })
  }, [])

  return (
    <>
      {(user.type === "Squad" || user.type === "Squadinator" || user.type === "Coordinator") && (
        <>
          <div className="row mt-15">
            <div className="col-sm-3">
              <div className="card cursor-pointer" onClick={() => routeReport('In_Progress')}>
                <div className="card-body bg-danger">
                  <div style={minHeight} className="text-white card-title">{translateObj("MESSAGE.SERVICE.OVERDUE")}</div>
                  <h1 style={sizeText} className='text-white'>{dashboard?.delaieds}</h1>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card cursor-pointer" onClick={() => routeReport('Finished')}>
                <div className="card-body bg-success">
                  <div style={minHeight} className="card-title text-white">{translateObj("MESSAGE.SERVICE.FINALIZED")}</div>
                  <h1 style={sizeText} className="text-white">{dashboard?.finisheds}</h1>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card cursor-pointer" onClick={() => routeReport('Waiting_Service')}>
                <div className="card-body bg-warning">
                  <div style={minHeight} className="text-dark card-title">{translateObj("MESSAGE.WAITINGSERVICE")}</div>
                  <h1 style={sizeText} className='text-dark'>{dashboard?.waiting_service}</h1>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card cursor-pointer" onClick={() => routeReport('Waiting_Customer')}>
                <div className="card-body bg-secondary">
                  <div style={minHeight} className="text-dark card-title">{translateObj("MESSAGE.WAITINGCLIENT")}</div>
                  <h1 style={sizeText} className='text-dark'>{dashboard?.waiting_customer}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-8">
            <div className="col-sm-3">
              <div className="card cursor-pointer" onClick={() => routeReport('Waiting_Payment')}>
                <div className="card-body bg-secondary border">
                  <div style={minHeight} className="text-dark card-title">{translateObj("MESSAGE.PAYMENTPENDING")}</div>
                  <h1 style={sizeText} className='text-dark'>{dashboard?.waiting_payment}</h1>
                </div>
              </div>
            </div>
            {(user.type === "Squad" || user.type === "Squadinator") ? (
              <>
                <div className="col-sm-3">
                  <div className="card cursor-pointer" onClick={() => routeReport('Finished')}>
                    <div className="card-body bg-light">
                      <div style={minHeight} className="text-primary card-title">{translateObj("MESSAGE.TEAM.BLUE")}</div>
                      <h1 style={sizeText} className="text-primary">{dashboard?.team_blue}</h1>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card cursor-pointer" onClick={() => routeReport('Finished')}>
                    <div className="card-body bg-light">
                      <div style={minHeight} className="text-success card-title">{translateObj("MESSAGE.TEAM.GREEN")}</div>
                      <h1 style={sizeText} className="text-success">{dashboard?.team_green}</h1>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card cursor-pointer" onClick={() => routeReport('Finished')}>
                    <div className="card-body bg-light">
                      <div style={minHeight} className="text-warning card-title">{translateObj("MESSAGE.TEAM.YELLOW")}</div>
                      <h1 style={sizeText} className="text-warning">{dashboard?.team_yellow}</h1>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-sm-3">
                <div className="card cursor-pointer" onClick={() => routeReport('Finished')}>
                  <div className="card-body bg-success">
                    <div style={minHeight} className="text-white card-title">{translateObj("MESSAGE.FINALIZEDTEAM")}</div>
                    <h1 style={sizeText} className="text-white">{dashboard?.my_team}</h1>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      <div className='mt-15'>
        <div>
          <h5>{translateObj("MESSAGE.WANTVIEWFOLLOWS")}</h5>
          <a href='/report' className='btn btn-dark text-white'>
            <i className='fa fa-search'></i>
            <span>{translateObj("MESSAGE.CONSULTGENERAL")}</span>
          </a>
        </div>

        <div className='mt-15'>
          <h5>{translateObj("MESSAGE.WANTVIEWANALYSIS")}</h5>
          <a href='/kanban' className='btn btn-dark text-white'>
            <i className='fa fa-search'></i>
            <span>{translateObj("MESSAGE.VIEWKANBAN")}</span>
          </a>
        </div>

        <div className='mt-15'>
          <h5>{translateObj("MESSAGE.NOTIFICATIONS")}</h5>
          <a href='/notifications' className='btn btn-dark text-white'>
            <i className='fa fa-search'></i>
            <span>{translateObj("MESSAGE.VIEWNOTIFICATIONS")}</span>
          </a>
        </div>
      </div>
    </>
  )
}

const DashboardNotTeamSquad = (props: { translateObj: any, user: any }) => {
  const { translateObj, user } = props

  return (
    <>{user?.type === 'Executive' ? <Executive translateObj={translateObj} /> : <Influencer translateObj={translateObj} />}</>
  )
}

export default function DashboardTeam(props: { translateObj: any }) {
  let { translateObj } = props;

  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const notPermission = ['Executive', 'Influencer'].includes(user?.type)

  return (
    <>
      <h1>{translateObj("MESSAGE.WELCOME")}, {user.name}!</h1>
      {notPermission ? (
        <>
          <DashboardNotTeamSquad translateObj={translateObj} user={user} />
        </>
      ) : (
        <>
          <p>{translateObj("MESSAGE.DASHBOARD.DESCRIPTION")}</p>
          <DashboardTeamSquad translateObj={translateObj} user={user} />
        </>
      )}
    </>
  )
}