import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

export interface IRegister {
  name: string;
  document: string;
  zip_code: number | string;
  state: string;
  city: string;
  address: string;
  address_number: number | string;
  consumer_name: string;
  email: string;
  phone: string;
  password: string;
}

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/me`
export const GET_USER_BY_ACCESSTOKEN_ADMIN_URL = `${API_URL}/auth/user/me`
export const LOGIN_URL = `${API_URL}/auth/sign-in`
export const LOGIN_ADMIN_URL = `${API_URL}/auth/user/sign-in`
export const REGISTER_URL = `${API_URL}/company`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/recovery`
export const REQUEST_PASSWORD_URL_ADMIN = `${API_URL}/auth/user/recovery`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {email, password})
}

export function loginAdmin(email: string, password: string) {
  return axios.post(LOGIN_ADMIN_URL, {email, password})
}

// Server should return AuthModel
export function register(data : IRegister) {
  return axios.post<AuthModel>(REGISTER_URL, data)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {email})
}

export function requestPasswordAdmin(email: string) {
  return axios.post(REQUEST_PASSWORD_URL_ADMIN, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function getUserByTokenAdmin() {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_ADMIN_URL)
}
