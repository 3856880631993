/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useLayout } from '../core'

const Footer: FC = () => {
  const { classes } = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer' style={{ background: '#ffc200' }}>
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className='order-2 order-md-1' style={{ color: '#000' }}>
          <span className='fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href='#' className='text-hover-primary' style={{ color: '#000' }}>
            CELER
          </a>
        </div>
      </div>
    </div>
  )
}

export { Footer }
