/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { Login as LoginAdmin } from './components/LoginAdmin'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { setLanguage } from '../../../_metronic/i18n/Metronici18n'
import { useIntl } from 'react-intl'
import { languages } from '../../utils'

export function AuthPage() {
  const intl = useIntl();
  const translateObj = (obj: any, varInfo?: any) => {
    if (varInfo) {
      return intl.formatMessage({ id: obj }, varInfo)
    }

    return intl.formatMessage({ id: obj })
  }

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.png')} className='h-60px' />
          <div className='position-absolute top-0 end-0 mt-8 me-5 text-center'>
            <b className='me-3'>{translateObj("TRANSLATOR.SELECT")}</b>
            <br />
            <select className='mt-1' onChange={(e) => e.target.value !== '' && setLanguage(e.target.value)}>
              <option value=''>{translateObj("MESSAGE.SELECT")}</option>
              {languages.map((item: any, key: number) => {
                return <option key={key} value={item.id} selected={item.id === intl.locale}>{item.name}</option>
              })}
            </select>
          </div>
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route path='/auth/login' render={() => <Login translateObj={translateObj} />} />
            <Route path='/auth/admin' render={() => <LoginAdmin translateObj={translateObj} />} />
            <Route path='/auth/registration/:id' render={() => <Registration translateObj={translateObj} />} />
            <Route path='/auth/registration' render={() => <Registration translateObj={translateObj} />} />
            <Route path='/auth/forgot-password' render={() => <ForgotPassword type={0} translateObj={translateObj} />} />
            <Route path='/auth/forgot-password-admin' render={() => <ForgotPassword type={1} translateObj={translateObj} />} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}


      <div className='footer' id='kt_footer' style={{ background: '#ffc200' }}>
        <div className='order-2 order-md-1 text-center' style={{ color: '#000' }}>
          <b style={{ color: '#000' }}>
            {new Date().getFullYear()} &copy;
            Squad Realty
            <a href='admin' className='px-5 fs-3' style={{ color: '#000' }}>-= CRM =-</a>
          </b>
        </div>
      </div>
    </div>
  )
}
